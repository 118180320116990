import React, { ReactNode } from 'react';
import { ServiceDescriptionsWrapper, MainTitle } from './ServicesBox.styled';

interface IProps {
  title?: ReactNode;
}

const ServicesBox: React.FC<IProps> = ({ title = 'Services', children }) => {
  return (
    <div>
      <MainTitle>{title}</MainTitle>
      <ServiceDescriptionsWrapper>{children}</ServiceDescriptionsWrapper>
    </div>
  );
};

export default ServicesBox;
