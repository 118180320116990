import React from 'react';
import BoxText from 'src/components/boxText/BoxText';
import { InputWrapper } from 'src/components/searchInput/SearchInput.styled';
import type { IJob } from 'src/types/job.types';
import ListFilterInput from '../../../../components/searchInput/SearchInput';
import JobListItem from '../../components/jobListItem/JobListItem';
import { useJobsList } from '../../hooks/useJobsList/useJobsList';
import NoJobsPlaceholder from '../noJobsPlaceholder/NoJobsPlaceholder';
import {
  Root,
  Row,
  Tag,
  JobsListTitle,
  ListWrapper,
  ListItem,
  ListItemLink,
  JobsListSubTitle,
} from './jobsList.styled';

const JobsList = () => {
  const [{ filteredJobs: jobs, locationTags, activeLocationTag, searchQuery }, setLocationTag, setSearchQuery] =
    useJobsList();

  const hasNoResults = jobs.length === 0 && searchQuery.length > 0;

  return (
    <Root id="jobs">
      <JobsListTitle>Open roles</JobsListTitle>
      <JobsListSubTitle>
        <BoxText>Your next adventure starts here.</BoxText>
      </JobsListSubTitle>
      <Row>
        <div>
          {locationTags.map((tag: string) => (
            <Tag key={tag} isHighlighted={tag === activeLocationTag} onClick={() => setLocationTag(tag)}>
              {tag}
            </Tag>
          ))}
        </div>
        <div>
          <InputWrapper>
            <ListFilterInput value={searchQuery} onChange={e => setSearchQuery((e.target as HTMLInputElement).value)} />
          </InputWrapper>
        </div>
      </Row>

      {hasNoResults && <NoJobsPlaceholder searchTerm={searchQuery} />}

      <ListWrapper>
        {jobs.map((job: IJob) => (
          <ListItem key={job.id}>
            <ListItemLink to={`/careers/job/${job.shortcode}`} state={{ canGoBack: true }}>
              <JobListItem title={job.title} />
            </ListItemLink>
          </ListItem>
        ))}
      </ListWrapper>
    </Root>
  );
};

export default JobsList;
