import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import GivingBackBox from 'src/components/givingBackBox/GivingBackBox';
import Layout from 'src/components/layout/Layout';
import PageContentSectionsWrapper from 'src/components/pageContentSectionsWrapper/PageContentSectionsWrapper';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import PageDescription from 'src/components/pageDescription/PageDescription';
import PageHeading from 'src/components/pageHeading/PageHeading';
import PageTitle from 'src/components/pageTitle/PageTitle';
import CompanyTechStack from '../../components/companyTechStack/CompanyTechStack';
import { S } from './About.styled';
import CompanyInnovationBox from './components/companyInnovationBox/CompanyInnovationBox';
import CompanyPurposeBox from './components/companyPurposeBox/CompanyPurposeBox';
import CompanyValuesBox from './components/companyValuesBox/AboutCompanyValuesBox';

interface IProps {
  data: {
    markdownRemark: {
      frontmatter: {
        pageTitle: string;
        title: string;
        pageDescription: string;
        description: string;
      };
    };
  };
}

const AboutPage = ({ data }: IProps) => {
  const { title, pageDescription, description, pageTitle } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
      </Helmet>
      <PageContentWide>
        <PageContentSectionsWrapper>
          <PageHeading>
            <PageTitle>{title}</PageTitle>
            <S.DescriptionContainer>
              {description.split('\n').map((p, index) => (
                <PageDescription key={index}>{p}</PageDescription>
              ))}
            </S.DescriptionContainer>
          </PageHeading>
          <CompanyPurposeBox />
          <CompanyValuesBox />
          <CompanyTechStack />
          <CompanyInnovationBox />
          <GivingBackBox />
        </PageContentSectionsWrapper>
      </PageContentWide>
    </Layout>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        title
        description
        pageDescription
      }
    }
  }
`;
