import styled from 'styled-components';
import { MainCarousel } from 'src/components/boxCarousel/MainCarousel.styled';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingHorizontalDesktop, boxPaddingVerticalDesktop, pagePaddingMobile } from 'src/theme/dimensions';

const CarouselWrapper = styled(MainCarousel)`
  background-color: ${Colors.switchedOnFibre.bg.blue60};
`;

const CarouselControlsWrapper = styled.div`
  position: relative;

  & > div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: ${pagePaddingMobile};
  }

  @media ${mediaQuery.tablet} {
    & > div {
      transform: translateX(0%);
      left: ${pagePaddingMobile};
      bottom: ${boxPaddingVerticalDesktop};
    }
  }

  @media ${mediaQuery.desktop} {
    & > div {
      left: ${boxPaddingHorizontalDesktop};
      bottom: ${boxPaddingVerticalDesktop};
    }
  }
`;

export const S = {
  CarouselWrapper,
  CarouselControlsWrapper,
};
