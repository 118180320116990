import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';

export const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  color: #dddedf;
  margin-bottom: 36px;

  @media ${mediaQuery.phone} {
    flex-direction: column;
  }
`;

export const Image = styled(GatsbyImage)`
  width: 40px;
  border-radius: 20px;
`;

export const Name = styled.div`
  font-size: 24px;
  font-weight: 700;
`;

export const Position = styled.div`
  text-transform: uppercase;
  font-size: 12px;
`;
