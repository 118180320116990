import axios from 'axios';
import React, { useEffect } from 'react';
import type { IJob } from 'src/types/job.types';

interface IState {
  job: IJob | null;
  loading: boolean;
  error?: string;
}

export const useJob = (shortcode: string): IState => {
  const [state, setState] = React.useState<IState>({ job: null, loading: true });

  useEffect(() => {
    axios
      .get(`/.netlify/functions/job-read?shortcode=${shortcode}`)
      .then((response: { data: IJob }) => {
        setState({ loading: false, job: response.data, error: '' });
      })
      .catch(() => setState({ loading: false, job: null, error: 'Cannot read job' }));
  }, [shortcode]);

  return state;
};
