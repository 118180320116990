import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';

const StyledBackgroundImage = styled(BackgroundImage)`
  background-size: 100% auto !important;
  background-position: right 0px !important;

  @media ${mediaQuery.tablet} {
    &,
    &::before,
    &::after {
      background-size: 80% auto !important;
      background-position: right 100px !important;
    }
  }
`;

const PageSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;

  @media ${mediaQuery.tablet} {
    gap: 80px;
    margin-bottom: 80px;
  }
`;

export const S = {
  StyledBackgroundImage,
  PageSectionsWrapper,
};
