import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingHorizontalDesktop, boxPaddingMobile, boxPaddingVerticalDesktop } from 'src/theme/dimensions';

export const Container = styled.div`
  border-radius: 16px;
  background-color: #1b2327;
  padding: ${boxPaddingMobile};

  .light & {
    background-color: ${Colors.ui.componentLight};
  }

  @media ${mediaQuery.desktop} {
    padding: ${boxPaddingVerticalDesktop} ${boxPaddingHorizontalDesktop};
  }
`;

export const Title = styled.h3`
  letter-spacing: -1.5px;
  font-weight: 800;

  @media ${mediaQuery.tablet} {
    font-size: 40px;
    max-width: 50%;
  }

  @media ${mediaQuery.navbarCollapse} {
    font-size: 36px;
  }

  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;
const GalleryContainer = styled.div`
  overflow-x: auto;
  padding-bottom: 16px;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: ${Colors.blue.main};
  }
`;
const Gallery = styled.div`
  display: grid;
  width: min-content;
  grid-template-columns: repeat(auto-fill);
  gap: 16px;
  grid-template-rows: 200px 200px;
  grid-auto-flow: column dense;
`;
const GalleryItem = styled.img`
  &:nth-child(3n + 1) {
    grid-row: span 2 / span 2;
  }
  width: max-content;
  height: 100%;
  margin: 0;
  pointer-events: none;
  border-radius: 8px;
`;

export const S = {
  Container,
  Title,
  GalleryContainer,
  Gallery,
  GalleryItem,
};
