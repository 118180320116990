import React from 'react';
import { useForm } from 'react-hook-form';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { IContactFormData } from 'src/components/contactForm/contactForm.types';
import { useContactForm } from 'src/components/contactForm/hooks/useContactForm/useContactForm';
import FormSuccess from 'src/components/formSuccess/FormSuccess';
import Input from 'src/components/input/Input';
import InputLabel from 'src/components/inputLabel/InputLabel';
import InputPhone from 'src/components/inputPhone/InputPhone';
import TermsCheckbox from 'src/components/termsCheckbox/TermsCheckbox';
import ValidationMessage from 'src/components/validationMessage/ValidationMessage.styled';
import { Colors } from 'src/theme/colors';
import '../../../../components/contactForm/transition.css';
import { S } from './PivotsContactSection.styled';

const PivotsContactSection = () => {
  const formName = 'pivOTS contact form';

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IContactFormData>({ mode: 'onBlur' });
  const termsChecked = watch('terms', false);

  const [sendContactFormData] = useContactForm(formName);

  return (
    <S.Root>
      <SwitchTransition mode="out-in">
        <CSSTransition key={isSubmitSuccessful ? 'success' : 'form'} timeout={500} classNames="fade">
          <>
            <S.Background />
            {!isSubmitSuccessful ? (
              <S.FormContainer>
                <S.Title>Want to learn more? We’d love to hear from you.</S.Title>
                <form
                  name={formName}
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit(sendContactFormData)}>
                  <input type="hidden" name="form-name" value={formName} />
                  <S.FormContent>
                    <S.InputWrapper>
                      <InputLabel htmlFor="name">Name</InputLabel>
                      <Input
                        name="name"
                        id="name"
                        title="name"
                        register={register}
                        placeholder="Name"
                        rules={{ required: true }}
                      />
                      {errors.name && <ValidationMessage>This field is required</ValidationMessage>}
                    </S.InputWrapper>

                    <S.InputWrapper>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <Input
                        name="email"
                        id="email"
                        title="email"
                        register={register}
                        placeholder="Email"
                        inputMode="email"
                        rules={{
                          required: 'This field is required',
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Please provide a properly formatted email address',
                          },
                        }}
                      />
                      {errors.email && <ValidationMessage>{errors.email.message}</ValidationMessage>}
                    </S.InputWrapper>
                    <S.InputWrapper>
                      <InputLabel htmlFor="businessName">
                        <span>Business name</span>
                        <S.ContactInputOptionalLabel>(Optional)</S.ContactInputOptionalLabel>
                      </InputLabel>
                      <Input
                        name="businessName"
                        id="businessName"
                        title="Business name"
                        register={register}
                        placeholder="Business name"
                      />
                    </S.InputWrapper>
                    <S.InputWrapper>
                      <InputLabel htmlFor="phone">
                        <span>Phone number</span>
                        <S.ContactInputOptionalLabel>(Optional)</S.ContactInputOptionalLabel>
                      </InputLabel>
                      <InputPhone
                        defaultCountry="gb"
                        preferredCountries={['pl', 'ie', 'sk', 'gb']}
                        setValue={val => {
                          setValue('phone', val);
                        }}
                      />
                    </S.InputWrapper>
                    <S.TermsCheckboxWrapper>
                      <TermsCheckbox
                        onChange={value => setValue('terms', value)}
                        register={register}
                        checked={termsChecked}
                      />
                      {errors.terms && <ValidationMessage>You need to accept the Privacy Policy</ValidationMessage>}
                    </S.TermsCheckboxWrapper>
                    <S.InputWrapper>
                      <S.Button title="Submit form" type="submit">
                        Send
                      </S.Button>
                    </S.InputWrapper>
                  </S.FormContent>
                </form>
              </S.FormContainer>
            ) : (
              <FormSuccess $textColor={Colors.ui.bodyText} />
            )}
          </>
        </CSSTransition>
      </SwitchTransition>
    </S.Root>
  );
};

export default PivotsContactSection;
