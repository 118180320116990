import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import BoxCarousel, { carouselPresetMain } from 'src/components/boxCarousel/BoxCarousel';
import { S } from './StrengthsSection.styled';
import StrengthCard from './components/strengthCard/StrengthCard';

const StrengthsSection = () => {
  return (
    <section>
      <S.CarouselWrapper>
        <BoxCarousel config={carouselPresetMain}>
          <StrengthCard
            title="Separate the qualified from the unqualified"
            description="Checking eligibility for fibre? All you need is an address. SwitchedOn Fibre™ does all the work to quickly identify a potential customer."
            SideImage={
              <StaticImage
                src="img/strength-1.png"
                alt="Strength 1 image"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            }
          />
          <StrengthCard
            title="Get quotes and prices at a glance"
            description="Get instant access to all your products and pricing through our dashboard. It couldn’t be easier to access product prices and share quotes with customers."
            SideImage={
              <StaticImage
                src="img/strength-2.png"
                alt="Strength 2 image"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            }
          />
          <StrengthCard
            title="An eCommerce user experience"
            description="Today’s customers want and expect the equivalent of an internet shopping experience. They want quick delivery and constant updates. We can meet those expectations."
            SideImage={
              <StaticImage
                src="img/strength-3.png"
                alt="Strength 3 image"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            }
          />
          <StrengthCard
            title="Smooth installation"
            description="Installation doesn’t have to be difficult. From scheduling to activation, our installation workflow is designed to make things easier."
            SideImage={
              <StaticImage
                src="img/strength-4.png"
                alt="Strength 4 image"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            }
          />
          <StrengthCard
            title="Management made easy"
            description="Service doesn’t stop with a sale. Our intelligent automation handles everything from invoicing to upgrades to keep you and your customers happy."
            SideImage={
              <StaticImage
                src="img/strength-5.png"
                alt="Strength 5 image"
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            }
          />
        </BoxCarousel>
      </S.CarouselWrapper>
    </section>
  );
};

export default StrengthsSection;
