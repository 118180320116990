import React from 'react';
import BoxText from 'src/components/boxText/BoxText';
import { Root, Row, Description, CartTitle, CartDescription, MainTitle } from './personalDevelopmentBox.styled';

const PersonalDevelopmentBox = () => {
  return (
    <Root>
      <MainTitle>Learning & development</MainTitle>
      <Description>
        <BoxText>
          Sonalakers stay with us because we deliver on career progression and provide opportunities for continuous
          professional development. Our learning and development initiatives cover technologies, methodologies, design,
          emotional intelligence and people-oriented competencies.
        </BoxText>
      </Description>
      <Row>
        <div>
          <CartTitle>Investing in you</CartTitle>
          <CartDescription>
            Every Sonalaker gets a training budget and paid training days every year to upskill. This covers courses,
            training, books or conferences. It’s not just technical training in AWS, Java, Kotlin or React, it also
            covers soft skills like leadership training, interview techniques or English lessons.
          </CartDescription>
        </div>
        <div>
          <CartTitle>Knowledge sharing</CartTitle>
          <CartDescription>
            Peer-to-peer knowledge sharing is central to what we do. We organise weekly tech, design or process talks
            and host monthly all-hands meetings to keep everyone updated. Our internal workshops provide insights into
            new technologies and processes.
          </CartDescription>
        </div>
      </Row>
      <Row>
        <div>
          <CartTitle>Communities of practice</CartTitle>
          <CartDescription>
            We form expertise groups to discuss disciplines like front-end, back-end, project management, UX and QA. We
            delve into new concepts, burning issues, new tools or the latest trends. You can meet people with similar
            interests, stay informed and get insights on the latest industry developments.
          </CartDescription>
        </div>
        <div>
          <CartTitle>Learn on the job</CartTitle>
          <CartDescription>
            Our varied client base provides opportunities to work on different tech stacks, methodologies and
            applications. You can work with international organisations and develop your English, if it’s not your first
            language. Our experienced senior developers, designers and leaders are on hand to provide expertise and
            mentorship.
          </CartDescription>
        </div>
      </Row>
    </Root>
  );
};

export default PersonalDevelopmentBox;
