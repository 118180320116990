import React from 'react';
import { IParsedCaseStudyListItemData } from 'src/types/caseStudies.types';
import { S } from './CaseStudiesSection.styled';
import CaseStudyCard from './components/caseStudyCard/CaseStudyCard';

interface IProps {
  caseStudies: IParsedCaseStudyListItemData[];
}

const CaseStudiesSection: React.FC<IProps> = ({ caseStudies }) => (
  <section>
    <S.Title>SwitchedOn Billing™: Case studies</S.Title>
    <S.CardsContainer>
      {caseStudies.map(caseStudy => (
        <CaseStudyCard key={caseStudy.id} caseStudy={caseStudy} />
      ))}
    </S.CardsContainer>
  </section>
);

export default CaseStudiesSection;
