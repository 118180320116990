import { useRef, useState } from 'react';
import { useIsomorficLayoutEffect } from './useIsomorficLayoutEffect';
import { useWindowSize } from './useWindowSize';

export const useTypewritterHeight = (typewriterBeforeText: string, typewriterTexts: string[]) => {
  const [height, setHeight] = useState('200px');
  const ref = useRef<HTMLDivElement>(null);
  const size = useWindowSize();

  useIsomorficLayoutEffect(() => {
    if (ref?.current) {
      const weight = window.getComputedStyle(ref?.current).fontWeight;
      const size = window.getComputedStyle(ref?.current).fontSize;
      const family = window.getComputedStyle(ref?.current).fontFamily;

      const font = `${weight} ${size} ${family}`;

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) return;
      context.font = font;
      const longestTypeWritterText = [...typewriterTexts].sort((a, b) => (a.length <= b.length ? 1 : -1))[0];
      const { width } = context.measureText(`${typewriterBeforeText} ${longestTypeWritterText}`);
      const lines = Math.ceil(width / ref?.current?.offsetWidth);
      setHeight(`${lines * 1.5}em`);
    }
  }, [typewriterBeforeText, typewriterTexts, size]);
  return {
    ref,
    height,
  };
};
