import styled from 'styled-components';

export const BoxButton = styled.button`
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;

  background: ${({ $theme }: { $theme?: string }) => {
    switch ($theme) {
      case 'visimetrix':
        return '#3EB7C2';
      case 'switchedonfibre':
        return '#4E92FF';
      case 'dark':
        return '#494F52';
      case 'teal':
        return '#3EB7C2';
      default:
        return '#fff';
    }
  }};

  color: ${({ $theme }: { $theme?: string }) => {
    switch ($theme) {
      case 'visimetrix':
        return '#fff';
      case 'switchedonfibre':
        return '#fff';
      case 'dark':
        return '#FFFFFF';
      case 'teal':
        return '#FFF';
      default:
        return '#000';
    }
  }};

  &:hover {
    background: ${({ $theme }: { $theme?: string }) => {
      switch ($theme) {
        case 'visimetrix':
          return '#51BEC8';
        case 'switchedonfibre':
          return '#68A2FF';
        case 'dark':
          return '#44494c';
        case 'teal':
          return '#56C0CA';
        default:
          return '#dadada';
      }
    }};
  }
`;

export default BoxButton;
