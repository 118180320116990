import { css } from 'styled-components';

const TextLink = css`
  color: white;
  text-decoration: underline;
  font-weight: bold;
`;

const LightTextLink = css`
  color: #494f52;
  text-decoration: underline;
  font-weight: bold;
`;

export const StyleMixins = {
  TextLink,
  LightTextLink,
};
