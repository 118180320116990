import styled from 'styled-components';
import { Colors } from 'src/theme/colors';

export const Tag = styled.span`
  text-transform: capitalize;
  color: ${Colors.tags};
  font: bold 10px Inter;
  &:not(:last-child):after {
    content: ', ';
  }

  .light & {
    color: #767b7d;
  }
`;
