import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import VisimetrixPageHeader from 'src/components/visimetrix/visimetrixPageHeader/VisimetrixPageHeader';
import VisimetrixPageLayout from 'src/components/visimetrix/visimetrixPageLayout/VisimetrixPageLayout';
import { IAuthorCaptionData } from 'src/types/blog.types';
import BlogPostSection from './sections/blogPostSection/BlogPostSection';
import InfoSection from './sections/infoSection/InfoSection';
import IntroSection from './sections/introSection/IntroSection';

interface IProps {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        creator: string;
        date: string;
        iconType: string;
        authorCaption: IAuthorCaptionData;
      };
      fields: {
        slug: string;
        readingTime: {
          text: string;
        };
      };
    };
  };
}

const VisimetrixDataSciencePage: React.FC<IProps> = ({ data }) => {
  const { frontmatter, fields } = data.markdownRemark;
  const pageTitle = 'VisiMetrix - Data science & machine learning models';
  const pageDescription =
    'Detect network anomalies before they affect critical processes with baseline-triggered alerts and forecasting features.';

  return (
    <VisimetrixPageLayout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
      </Helmet>
      <VisimetrixPageHeader
        title="Data science & machine learning"
        subtitle="Detect anomalies before they affect critical processes with baseline-triggered alerts and forecasting features."
        isSubPage={true}
      />
      <IntroSection />
      <BlogPostSection
        post={{
          iconType: frontmatter.iconType,
          readingTime: fields.readingTime.text,
          authorCaptionData: frontmatter.authorCaption,
          slug: fields.slug,
          date: frontmatter.date,
          title: frontmatter.title,
        }}
      />
      <InfoSection />
    </VisimetrixPageLayout>
  );
};

export default VisimetrixDataSciencePage;

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { postKey: { eq: "networkCongestionForecasting" } }) {
      id
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        date(formatString: "DD MMM YYYY")
        title
        creator
        iconType
        authorCaption {
          name
          position
          photo {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 40)
            }
          }
        }
      }
    }
  }
`;
