import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';

const StyledBackgroundImage = styled.div`
  @media ${mediaQuery.navbarCollapse} {
    background-image: url('/backgrounds/switched-on-billing-pattern.svg');
    background-size: 40%;
    background-position: right top;
    background-repeat: no-repeat;
  }
`;

const PageSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;

  @media ${mediaQuery.tablet} {
    gap: 80px;
    margin-bottom: 80px;
  }
`;

export const S = {
  StyledBackgroundImage,
  PageSectionsWrapper,
};
