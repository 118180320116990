import * as React from 'react';
import UIStore from 'src/store/Store';
import { Colors } from 'src/theme/colors';
import { AddressLine, AddressTitle, DirectionsLink, DirectionsText, Root } from './address.styled';
import MapMarker from './img/map-marker.svg';

export interface Props {
  title: string;
  addressLines: string[];
  mapUrl: string;
  onClick: () => void;
}
export const Address = ({ title, addressLines, mapUrl, onClick }: Props) => {
  const isDarkMode = UIStore.useState((s: any) => s.isDarkMode);

  return (
    <Root onClick={onClick}>
      <AddressTitle>{title}</AddressTitle>
      <div>
        {addressLines.map((line, i) => (
          <AddressLine key={i}>{line}</AddressLine>
        ))}
      </div>
      <DirectionsLink href={mapUrl} target="_blank" rel="noreferrer">
        <MapMarker css={{ fill: isDarkMode ? Colors.white : Colors.ui.greyDark, marginRight: '4px' }} />
        <DirectionsText>Get directions</DirectionsText>
      </DirectionsLink>
    </Root>
  );
};
