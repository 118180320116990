import { graphql } from 'gatsby';
import { IFluidObject } from 'gatsby-background-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'src/components/layout/Layout';
import useSiteMetadata from 'src/components/layout/hooks/useSiteMetadata';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import { ThemeName } from 'src/theme/ThemeStyles';
import { ICaseStudyListItemData, IParsedCaseStudyListItemData } from 'src/types/caseStudies.types';
import { S } from './SwitchedOnFibrePage.styled';
import SwitchOnFibreTestimonialsCarousel from './sections/SwitchOnFibreTestimonials/SwitchOnFibreTestimonialsCarousel';
import CaseStudiesSection from './sections/caseStudiesSection/CaseStudiesSection';
import DescriptionSection from './sections/descriptionSection/DescriptionSection';
import HeaderSection from './sections/headerSection/HeaderSection';
import LeadToCashSection from './sections/leadToCashSection/LeadToCashSection';
import RequestDemoSection from './sections/requestDemoSection/RequestDemoSection';
import StrengthsSection from './sections/strengthsSection/StrengthsSection';

interface IProps {
  data: {
    file: { childImageSharp: { fluid: IFluidObject } };
    caseStudiesData: {
      caseStudiesList: ICaseStudyListItemData[];
    };
  };
}

const flattenCaseStudiesData = (caseStudiesData: ICaseStudyListItemData[]): IParsedCaseStudyListItemData[] => {
  return caseStudiesData.map(({ caseItem }: ICaseStudyListItemData): IParsedCaseStudyListItemData => {
    return {
      id: caseItem.id,
      slug: caseItem.fields.slug,
      ...caseItem.frontmatter,
    };
  });
};

export const SwitchedOnFibrePage: React.FC<IProps> = ({ data }) => {
  const { siteUrl } = useSiteMetadata();

  const caseStudies = flattenCaseStudiesData(data.caseStudiesData.caseStudiesList);

  return (
    <Layout theme={ThemeName.switchedOnFibre}>
      <Helmet>
        <title>SwitchedOn Fibre™ - Automation of lead-to-cash fibre journey</title>
        <meta property="og:title" content="SwitchedOn Fibre™ - Automation of lead-to-cash fibre journey" />
        <meta
          name="description"
          content="Sonalake’s FTTx BSS is a modular suite of software used by fibre operators to automate complex processes across wholesale, retail and enterprise services."
        />
        <meta
          property="og:description"
          content='Sonalake’s FTTx BSS is a modular suite of software used by fibre operators to automate complex processes across wholesale, retail and enterprise services."'
        />
        <meta property="og:image" content={`${siteUrl}/banners/switchedon-fibre.jpeg`} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      <S.StyledBackgroundImage fluid={data.file.childImageSharp.fluid}>
        <PageContentWide>
          <S.PageSectionsWrapper>
            <HeaderSection />
            <DescriptionSection />
            <LeadToCashSection />
            <StrengthsSection />
            <CaseStudiesSection caseStudies={caseStudies} />
            <RequestDemoSection />
            <SwitchOnFibreTestimonialsCarousel />
          </S.PageSectionsWrapper>
        </PageContentWide>
      </S.StyledBackgroundImage>
    </Layout>
  );
};

export default SwitchedOnFibrePage;

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "switched-on-fibre-bg.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    caseStudiesData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "caseStudy" }, company: { in: ["NBI", "Enet"] } } }
    ) {
      caseStudiesList: edges {
        caseItem: node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            category
            company
            coverImageAlt
            companyLogoImage {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED)
              }
              extension
              publicURL
            }
            coverImage {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
