import React from 'react';
import { S } from './OTSPanel.styled';

const OTSPanel = () => {
  return (
    <S.Panel>
      <S.Subtitle>One Touch Switching UK</S.Subtitle>
      <S.Title>OTS compliance</S.Title>
      <S.Description>
        OTS is being introduced by Ofcom to make it easier for customers to switch between providers. This mandatory
        process will require changes to your IT systems and processes.
        <S.OTSLink to={'https://ots.sonalake.com/'} aria-label={'OTS Sonalake'}>
          Here’s how Sonalake can help.
        </S.OTSLink>
      </S.Description>
    </S.Panel>
  );
};

export default OTSPanel;
