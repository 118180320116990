import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { Typography } from 'src/theme/typography';

const PageDescription = styled.h2`
  ${Typography.body[16]};
  color: ${Colors.ui.bodyText};
  margin: 0;

  @media ${mediaQuery.tablet} {
    max-width: 480px;
  }

  .light & {
    color: #494f52;
  }
`;

export const PageDescriptionLight = styled.p`
  font-size: 20px;
  }
  .light & {
    color: #494f52;
  }
`;

export default PageDescription;
