import { useLocation } from '@reach/router';
import { navigate } from '@reach/router';
import axios from 'axios';
import React, { useEffect, useMemo } from 'react';
import type { IJob } from 'src/types/job.types';

enum ActionType {
  setData = 'setData',
  setError = 'setError',
  setLocationTag = 'setLocationTag',
  setSearchQuery = 'setSearchQuery',
}

const defaultLocationTags = {
  all: 'All',
  poland: 'Poland',
  ireland: 'Ireland',
  slovakia: 'Slovakia',
  other: 'Other',
};

type IAction =
  | { type: ActionType.setData; payload: IJob[] }
  | { type: ActionType.setError; payload: string }
  | { type: ActionType.setLocationTag; payload: string }
  | { type: ActionType.setSearchQuery; payload: string };

interface IState {
  jobs: IJob[];
  filteredJobs: IJob[];
  loading: boolean;
  error?: string;
  locationTags: string[];
  activeLocationTag: string;
  searchQuery: string;
}

const initialState: IState = {
  jobs: [],
  filteredJobs: [],
  loading: true,
  error: '',
  locationTags: [],
  activeLocationTag: defaultLocationTags.all,
  searchQuery: '',
};

const parsedLocationTags = (jobs: IJob[]) => {
  const tagsByLocation = jobs.reduce((tags: string[], job: IJob) => {
    const locations = job.locations.map(l => l.country_name);
    for (const location of locations) {
      if (defaultLocationTags[location.toLowerCase() as keyof typeof defaultLocationTags] && !tags.includes(location)) {
        tags.push(location);
      }
    }

    return tags;
  }, [] as string[]);

  return [defaultLocationTags.all, ...tagsByLocation.sort(), defaultLocationTags.other];
};

const filterJobsByLocation = (jobs: IJob[], locationTag: string): IJob[] => {
  if (locationTag === defaultLocationTags.all) {
    return jobs;
  }

  if (locationTag === defaultLocationTags.other) {
    const defaultCountryValues = Object.values(defaultLocationTags);
    return jobs.filter((job: IJob) => job.locations.some(l => !defaultCountryValues.includes(l.country_name)));
  }

  return jobs.filter((job: IJob) => job.locations.some(l => l.country_name === locationTag));
};

const excludeEshopWorldJobs = (jobs: IJob): boolean => {
  return !jobs.title.toLowerCase().includes('eshopworld');
};

const filterJobs = (jobs: IJob[], locationTag: string, searchQuery = ''): IJob[] => {
  const filteredJobs = filterJobsByLocation(jobs, locationTag);

  if (searchQuery) {
    return filteredJobs
      .filter(excludeEshopWorldJobs)
      .filter((job: IJob) => job.title.toLowerCase().includes(searchQuery.toLowerCase()));
  } else {
    return filteredJobs.filter(excludeEshopWorldJobs);
  }
};

const jobsReducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case ActionType.setData:
      return {
        ...state,
        jobs: action.payload,
        filteredJobs: filterJobs(action.payload, state.activeLocationTag, state.searchQuery),
        loading: false,
        locationTags: parsedLocationTags(action.payload),
      };

    case ActionType.setError:
      return { ...state, jobs: [], loading: false, error: action.payload };

    case ActionType.setLocationTag:
      return {
        ...state,
        activeLocationTag: action.payload,
        filteredJobs: filterJobs(state.jobs, action.payload, state.searchQuery),
      };

    case ActionType.setSearchQuery:
      return {
        ...state,
        searchQuery: action.payload,
        filteredJobs: filterJobs(state.jobs, state.activeLocationTag, action.payload),
      };
  }
};

export function useJobsList(): [IState, (tag: string) => void, (searchQuery: string) => void] {
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [state, dispatch] = React.useReducer(jobsReducer, {
    ...initialState,
    activeLocationTag: urlSearchParams.get('location') ?? defaultLocationTags.all,
  });
  const setLocationTag = (tag: string) => {
    navigate(`?location=${tag}#jobs`);
    return dispatch({ type: ActionType.setLocationTag, payload: tag });
  };
  const setSearchQuery = (query: string) => dispatch({ type: ActionType.setSearchQuery, payload: query });

  useEffect(() => {
    axios
      .get('/.netlify/functions/jobs-read/jobs-read')
      .then((response: { data: { jobs: IJob[] } }) => {
        dispatch({ type: ActionType.setData, payload: response.data.jobs });
      })
      .catch(() => dispatch({ type: ActionType.setError, payload: 'Cannot get jobs list' }));
  }, []);

  return [state, setLocationTag, setSearchQuery];
}
