import React from 'react';
import { S } from './SimpleFlowItem.styled';

interface IProps {
  index: number;
  title: string;
}

const SimpleFlowItem = ({ index, title }: IProps) => (
  <S.Item>
    <S.Index>{index}</S.Index>
    <S.Title>{title}</S.Title>
  </S.Item>
);

export default SimpleFlowItem;
