import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import CaseStudiesBox from 'src/components/caseStudiesBox/CaseStudiesBox';
import Layout from 'src/components/layout/Layout';
import PageContentSectionsWrapper from 'src/components/pageContentSectionsWrapper/PageContentSectionsWrapper';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import PageDescription from 'src/components/pageDescription/PageDescription';
import PageHeading from 'src/components/pageHeading/PageHeading';
import PageTitle from 'src/components/pageTitle/PageTitle';
import OurServicesBox from './components/ourServicesBox/OurServicesBox';
import ProjectManagementBox from './components/projectManagementBox/ProjectManagementBox';
import WhatWeDoTestimonialsBox from './components/whatWeDoTestimonialsBox/WhatWeDoTestimonialsBox';

interface IProps {
  data: {
    markdownRemark: {
      frontmatter: {
        pageTitle: string;
        title: string;
        description: string;
        pageDescription: string;
      };
    };
  };
}

const WhatWeDoPage = ({ data }: IProps) => {
  const { title, description, pageTitle, pageDescription } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
      </Helmet>
      <PageContentWide>
        <PageContentSectionsWrapper>
          <PageHeading>
            <PageTitle>{title}</PageTitle>
            <PageDescription>{description}</PageDescription>
          </PageHeading>
          <OurServicesBox />
          <CaseStudiesBox />
          <WhatWeDoTestimonialsBox />
          <ProjectManagementBox />
        </PageContentSectionsWrapper>
      </PageContentWide>
    </Layout>
  );
};

export default WhatWeDoPage;

export const pageQuery = graphql`
  query WhatWeDoPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        title
        description
        pageDescription
      }
    }
  }
`;
