import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import SadFaceIcon from './img/sad-face.svg';

const Container = styled.div`
  padding-top: 64px;
  color: ${Colors.white};

  .light & {
    color: ${Colors.ui.greyDark};
  }
`;

const StyledSadFaceIcon = styled(SadFaceIcon)`
  margin-bottom: 64px;
`;

const Header = styled.h2`
  font-weight: 800;
  font-size: 56px;
  line-height: 44px;
  letter-spacing: -4px;

  @media ${mediaQuery.smallScreen} {
    width: 212px;
    font-size: 72px;
    line-height: 64px;
  }
`;

const SubHeader = styled.h4`
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: -2px;

  @media ${mediaQuery.smallScreen} {
    font-size: 34px;
    line-height: 36px;
  }
`;

export const S = { Container, StyledSadFaceIcon, Header, SubHeader };
