import styled from 'styled-components';

export const Root = styled.div`
  color: #dddedf;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`;
