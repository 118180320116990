import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from 'src/components/layout/hooks/useSiteMetadata';
import VisimetrixPageHeader from 'src/components/visimetrix/visimetrixPageHeader/VisimetrixPageHeader';
import VisimetrixPageLayout from 'src/components/visimetrix/visimetrixPageLayout/VisimetrixPageLayout';
import { flattenBlogPostsData } from 'src/services/blog/blog';
import UIStore from 'src/store/Store';
import { IBlogPostListItemData } from 'src/types/blog.types';
import AwardsSection from './sections/awardSection/AwardsSection';
import BookConsultationSection from './sections/bookConsultationSection/bookConsultationSection';
import IntroSection from './sections/introSection/IntroSection';
import LatestPostsSection from './sections/latestPostsSection/LatestPostsSection';
import NocSection from './sections/nocSection/NocSection';
import NocSupportSection from './sections/nocSupportSection/NocSupportSection';
import TestimonialsSection from './sections/testimonialsSection/TestimonialsSection';

interface IProps {
  data: {
    blogPostsData: {
      blogPostsList: IBlogPostListItemData[];
    };
  };
}

const VisimetrixPage: React.FC<IProps> = ({ data }) => {
  const { siteUrl } = useSiteMetadata();

  UIStore.update(s => {
    s.isProductPage = true;
  });
  const {
    blogPostsData: { blogPostsList },
  } = data;
  const latestPosts = flattenBlogPostsData(blogPostsList);

  const pageTitle = 'VisiMetrix - AIOps enabled network service analytics';
  const pageDescription =
    'AIOps enabled network service analytics to deliver real time monitoring, predictive analytics, user-friendly visualisations and multi-vendor integration.';

  return (
    <VisimetrixPageLayout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={`${siteUrl}/banners/visimetrix.jpeg`} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <VisimetrixPageHeader
        title="VisiMetrix™"
        subtitle="VisiMetrix provides operators with new ways to monitor and analyse network performance. It can extract data from
      multi-vendor systems, predict trends, identify problems, assess customer impact and facilitate early intervention."
      />
      <IntroSection />
      <TestimonialsSection />
      <NocSection />
      <NocSupportSection />
      <AwardsSection />
      <BookConsultationSection />
      <LatestPostsSection posts={latestPosts} />
    </VisimetrixPageLayout>
  );
};

export default VisimetrixPage;

export const pageQuery = graphql`
  query {
    blogPostsData: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blogPost" }, categories: { in: ["visimetrix"] } } }
      limit: 3
    ) {
      blogPostsList: edges {
        postItem: node {
          id
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            title
            description
            templateKey
            categories
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            creator
            iconType
          }
        }
      }
    }
  }
`;
