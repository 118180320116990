import GoogleMapReact, { Coords } from 'google-map-react';
import React, { useCallback } from 'react';
import { IMapState } from '../findUsBox/FindUsBox';
import { addressList, mapsApiKey } from './config';
import { MarkerStyled, Root } from './findUsMap.styled';
import mapStyles from './mapStyles';

interface MarkerProps {
  lat: number;
  lng: number;
  url: string;
  onMarkerClick: ({ lng, lat, url }: Coords & { url: string }) => void;
}
const Marker = ({ lat, lng, url, onMarkerClick }: MarkerProps) => {
  return <MarkerStyled onClick={() => onMarkerClick({ lat, lng, url })} />;
};

interface IProps {
  mapState: IMapState;
  onMapStateChange: (state: Partial<IMapState>) => void;
  onMarkerClick: (center: Coords) => void;
}

const FindUsMap = ({ mapState, onMapStateChange, onMarkerClick }: IProps) => {
  const onMarkerInnerClick = useCallback(
    ({ lat, lng, url }: { lat: number; lng: number; url: string }) => {
      if (mapState.zoom !== 10) {
        onMarkerClick({ lat, lng });
      } else window.open(url, '_blank', 'noopener,noreferrer');
    },
    [onMarkerClick, mapState.zoom],
  );

  return (
    <Root title="Map showing Sonalake office locations in Ireland, Poland, and Slovakia.">
      <GoogleMapReact
        bootstrapURLKeys={process.env.NODE_ENV === 'development' ? undefined : { key: mapsApiKey }}
        center={mapState.center}
        zoom={mapState.zoom}
        onChange={onMapStateChange}
        options={{ styles: mapStyles }}>
        {addressList.map(address => (
          <Marker
            key={address.title}
            lat={address.coords.lat}
            lng={address.coords.lng}
            url={address.mapUrl}
            onMarkerClick={onMarkerInnerClick}
          />
        ))}
      </GoogleMapReact>
    </Root>
  );
};

export default FindUsMap;
