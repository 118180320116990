import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisimetrixInfoItem from 'src/components/visimetrix/visimetrixInfoItem/VisimetrixInfoItem';
import { S } from './InfoSection.styled';

const InfoSection = () => (
  <S.Section>
    <S.RowWithSeparator>
      <VisimetrixInfoItem
        title="Predictive analytics"
        descriptions={[
          'VisiMetrix models your data to highlight trends, make accurate predictions, and alert you to any statistical anomalies. It uses advanced analytics to learn seasonal patterns and variations, which allow it to set dynamic thresholds that dramatically reduce false alerts.',
        ]}
        ItemImage={
          <StaticImage
            src="img/predictive-analytics.png"
            alt="VisiMetrix - screenshot of predictive analytics charts"
          />
        }
        tabletOrientation="bottom"
      />
      <VisimetrixInfoItem
        title="Adaptive thresholds"
        descriptions={[
          'Powerful thresholds and alerts features ensure you get the right information when you need it. Get real-time visibility of network data, service characteristics and quality measurements in your chosen combinations across all network technologies.',
        ]}
        ItemImage={<StaticImage src="img/adaptive-thresholds.png" alt="VisiMetrix - screenshot of real time alerts" />}
        tabletOrientation="bottom"
      />
    </S.RowWithSeparator>

    <S.Row>
      <VisimetrixInfoItem
        title="Event correlation and proactive warnings"
        descriptions={[
          'Event correlation, service status calculation and automated actions enable VisiMetrix to proactively flag a potential problem. ',
          'It can even understand its impact on your business. IT teams can use this information to implement dynamic capacity planning and disaster prevention procedures.',
        ]}
        ItemImage={<StaticImage src="img/event-correlation.png" alt="VisiMetrix - screenshot of geo view" />}
        tabletOrientation="bottom"
      />
      <VisimetrixInfoItem
        title="Anomaly detection and baselining"
        descriptions={[
          'VisiMetrix learns how a given service should behave over time. That lets it recognise and report anomalies or unusual network behaviour.',
          'Manual or automated baseline calculation and baseline-triggered alerts flag potential issues, allowing them to be addressed before they become problems.',
        ]}
        ItemImage={<StaticImage src="img/anomaly-detection.png" alt="VisiMetrix - screenshot of entity table" />}
        tabletOrientation="bottom"
      />
      <VisimetrixInfoItem
        title="Forecast resource requirements"
        descriptions={[
          'Advanced analytical models can forecast IT or network resource availability, which facilitates capacity analysis.',
          'Get a clearer picture of your future infrastructure requirements. Use data-driven predictions to make informed decisions about infrastructure investment and upgrades.',
        ]}
        ItemImage={<StaticImage src="img/forecast-resource.png" alt="VisiMetrix - screenshot of wallboard" />}
        tabletOrientation="bottom"
      />
    </S.Row>
  </S.Section>
);

export default InfoSection;
