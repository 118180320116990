import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import Totsco from './img/totsco.svg';

const InfoSection = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  @media ${mediaQuery.smallScreen} {
    row-gap: 40px;
  }
`;

const InfoImgContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const HighlightedSection = styled.div`
  background-color: ${Colors.white};
  color: ${Colors.ui.black};
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SectionParagraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0px;
`;

const InlineSectionParagraph = styled(SectionParagraph)`
  display: inline;
`;

const BlogAnchor = styled.a`
  font-weight: bold;
  font-size: 20px;
  text-decoration: underline;
`;

const SectionHeader = styled.h3`
  font-size: 34px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -2px;
`;

const SectionList = styled.ul`
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  list-style-position: outside;
  padding-left: 1em;

  & > li {
    margin-bottom: 0px;
  }
`;

const FeaturesListContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${mediaQuery.smallScreen} {
    flex-direction: row;
    gap: 80px;
  }

  & > ul {
    flex-grow: 1;
  }
`;

const TotscoLogo = styled(Totsco)`
  height: 80px;
  width: 80px;
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
`;

export const S = {
  InfoSection,
  InfoImgContainer,
  SectionParagraph,
  HighlightedSection,
  InlineSectionParagraph,
  BlogAnchor,
  SectionHeader,
  SectionList,
  FeaturesListContainer,
  TotscoLogo,
};
