import React from 'react';
import { Root } from './BackButton.styled';
import ChevronIcon from './img/chevron-left.svg';

const BackButton = () => {
  return (
    <Root className="fill-white light:fill-black">
      <ChevronIcon className="fill-white light:fill-black" />
      Back
    </Root>
  );
};

export default BackButton;
