import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingMobile, boxPaddingVerticalDesktop, boxPaddingHorizontalDesktop } from 'src/theme/dimensions';

const Section = styled.section`
  border-radius: 16px;
  background-color: ${Colors.ui.teal[50]};
  position: relative;
  overflow: hidden;
`;

const Wrapper = styled.div`
  padding: ${boxPaddingMobile};
  @media ${mediaQuery.desktop} {
    padding: ${boxPaddingVerticalDesktop} ${boxPaddingHorizontalDesktop};
  }
`;

export const S = {
  Section,
  Wrapper,
};
