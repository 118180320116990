import styled from 'styled-components';
import PageBoxWrapper from 'src/components/pageBoxWrapper/PageBoxWrapper';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

export const Root = styled(PageBoxWrapper)`
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: -5;
    background-color: #1b2327;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: -1;

    background-image: url('/img/learning-background.svg');
    background-size: inherit;
    background-position: right top;
    background-repeat: no-repeat;
    @media ${mediaQuery.tablet} {
      opacity: 1;
    }
  }
  .light & {
    background-color: ${Colors.ui.componentLight};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${mediaQuery.desktop} {
    row-gap: 24px;
    column-gap: 170px;
    margin-top: 64px;
    flex-direction: row;
  }

  svg {
    width: 100%;
    height: 100%;

    @media ${mediaQuery.desktop} {
      height: unset;
    }
  }
`;

export const Description = styled.div`
  margin-top: 40px;
  margin-bottom: 60px;
  max-width: 520px;

  .light & {
    color: ${Colors.ui.bodyTextLight};
  }
`;

export const MainTitle = styled.h3`
  letter-spacing: -1.5px;
  font-weight: 800;
  font-size: 24px;

  .light & {
    color: ${Colors.ui.titleTextLight};
  }

  @media ${mediaQuery.tablet} {
    font-size: 40px;
  }

  @media ${mediaQuery.navbarCollapse} {
    font-size: 36px;
  }
`;

export const CartTitle = styled.h3`
  font-size: 20px;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 10px;
  @media ${mediaQuery.tablet} {
    font-size: 28px;
  }

  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;

export const CartDescription = styled.div`
  margin-top: 10px;
  line-height: 20px;
  max-width: 400px;
  margin-bottom: 60px;
  letter-spacing: -0.5px;

  color: ${Colors.ui.bodyText};
  .light & {
    color: ${Colors.ui.bodyTextLight};
  }

  @media ${mediaQuery.desktop} {
    margin-bottom: unset;
  }
`;
