import Link from 'gatsby-link';
import styled from 'styled-components';

const Panel = styled.div`
  background-color: #fff;
  border-radius: 8px;
  width: 347px;
  padding: 8px;
  margin-top: 80px;
  color: #262e32;
`;

const Title = styled.h3`
  font-size: 20px;
  line-height: 24.2px;
  margin-bottom: 10px;
`;

const Subtitle = styled.h4`
  font-size: 12px;
  margin-bottom: 10px;
`;

const OTSLink = styled(Link)`
  flex-direction: column;
  color: #3774db;
  text-decoration: underline;
  margin-left: 2px;
  font-weight: bold;
`;

const Description = styled.p`
  font-size: 12px;
  margin-bottom: 0px;
`;

export const S = {
  Title,
  Subtitle,
  Panel,
  OTSLink,
  Description,
};
