import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import AuthorCaption from 'src/modules/blogPost/components/authorCaption/AuthorCaption';
import PublishedInfo from 'src/modules/blogPost/components/publishedInfo/PublishedInfo';
import { Colors } from 'src/theme/colors';

const Banner = styled.div`
  padding: 48px;
  width: 100%;
  background-color: ${Colors.ui.teal['50']};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${mediaQuery.smallScreen} {
    flex-direction: row;
  }
`;

const StyledPublishedInfo = styled(PublishedInfo)`
  margin-bottom: 16px;
  ${props => (props.theme === 'blog' ? `color: ${Colors.white};` : '')}
`;

const TitleAndAuthorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.theme === 'visimetrix'
      ? ` svg {
    filter: drop-shadow(3px 4px 3px rgb(0 0 0 / 0.3));
  }`
      : ''}
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -2px;
  color: ${Colors.white};
  margin-bottom: 32px;
`;

const StyledAuthorCaption = styled(AuthorCaption)`
  margin-bottom: 0;
  ${props => (props.theme === 'blog' ? `color: ${Colors.white};` : '')}
`;

export const StyledImage = styled(GatsbyImage)`
  flex-shrink: 0;

  @media ${mediaQuery.tablet} {
    width: 212px;
  }
`;

export const S = {
  Banner,
  IconContainer,
  StyledPublishedInfo,
  Title,
  TitleAndAuthorContainer,
  StyledAuthorCaption,
  StyledImage,
};
