import styled from 'styled-components';
import { Colors } from 'src/theme/colors';
import { ulMarginLeft } from 'src/theme/dimensions';
import { StyleMixins } from 'src/theme/style-mixins';
import { Typography } from 'src/theme/typography';

export const Root = styled.section`
  ${Typography.articleBody};
  margin: 0 auto;
  max-width: 712px;
  color: ${Colors.ui.bodyText};
  .gatsby-remark-code-button {
    height: 32px;
    width: 32px;
    top: -16px;
    right: 6px;
  }
  .img-caption {
    font-size: 10px;
    text-transform: lowercase;
    font-variant: small-caps;
    font-style: normal;
  }

  & ul {
    margin-left: ${ulMarginLeft};
  }
  & ol {
    margin-left: ${ulMarginLeft};
  }

  blockquote {
    border-left: solid 3px #fff;
    padding-left: 20px;
    font-style: italic;
    font-weight: 700;
  }

  img {
    display: block;
    margin: 0 auto;
  }

  h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 48px;
    color: #fff;

    .light & {
      color: ${Colors.ui.titleTextLight};
    }
  }

  a {
    ${StyleMixins.TextLink};
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  font-weight: 800;
  letter-spacing: -2px;
  margin: 16px 0 32px 0;
`;

export const BlogPostContentWrapper = styled.div`
  & img {
    border-radius: 8px;
    overflow: hidden;
  }

  .light & {
    color: ${Colors.ui.bodyTextLight};
    & a {
      color: ${Colors.ui.bodyTextLight};
    }
    & h3 {
      color: ${Colors.ui.titleTextLight};
    }
  }
`;
