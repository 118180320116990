import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

const Section = styled.section`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media ${mediaQuery.tablet} {
    gap: 80px;
  }
`;

const PresentationOpionsList = styled.ul`
  color: ${Colors.ui.teal[80]};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  padding-left: 16px;

  & > li {
    margin: 0;
  }
`;

export const S = {
  Section,
  PresentationOpionsList,
};
