import React from 'react';
import { HTMLContent } from 'src/components/content/Content';
import { BlogPostContentWrapper, Root } from './blogPostContent.styled';

interface IProps {
  content: string;
  contentComponent?: React.FC;
}

const BlogPostContent = ({ content, contentComponent }: IProps) => {
  const PageContent = contentComponent || HTMLContent;
  return (
    <Root>
      <BlogPostContentWrapper>
        <PageContent content={content} />
      </BlogPostContentWrapper>
    </Root>
  );
};

export default BlogPostContent;
