import React from 'react';
import { S } from './DescriptionSection.styled';
import OTSPanel from './components/OTSPanel/OTSPanel';

const DescriptionSection = () => {
  return (
    <section>
      <S.Description>
        Sonalake’s SwitchedOn Fibre™ makes it easy to acquire, manage and bill customers using a modular suite of FTTx
        BSS software. It allows fibre operators to automate processes across wholesale, retail and enterprise services.
        It seamlessly integrates with new and legacy systems to deliver a better customer experience.
      </S.Description>
      <OTSPanel />
    </section>
  );
};

export default DescriptionSection;
