import React from 'react';
import ContactForm from 'src/components/contactForm/ContactForm';
import { S } from './bookConsultationSection.styled';

const PureBookConsultationSection: React.FC = ({ children }) => (
  <S.Section>
    <S.Wrapper>{children}</S.Wrapper>
  </S.Section>
);

const BookConsultationSection: React.FC = () => {
  const formName = 'Book a free consultation';
  const formTitle = 'Book a free consultation';
  const formDescription =
    'Want to know more? Complete this form to talk to an expert about VisiMetrix, how it works, and what it can do for you.';

  return (
    <PureBookConsultationSection>
      <ContactForm
        isMessageField={false}
        formName={formName}
        visimetrix={true}
        title={formTitle}
        description={formDescription}
      />
    </PureBookConsultationSection>
  );
};

export default BookConsultationSection;
