import React from 'react';
import { TestimonialsSwiper } from 'src/components/testimonials-swiper/testimonials-swiper';
import { Root } from './SwitchOnFibreTestimonialsCarousel.styled';

const testimonials = [
  {
    name: 'Geoff Shakespeare',
    jobTitle: 'Director of IT, NBI',
    text: 'Sonalake’s BSS solution is pivotal in enabling our customer journeys, from address search through to customer order management, billing and assurance. Their agile and collaborative approach as our software partner has been excellent.',
    personImageUrl: '/testimonial/nbi.svg',
  },
  {
    name: 'Conal Henry',
    jobTitle: 'CEO, enet',
    text: 'The Sonalake BSS solution has helped enet to dramatically scale our business solutions and underlines our commitment to delivering additional, world-class valuable services to our customers large and small.',
    personImageUrl: '/testimonial/enet.svg',
  },
  {
    name: 'John Power',
    jobTitle: 'COO, NBI',
    text: 'Sonalake’s domain expertise and software delivery quality have been central to the success of NBI’s rapid build of our cloud-native Operating Environment, underlining our commitment to delivering high speed broadband services throughout the country.',
    personImageUrl: '/testimonial/nbi.svg',
  },
];

const SwitchOnFibreTestimonialsCarousel = () => {
  return (
    <Root>
      <TestimonialsSwiper testimonials={testimonials} slides={1} />
    </Root>
  );
};

export default SwitchOnFibreTestimonialsCarousel;
