import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

export const PageTitle = styled.h1`
  font-size: 40px;
  font-weight: 800;
  letter-spacing: -3px;
  margin-bottom: 32px;

  @media ${mediaQuery.tablet} {
    font-size: 72px;
    letter-spacing: -6px;
    max-width: 90%;
    line-height: 72px;
  }

  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;

export default PageTitle;
