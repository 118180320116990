import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React from 'react';
import BackButton from 'src/components/backButton/BackButton';
import { S } from './HeaderSection.styled';

const HeaderSection = () => {
  const { state } = useLocation() as { state: { canGoBack?: boolean } | null };

  return (
    <S.Section>
      <S.GoBackLink onClick={() => (state?.canGoBack ? navigate(-1) : navigate('/telecom-solutions'))}>
        <BackButton />
      </S.GoBackLink>
      <S.PageTitle>
        <S.StyledSwitchedOnBillingLogo />
      </S.PageTitle>
      <S.SubTitle>A powerful billing platform to manage and monetise your subscriptions.</S.SubTitle>
    </S.Section>
  );
};

export default HeaderSection;
