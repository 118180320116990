import React, { ChangeEvent, useRef } from 'react';
import UIStore from 'src/store/Store';
import { SearchIcon, SearchIconLight, Input } from './SearchInput.styled';

interface IProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
  className?: string;
}

const ListFilterInput = ({ value, onChange, className }: IProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const handleSearchInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      ref.current?.blur();
    }
  };

  const isDarkMode = UIStore.useState((s: any) => s.isDarkMode);
  return (
    <>
      {isDarkMode ? <SearchIcon /> : <SearchIconLight width="16px" height="16px" />}
      <Input
        placeholder="Search"
        name="posts-filter"
        value={value}
        onChange={onChange}
        onKeyUp={handleSearchInputKeyUp}
        ref={ref}
        {...{ className }}
      />
    </>
  );
};

export default ListFilterInput;
