import styled from 'styled-components';
import { Colors } from 'src/theme/colors';
import { ulMarginLeft } from 'src/theme/dimensions';

export const Root = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;

export const JobDescription = styled.div`
  padding-bottom: 30px;

  ul {
    margin-left: ${ulMarginLeft};
  }

  .light & {
    color: ${Colors.ui.bodyTextLight};
    & strong {
      color: ${Colors.ui.titleTextLight};
    }
  }
`;
