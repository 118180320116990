import React from 'react';
import { FlowItem, FlowList } from './flow.styled';
import AutomationSvg from './img/automated.svg';
import CatalogueSvg from './img/catalogue.svg';
import hero from './img/featureimage.png';
import IntegrationSvg from './img/integration.svg';
import ManagamentSvg from './img/managament.svg';

export const Flow = () => {
  return (
    <FlowList>
      <FlowItem.Root>
        <FlowItem.Image style={{ width: '350px' }}>
          <img src={hero} />
        </FlowItem.Image>
        <FlowItem.Body>
          <FlowItem.Title>Streamline your billing </FlowItem.Title>
          <FlowItem.Text>
            SwitchedOn Billing™ simplifies how you manage your product offerings, subscribers, billing and invoices. Its
            automated, accurate and reliable workflows reduce the need for manual input. Streamline and centralise your
            processes to support organisational scale and growth.
          </FlowItem.Text>
        </FlowItem.Body>
      </FlowItem.Root>
      <FlowItem.Root>
        <FlowItem.Image>
          <CatalogueSvg />
        </FlowItem.Image>
        <FlowItem.Body>
          <FlowItem.Title>A flexible product catalogue</FlowItem.Title>
          <FlowItem.Text>
            Product managers can easily define product and service offerings within our customisable product catalogue.
            The intuitive interface lets you specify product characteristics, pricing models, discounts, vouchers and
            product terms.
          </FlowItem.Text>
        </FlowItem.Body>
      </FlowItem.Root>
      <FlowItem.Root>
        <FlowItem.Image>
          <ManagamentSvg />
        </FlowItem.Image>
        <FlowItem.Body>
          <FlowItem.Title>Effortless subscription management </FlowItem.Title>
          <FlowItem.Text>
            Track and manage subscriptions of your customers from the moment they sign up. Set up multiple price plans,
            renewal periods and discounts. It’s easy to introduce promotions and new pricing strategies for existing
            customers to drive growth for your business.
          </FlowItem.Text>
        </FlowItem.Body>
      </FlowItem.Root>
      <FlowItem.Root>
        <FlowItem.Image>
          <AutomationSvg />
        </FlowItem.Image>
        <FlowItem.Body>
          <FlowItem.Title>Automated billing</FlowItem.Title>
          <FlowItem.Text>
            Automate billing and invoicing processes, so you spend less time on manual or administrative tasks.
            Calculate and review all recurring, one-off and usage charges before invoices are automatically sent to your
            customers.
          </FlowItem.Text>
        </FlowItem.Body>
      </FlowItem.Root>
      <FlowItem.Root>
        <FlowItem.Image>
          <IntegrationSvg />
        </FlowItem.Image>
        <FlowItem.Body>
          <FlowItem.Title>Easy integration and data capture</FlowItem.Title>
          <FlowItem.Text>
            The platform can facilitate integration with third-party applications like a CRM, order management or any
            payment gateways. This platform is designed to be a critical part of your wider business ecosystem, helping
            to ensure that financial operations are efficient, accurate and scalable.
            <br />
            <br />
            It captures product, subscriber and revenue data so you can build KPIs and reports for your key
            stakeholders. This valuable information can help you identify trends or opportunities for further growth.
          </FlowItem.Text>
        </FlowItem.Body>
      </FlowItem.Root>
    </FlowList>
  );
};
