import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { IParsedBlogPostListItemData } from 'src/types/blog.types';

const Wrapper = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding-left: 30px;
  @media ${mediaQuery.phone} {
    padding: 10px 0;
  }
`;
const Container = styled.div`
  display: block;
  font-size: 12px;
  height: 1em;
  .light & {
    color: ${Colors.ui.greyDark};
  }
`;

export const BlogListCounter = ({ posts }: { posts: IParsedBlogPostListItemData[] }) => {
  return (
    <Wrapper>
      <Container>
        {posts && posts.length > 0 ? `${posts.length} ${posts.length === 1 ? 'Article' : 'Articles'}` : 'No Articles'}
      </Container>
    </Wrapper>
  );
};
