import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { Typography } from 'src/theme/typography';
import BoxSubTitle from '../boxSubTitle/BoxSubTitle';

const StyledBoxSubTitle = styled(BoxSubTitle)`
  color: ${Colors.red[40]};
  .light & {
    color: ${Colors.red[40]};
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 64px;

  @media ${mediaQuery.smallScreen} {
    grid-template-columns: 1fr 1fr;
  }
`;

const DescriptionCol = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const Description = styled.p`
  ${Typography.body[16]};
  text-align: left;
  color: ${Colors.red[80]};
  margin-bottom: 0;
`;

const LogoCol = styled.div`
  margin: auto;
`;

export const S = {
  StyledBoxSubTitle,
  Container,
  DescriptionCol,
  Description,
  LogoCol,
};
