import { graphql } from 'gatsby';
import { IFluidObject } from 'gatsby-background-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'src/components/layout/Layout';
import useSiteMetadata from 'src/components/layout/hooks/useSiteMetadata';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import { ThemeName } from 'src/theme/ThemeStyles';
import { ICaseStudyListItemData, IParsedCaseStudyListItemData } from 'src/types/caseStudies.types';
import { S } from './SwitchedOnFibrePage.styled';
import { SwitchOnBillingTestimonialsCarousel } from './sections/SwitchOnBillingTestimonials/SwitchOnBillingTestimonialsCarousel';
import CaseStudiesSection from './sections/caseStudiesSection/CaseStudiesSection';
import { Flow } from './sections/flow/flow';
import HeaderSection from './sections/headerSection/HeaderSection';
import RequestDemoSection from './sections/requestDemoSection/RequestDemoSection';

interface IProps {
  data: {
    file: { childImageSharp: { fluid: IFluidObject } };
    caseStudiesData: {
      caseStudiesList: ICaseStudyListItemData[];
    };
  };
}

const flattenCaseStudiesData = (caseStudiesData: ICaseStudyListItemData[]): IParsedCaseStudyListItemData[] => {
  return caseStudiesData.map(({ caseItem }: ICaseStudyListItemData): IParsedCaseStudyListItemData => {
    return {
      id: caseItem.id,
      slug: caseItem.fields.slug,
      ...caseItem.frontmatter,
    };
  });
};

export const SwitchedOnBillingPage: React.FC<IProps> = ({ data }) => {
  const { siteUrl } = useSiteMetadata();
  const caseStudies = flattenCaseStudiesData(data.caseStudiesData.caseStudiesList);

  return (
    <Layout theme={ThemeName.switchedOnBilling}>
      <Helmet>
        <title>SwitchedOn Billing™ - Automation of lead-to-cash fibre journey</title>
        <meta
          data-react-helmet="true"
          property="og:title"
          content="SwitchedOn Billing™ - A powerful platform to manage and monetise your subscriptions"
        />
        <meta
          data-react-helmet="true"
          property="og:description"
          content="SwitchedOn Billing™ uses automated workflows to help you manage your product offerings, subscribers, billing and invoices."
        />
        <meta
          name="description"
          content="SwitchedOn Billing™ uses automated workflows to help you manage your product offerings, subscribers, billing and invoices."
        />
        <meta property="og:image" content={`${siteUrl}/banners/switchedon-billing.jpeg`} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>
      <S.StyledBackgroundImage>
        <PageContentWide>
          <S.PageSectionsWrapper>
            <HeaderSection />
            <Flow />
            <CaseStudiesSection caseStudies={caseStudies} />
            <SwitchOnBillingTestimonialsCarousel />
            <RequestDemoSection />
          </S.PageSectionsWrapper>
        </PageContentWide>
      </S.StyledBackgroundImage>
    </Layout>
  );
};

export default SwitchedOnBillingPage;

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "switched-on-fibre-bg.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    caseStudiesData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "caseStudy" }, company: { in: ["NBI", "Enet"] } } }
    ) {
      caseStudiesList: edges {
        caseItem: node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            category
            company
            coverImageAlt
            companyLogoImage {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED)
              }
              extension
              publicURL
            }
            coverImage {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
