import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisimetrixInfoItem from 'src/components/visimetrix/visimetrixInfoItem/VisimetrixInfoItem';
import { S } from './InfoSection.styled';

const InfoSection = () => (
  <S.Section>
    <VisimetrixInfoItem
      title="SLA management"
      descriptions={[
        'VisiMetrix can capture and display your metrics and KPIs with additional context, such as service-level agreement (SLA) data. Users can see this information on service monitoring dashboards that provide customer-centric visualisations.',
        'It helps you avoid any potential SLA breaches by flagging any service-impacting perfomance issues, before they affect your customers. Data is captured in real time so you can quickly take corrective action to address a problem.',
        'This lets you avoid the costly penalties or reputational damage that can come with SLA breaches.',
      ]}
      ItemImage={<StaticImage src="img/sla-management.png" alt="VisiMetrix - screenshot of node details" />}
      tabletOrientation="left"
    />
    <VisimetrixInfoItem
      title="Multi customer & service views"
      descriptions={[
        'The feature-rich portal offers the flexibility to collect and link data from multiple vendors.',
        'It allows you to choose from a variety of service views, depending on your needs. You can access information from a customer, service or business oriented perspective.',
        'Account managers can quickly identify customer capacity or service degradation issues. Having immediate access to the relevant data also helps you to upgrade or upsell customer services.',
      ]}
      ItemImage={
        <StaticImage
          src="img/multi-customer-and-service-views.png"
          alt="VisiMetrix - screenshot of multi-customer view"
        />
      }
      tabletOrientation="right"
    />
    <VisimetrixInfoItem
      title="Powering your customer portal"
      descriptions={[
        'VisiMetrix is a powerful, multi-tenancy secure platform which allows your customers to see their individual network KPIs in real time.',

        'It lets you to control the customer’s experience and level of access. The self-service feature combines flexibility with ease of use, empowering users with its accessibility.',

        'By removing unnecessary complexity from the interface, it also encourages user adoption across the business.',
      ]}
      ItemImage={
        <StaticImage src="img/powering-your-customer-portal.png" alt="VisiMetrix - screenshot of real time alerts" />
      }
      tabletOrientation="left"
    />
  </S.Section>
);

export default InfoSection;
