import styled from 'styled-components';
import { Colors } from 'src/theme/colors';

export const BoxSubTitle = styled.h2`
  font-size: 40px;
  letter-spacing: -2px;
  font-weight: 800;
  margin-bottom: 32px;
  text-align: left;
  color: ${Colors.white};

  .light & {
    color: ${Colors.ui.greyDark};
  }
`;

export default BoxSubTitle;
