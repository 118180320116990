import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';

const Section = styled.section`
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media ${mediaQuery.tablet} {
    gap: 80px;
  }
`;

export const S = {
  Section,
};
