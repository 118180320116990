import React from 'react';
import PivotsContactSection from '../pivotsContactSection/PivotsContactSection';
import { S } from './MainSections.styled';
import HowDoesItHelpSVG from './img/how-does-it-help.svg';

const MainSections = () => (
  <>
    <S.InfoSection>
      <S.SectionParagraph>
        In 2021 Ofcom introduced One Touch Switching (OTS) rules to ease the process by which customers can change their
        fixed voice and broadband service provider. The OTS process mandates that service providers play their part in
        automating the end-to-end switching process by integrating with the{' '}
        <a href="https://totsco.org.uk/" target="_blank" rel="noreferrer">
          TOTSCo Hub
        </a>{' '}
        messaging platform. This two-way integration, where providers send requests to the Hub and service requests from
        it, comprises a complex set of APIs and security protocols with strict performance guarantees.
      </S.SectionParagraph>

      <S.HighlightedSection>
        <S.SectionParagraph>
          Update 30 September &apos;24
          <br />
          All pivOTS customers are now live and switching on the TOTSCo hub! Check out some testimonial comments from{' '}
          <a href="https://www.linkedin.com/posts/activity-7240327064849940480-GSvN">Lila Connect</a> and{' '}
          <a href="https://www.linkedin.com/posts/sonalake_connectedbritain-ots-onetouchswitching-activity-7241403747631796225-MVhY">
            G.Network
          </a>{' '}
          on Linkedin, including: &quot;It’s a great platform within the complex ecosystem of OTS&quot;.
        </S.SectionParagraph>

        <S.SectionParagraph>
          Update 12 September &apos;24
          <br />
          On OTS GO-LIVE date, three pivOTS customers have been live and switching on the TOTSCo hub for some time now -
          Zen Internet, G.Network and Fibrenest, with several others just days away from being ready!
        </S.SectionParagraph>

        <S.SectionParagraph>
          Update 11 September &apos;24
          <br />
          <i>
            <a href="https://www.linkedin.com/feed/update/urn:li:activity:7241403747631796225">
              pivOTS wins Enterprise solution of the year at Connected Britain awards
            </a>
          </i>
        </S.SectionParagraph>

        <S.SectionParagraph>
          Update 9 August &apos;24
          <br />
          <i>
            We were delighted to participate in{' '}
            <a href="https://www.linkedin.com/posts/pvsharpe_onetouchswitch-broadband-telecoms-activity-7228893408432472064-Z0xX">
              Zen Internet&apos;s partner webinar on OTS
            </a>
          </i>
        </S.SectionParagraph>

        <S.SectionParagraph>
          Update June &apos;24:
          <br />
          <i>
            A pivOTS™ customer is in live trials with the &apos;big 4&apos; telcos having completed all tests with
            TOTSCo.
          </i>
        </S.SectionParagraph>

        <S.SectionParagraph>
          Update February &apos;24
          <br />
          <i>
            Sonalake secured &apos;TOTSCo Ready&apos; certification and completed end-to-end integration testing with a
            large-scale telecom provider (KCOM).
          </i>
        </S.SectionParagraph>
      </S.HighlightedSection>
      <S.InfoImgContainer>
        <HowDoesItHelpSVG height="auto" />
      </S.InfoImgContainer>
      <S.SectionParagraph>
        Integration requires that communication providers develop software to both send requests to the Hub and receive
        service requests from it, as well as integrate with their CRM, service inventory, customer billing, and other
        systems. To compound matters TOTSCo integration imposes strict performance guarantees and comprises a complex
        set of APIs and security protocols that will continue to evolve beyond 2024.
      </S.SectionParagraph>
      <S.SectionParagraph>
        This represents a significant technology challenge for providers.
        <br />
        See our blog post:{' '}
        <S.BlogAnchor href="https://sonalake.com/latest/ots-build-in-house-or-buy/" target="_blank" rel="noreferrer">
          Options to integrate with the TOTSCo Hub for OTS compliance
        </S.BlogAnchor>
      </S.SectionParagraph>
    </S.InfoSection>

    <section>
      <S.SectionHeader>How does pivOTS help?</S.SectionHeader>
      <S.SectionParagraph>
        pivOTS minimises the effort required for providers to meet their OTS regulatory obligations. It simplifies Hub
        integration in both gaining and losing scenarios handling messaging, data management, security, availability,
        performance and audit requirements.
      </S.SectionParagraph>
      <br />
      <S.SectionParagraph>
        pivOTS is deployed rapidly within a Retail Provider’s own IT environment, providing a high degree of protection
        from accidental or unauthorized attempts to access customer data. It abstracts the OTS challenge away from core
        systems including CRMs, while keeping them up to date with customer switch progress. pivOTS thus reinforces
        CRM’s central role as master of customer communications.
      </S.SectionParagraph>
      <br />
      <S.SectionParagraph>
        pivOTS is available at a price point that is significantly below the cost for a Retail Provider to develop and
        maintain a similar solution internally.
      </S.SectionParagraph>
      <br />
      <div>
        <S.TotscoLogo title="TOTSCO - ready" />
        <S.InlineSectionParagraph>
          In February 2024, pivOTS™ went through end-to-end integration test with a large provider, KCOM. This means
          that a service provider using pivOTS™ does not have to perform end-to-end integration test with another
          partner. pivOTS™ also supports a test mode, allowing clients to focus solely on BSS-pivOTS™ integration,
          independent of the TOTSCo Hub, with pivOTS™ acting in both losing and gaining roles.
        </S.InlineSectionParagraph>
      </div>
    </section>

    <section>
      <S.SectionHeader>Features</S.SectionHeader>
      <S.FeaturesListContainer>
        <S.SectionList>
          <li key="0">Fully compliant with TOTSCo OTS specifications</li>
          <li key="1">Customer data remains in-house and secure</li>
          <li key="2">Message retry</li>
          <li key="3">Multi-brand support</li>
          <li key="4">Simple service inventory ingestion APIs</li>
        </S.SectionList>

        <S.SectionList>
          <li key="0">Cloud deployment (AWS, Azure, GCP)</li>
          <li key="1">High-availability configuration</li>
          <li key="2">Horizontally scalable</li>
          <li key="3">Optional Front End module for manual actions</li>
        </S.SectionList>
      </S.FeaturesListContainer>
    </section>

    <section>
      <S.SectionHeader>Benefits</S.SectionHeader>
      <S.SectionList>
        <li key="0">TOTSCo compliance at lower cost - keep your tech team focused on delivering new business value</li>
        <li key="1">Insulate existing business support systems from additional load and external traffic</li>
        <li key="2">Remove need for 3rd party access to systems containing sensitive customer data</li>
        <li key="3">Reduced API surface for improved security</li>
        <li key="4">Comprehensive observability metrics and audit trail</li>
        <li key="5">Release roadmap in line with evolving TOTSCo specifications, including GPLB</li>
        <li key="6">Proven solution - pivOTS™ has already passed end-to-end testing and is TOTSCo Ready!</li>
        <li key="7">
          pivOTS™ is delivered with access to an online development-centre providing extensive resources for clients to
          integrate the product into their environment. This is backed by an expert Sonalake support team.
        </li>
      </S.SectionList>
    </section>

    <PivotsContactSection />
  </>
);

export default MainSections;
