import img1 from './Careers_img1s.webp';
import img2 from './Careers_img2.webp';
import img3 from './Careers_img3.webp';
import img4 from './Careers_img4s.webp';
import img5 from './Careers_img5.webp';
import img6 from './Careers_img6.webp';
import img7 from './Careers_img7s.webp';
import img8 from './Careers_img8.webp';
import img9 from './Careers_img9.webp';
import img10 from './Careers_img10s.webp';
import img11 from './Careers_img11.webp';
import img12 from './Careers_img12.webp';
import img13 from './Careers_img13s.webp';
import img14 from './Careers_img14.webp';
import img15 from './Careers_img15.webp';

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15];
export default images;
