import React from 'react';
import BoxCarousel, { carouselPresetBlogs } from 'src/components/boxCarousel/BoxCarousel';
import { getBlogIcon } from 'src/services/blog/blogIcon';
import { IParsedBlogPostListItemData } from 'src/types/blog.types';
import {
  Root,
  Row,
  Column,
  Title,
  Label,
  DimmedLabel,
  ButtonWrapper,
  FeaturedBlogImage,
  Button,
} from './BlogFeaturedPosts.styled';

interface IProps {
  posts: IParsedBlogPostListItemData[];
}

const BlogFeaturedPosts = ({ posts }: IProps) => {
  return (
    <Root>
      <BoxCarousel config={carouselPresetBlogs}>
        {posts.map((post: IParsedBlogPostListItemData, index) => {
          const { Icon, background } = getBlogIcon(post.iconType);
          return (
            <>
              <Row key={index} bg={background || 'transparent'}>
                <Column>
                  <Label>Editor's pick</Label>
                  <DimmedLabel>Posted on {post.date}</DimmedLabel>
                  <Title>{post.title}</Title>
                  <DimmedLabel>Reading time: {post.readingTime} minutes</DimmedLabel>
                  <ButtonWrapper>
                    <Button title="Open blog post" to={post.slug}>
                      Learn more
                    </Button>
                  </ButtonWrapper>
                </Column>
                <Column>
                  <FeaturedBlogImage>
                    <Icon />
                  </FeaturedBlogImage>
                </Column>
              </Row>
            </>
          );
        })}
      </BoxCarousel>
    </Root>
  );
};

export default BlogFeaturedPosts;
