import Link from 'gatsby-link';
import styled, { keyframes } from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import colors, { Colors } from 'src/theme/colors';

export const LinkButtonWrapper = styled.div`
  margin-top: 30px;

  @media ${mediaQuery.tablet} {
    margin-top: 64px;
  }
`;

export const Button = styled(Link)`
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;
  background: white;
  color: black;
  margin-top: 20px;
  position: relative;
  z-index: 1;

  .visimetrix &,
  .switchedonfibre & {
    background-color: #263c59;
  }

  .light & {
    background-color: ${Colors.ui.titleTextLight};
    color: white;
  }

  @media ${mediaQuery.tablet} {
    margin-top: 40px;
  }
`;

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0;
    }
  `;
}

export const TypewriterCursor = styled.span`
  display: inline-block;
  color: ${colors.textSecondary};
  animation: ${blinkingEffect} 1s linear infinite;
`;
