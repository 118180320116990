const convertToBase64 = (file: File): Promise<string | void> => {
  return new Promise(resolve => {
    const reader: FileReader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      if (reader && reader.result) {
        const base64String = (reader.result as string).replace('data:', '').replace(/^.+,/, '');

        resolve(base64String);
      }
    };
  });
};

export default convertToBase64;
