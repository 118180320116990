import styled from 'styled-components';
import { Colors } from 'src/theme/colors';

export const Container = styled.div`
  background-color: #1b2327;
  border-radius: 16px;
  padding: 30px 30px 0px 30px;

  .light & {
    background-color: ${Colors.ui.componentLight};
  }
`;
