import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { Colors } from 'src/theme/colors';
import { pagePaddingMobile } from 'src/theme/dimensions';

const Section = styled.section`
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
  color: ${Colors.ui.teal[50]};
  text-align: center;
  font-weight: bold;
  font-size: 3vw;
  padding: ${pagePaddingMobile};
`;

const Title = styled.h2`
  color: ${Colors.ui.teal[50]};
  text-align: center;
  font-weight: bold;
  font-size: 3vw;
`;

const NocSupportSection = () => (
  <Section>
    <Title>The four pillars of our AIOps enabled NOC</Title>
    <StaticImage
      src="img/FourPillarsNOC.png"
      alt="Visimetrix collects data from across the network, which is enriched with other data sources to provide additional context and intelligence. This information is qualified, processed and analysed by our analytics framework and displayed in intuitive dashboards that let you monitor and report upon your network performance."
    />
  </Section>
);

export default NocSupportSection;
