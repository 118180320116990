import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

const PageSubTitle = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  @media ${mediaQuery.tablet} {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -2px;
  }

  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;

export default PageSubTitle;
