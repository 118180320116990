import React from 'react';
import BlogRoll from 'src/components/blogRoll/BlogRoll';
import { IParsedBlogPostListItemData } from 'src/types/blog.types';
import { Root, ContentWrapper, TitleWrapper, OlderPostsLink, SectionTitle } from './LatestPostsBox.styled';
import ArrowRightSVG from './img/arrow-right.svg';

interface IProps {
  posts: IParsedBlogPostListItemData[];
  theme?: 'dark' | 'visimetrix';
}

const LatestPostsBox = ({ posts, theme }: IProps) => {
  return (
    <Root>
      <ContentWrapper>
        <TitleWrapper>
          <SectionTitle>Latest blog posts</SectionTitle>
          <OlderPostsLink to={`/latest${theme === 'visimetrix' ? '?category=visimetrix' : ''}`} className="desktopLink">
            More posts
            <ArrowRightSVG />
          </OlderPostsLink>
        </TitleWrapper>
        <BlogRoll posts={posts} />
        <OlderPostsLink to="/latest" className="mobileLink">
          More posts
          <ArrowRightSVG />
        </OlderPostsLink>
      </ContentWrapper>
    </Root>
  );
};

export default LatestPostsBox;
