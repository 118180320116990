import { graphql, navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import BackButton from 'src/components/backButton/BackButton';
import { HTMLContent } from 'src/components/content/Content';
import Layout from 'src/components/layout/Layout';
import PageContentNarrow from 'src/components/pageContentNarrow/PageContentNarrow';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import { IImageSharp } from 'src/types/gatsby.types';
import { Title, ContentWrapper, GoBackLink, CoverImageWrapper } from './CaseStudy.styled';

interface IProps {
  markdownRemark: {
    frontmatter: {
      pageTitle: string;
      pageDescription: string;
      title: string;
      coverImageAlt: string;
      coverImage?: {
        childImageSharp: IImageSharp;
      };
    };
    html: string;
  };
}

const CaseStudy = ({ data }: { data: IProps }) => {
  const {
    markdownRemark: { frontmatter, html },
  } = data;

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.pageTitle}</title>
        <meta property="og:title" content={frontmatter.pageTitle} />
        <meta name="description" content={frontmatter.pageDescription} />
        <meta property="og:description" content={frontmatter.pageDescription} />
      </Helmet>
      <>
        {frontmatter?.coverImage && (
          <PageContentWide>
            <CoverImageWrapper>
              <GatsbyImage
                image={frontmatter.coverImage.childImageSharp.gatsbyImageData}
                alt={frontmatter.coverImageAlt}
              />
            </CoverImageWrapper>
          </PageContentWide>
        )}
      </>
      <PageContentNarrow>
        <GoBackLink onClick={() => navigate(-1)}>
          <BackButton />
        </GoBackLink>
        <Title>{frontmatter?.title}</Title>
        <ContentWrapper>
          <HTMLContent content={html} />
        </ContentWrapper>
      </PageContentNarrow>
    </Layout>
  );
};

export default CaseStudy;

export const pageQuery = graphql`
  query CaseStudyByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        pageTitle
        pageDescription
        title
        coverImageAlt
        coverImage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
