import React from 'react';
import AgileTeamsIconSVG from 'src/assets/icons/icon_agileteams.svg';
import DataAnalyticsIconSVG from 'src/assets/icons/icon_data_analytics.svg';
import AgileTeamsIconBlackSVG from 'src/assets/icons/icon_ourServices_agile.svg';
import DataAnalyticsIconBlackSVG from 'src/assets/icons/icon_ourServices_dataAnalytics.svg';
import PlatformEngineeringIconBlackSVG from 'src/assets/icons/icon_ourServices_platform.svg';
import ProductDesDevIconBlackSVG from 'src/assets/icons/icon_ourServices_productDesign.svg';
import PlatformEngineeringIconSVG from 'src/assets/icons/icon_platformengineering.svg';
import ProductDesDevIconSVG from 'src/assets/icons/icon_productdesdev.svg';
import BoxTitle from 'src/components/boxTitle/BoxTitle';
import { Service } from './components/service';
import { MiniaturesContainer, ServiceMiniatureContainer, ServiceMiniature } from './ourServicesBox.styles';

const OurServicesBox = () => {
  return (
    <>
      <MiniaturesContainer id="services">
        <BoxTitle>Our services</BoxTitle>
        <ServiceMiniatureContainer>
          <ServiceMiniature type="ProductDesign" href="#ProductDesign">
            <ProductDesDevIconBlackSVG className="fill-white light:fill-black" /> Product design <br />& development
          </ServiceMiniature>
          <ServiceMiniature type="TeamAugmentation" href="#TeamAugmentation">
            <AgileTeamsIconBlackSVG className="fill-white light:fill-black" /> Agile teams
          </ServiceMiniature>
          <ServiceMiniature type="ProductDevelopment" href="#ProductDevelopment">
            <PlatformEngineeringIconBlackSVG className="fill-white light:fill-black" />
            Platform engineering
          </ServiceMiniature>
          <ServiceMiniature type="DataAnalytics" href="#DataAnalytics">
            <DataAnalyticsIconBlackSVG className="fill-white light:fill-black" /> Data <br />& analytics
          </ServiceMiniature>
        </ServiceMiniatureContainer>
      </MiniaturesContainer>
      <Service
        tag="ProductDesign"
        icon={<PlatformEngineeringIconSVG className="fill-blue" />}
        title="Product design & development"
        columns={[
          [
            `Great digital products deliver engaging customer experiences, so we combine user-led design with
        software engineering expertise as we collaborate with our clients, from concept to delivery and support.`,
            `Design starts with understanding and validating the problem. Our Design team will work with you to
        realise your vision and solve your customers’ problems using product discovery, research, validation and
        UI design.`,
            `Our technical designs are based on modern enterprise web application architectures. They are extensible
        for roadmap evolution, with performance, availability, security and scalability needs built in.`,
          ],
          [
            `For development, we employ contemporary cloud-native technologies, open source frameworks and agile
        engineering practices to deliver quality fast, delivered with automated test and CI/CD infrastructure on
        leading hosting environments.`,
            `Our approach is collaborative and transparent. We’ll work closely with you on each iteration to deliver
        the best results, and we’ll help set up and transfer to your in-house development environment at any
        time.`,
          ],
        ]}
      />
      <Service
        tag="TeamAugmentation"
        icon={<AgileTeamsIconSVG className="fill-teal" />}
        title="Agile teams"
        columns={[
          [
            `Technology innovation companies work with us to flexibly enhance their software engineering organisations.`,
            `Business drivers include acceleration of product roadmaps, introducing new technologies, managing skill shortages, migrating to cloud or ramping up agile adoption.`,
            `Our engineers are tuned to seamlessly integrate within your existing software organisation, while bringing the best of Sonalake experience.    `,
          ],
          [
            `We are located across the European continent, with clusters of on-site, hybrid and remote employees centred around our office bases.`,
            `We add additional value through our peer-to-peer consulting - combining the thinking of your experts and our experts always results in a fresh clarity to accelerate your business.`,
          ],
        ]}
      />
      <Service
        tag="ProductDevelopment"
        icon={<ProductDesDevIconSVG className="fill-purple" />}
        title="Platform engineering"
        columns={[
          [
            `Software supply chain, DevOps, CI/CD, observability - we've got it covered.`,
            `More than ever, organisations need the agility to adapt to evolving user requirements and performance demands. `,
            `Improving developer experience and reducing the impedance mismatch between development and production environments is key to streamlining the path to production.`,
          ],
          [
            `Using modern processes, toolchains, and technologies, our platform engineering teams apply software engineering principles to design and provision cloud infrastructure and test and deployment pipelines that deliver dependable, rapid value to production.`,
          ],
        ]}
      />
      <Service
        tag="DataAnalytics"
        icon={<DataAnalyticsIconSVG className="fill-red" />}
        title="Data & analytics"
        columns={[
          [
            `A world of super interesting new data (from people and machines) is being matched by technology advancements in data engineering, data science and AI. `,
            `We build applications to monetise and harness value from this new reality. `,
            `It starts with framing the problem and strategy, and flows through to data collection and processing, exploration and analysis, communication and visualisation.`,
          ],
          [
            `We have deep experience in building highly scalable data pipelines and open source platforms for efficient gathering, storage and streaming to enable analytics applications. `,
            `We develop custom applications that cannot be fulfilled by available COTS products, including embedding analytics modules within vendors’ software products. We employ data science for the analysis and development of advanced analytics features based upon contemporary machine learning capabilities.`,
          ],
        ]}
      />
    </>
  );
};

export default OurServicesBox;
