import React, { useCallback, useEffect, useRef } from 'react';
import { S } from './galerryBox.styles';
import images from './img';

const useMovingGallery = (speed = 0.25) => {
  const scroll = useRef({ position: 0, direction: 1, speed, isHovered: false });
  const pos = useRef({ x: 0, left: 0 });
  const ref = useRef<HTMLDivElement>(null);
  const handleMouseIn = () => {
    scroll.current.isHovered = true;
  };
  const handleMouseOut = () => {
    scroll.current.isHovered = false;
  };

  const mouseMoveHandler = useCallback((e: MouseEvent) => {
    if (!ref.current) return;
    // How far the mouse has been moved
    const dx = e.clientX - pos.current.x;

    // Scroll the element
    ref.current.scrollLeft = pos.current.left - dx;
    scroll.current.position = ref.current.scrollLeft;
  }, []);

  const mouseUpHandler = useCallback(() => {
    if (!ref.current) return;
    // scroll.current.isHovered = false;

    ref.current.removeEventListener('mousemove', mouseMoveHandler);
    ref.current.removeEventListener('mouseup', mouseUpHandler);

    ref.current.style.cursor = 'grab';
    ref.current.style.removeProperty('user-select');
  }, [mouseMoveHandler]);

  const mouseDownHandler = useCallback(
    (e: MouseEvent) => {
      if (!ref.current) return;
      scroll.current.isHovered = true;

      pos.current = {
        x: e.clientX,
        left: ref.current.scrollLeft,
      };
      ref.current.style.cursor = 'grabbing';
      ref.current.style.userSelect = 'none';
      ref.current.addEventListener('mousemove', mouseMoveHandler);
      ref.current.addEventListener('mouseup', mouseUpHandler);
    },
    [mouseMoveHandler, mouseUpHandler],
  );

  // const autoMove = () => {
  //   if (!ref.current) return;
  //   if (!scroll.current.isHovered) {
  //     const maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
  //     if (scroll.current.position < 0) {
  //       scroll.current.direction = 1;
  //     } else if (maxScrollLeft < scroll.current.position) {
  //       scroll.current.direction = -1;
  //     }
  //     scroll.current.position += scroll.current.direction * scroll.current.speed;
  //     ref.current.scroll({
  //       left: scroll.current.position,
  //     });
  //   } else {
  //     scroll.current.position = ref.current.scrollLeft;
  //   }
  // };

  const touchMoveHandler = useCallback((e: TouchEvent) => {
    if (!ref.current) return;
    // How far the mouse has been moved
    const dx = e.changedTouches[0].clientX - pos.current.x;

    // Scroll the element
    ref.current.scrollLeft = pos.current.left - dx;
    scroll.current.position = ref.current.scrollLeft;
  }, []);

  const touchEndHandler = useCallback(() => {
    if (!ref.current) return;
    scroll.current.isHovered = false;

    ref.current.removeEventListener('touchmove', touchMoveHandler);
    ref.current.removeEventListener('touchend', touchEndHandler);
  }, [touchMoveHandler]);

  const touchStartHandler = useCallback(
    (e: TouchEvent) => {
      if (!ref.current) return;
      scroll.current.isHovered = true;

      pos.current = {
        x: e.changedTouches[0].clientX,
        left: ref.current.scrollLeft,
      };
      ref.current.addEventListener('touchmove', touchMoveHandler);
      ref.current.addEventListener('touchend', touchEndHandler);
    },
    [touchEndHandler, touchMoveHandler],
  );

  useEffect(() => {
    const el = ref.current;
    el?.addEventListener('mousedown', mouseDownHandler);
    el?.addEventListener('touchstart', touchStartHandler);

    el?.addEventListener('mouseover', handleMouseIn);
    el?.addEventListener('mouseout', handleMouseOut);

    // const interval = setInterval(autoMove);

    return () => {
      el?.removeEventListener('mouseover', handleMouseIn);
      el?.removeEventListener('mousedown', mouseDownHandler);
      el?.removeEventListener('mouseout', handleMouseOut);
      el?.removeEventListener('touchstart', touchStartHandler);
      // clearInterval(interval);
    };
  }, [mouseDownHandler, touchStartHandler]);

  return ref;
};

const GalleryBox = () => {
  const ref = useMovingGallery();

  return (
    <S.Container>
      <S.Title>We make business and technology more human. By being human.</S.Title>
      <S.GalleryContainer ref={ref}>
        <S.Gallery>
          {images.map((img, i) => (
            <S.GalleryItem key={i} src={img} alt={'gallery-image-' + i} />
          ))}
        </S.Gallery>
      </S.GalleryContainer>
    </S.Container>
  );
};

export default GalleryBox;
