import React from 'react';
import styled from 'styled-components';
import SortByNewest from 'src/assets/icons/filter-variant-newest.svg';
import SortByOlder from 'src/assets/icons/filter-variant-older.svg';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

const SelectButton = styled.button`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-size: 12px;
  position: relative;
  background: none;
  text-transform: uppercase;
  border: none;
  color: var(--textPrimary);
  cursor: pointer;
  .light & {
    color: ${Colors.light.text.body};
    svg {
      stroke: ${Colors.light.text.body};
    }
  }
  svg {
    position: relative;
    padding-left: 3px;
    top: 1px;
    stroke: ${Colors.dark.text.body};
  }
  @media ${mediaQuery.phone} {
    padding: 10px 0;
  }
`;
const Container = styled.span`
  display: block;
  height: 1em;
`;

export enum OrderOptions {
  newest = 'newest',
  oldest = 'oldest',
}

const ORDER_OPTIONS_TEXT: Record<OrderOptions, string> = {
  newest: 'newest',
  oldest: 'oldest',
};

export const BlogListOrderSelect = ({
  orderBy,
  setOrderBy,
}: {
  orderBy: OrderOptions;
  setOrderBy: (orderOption: OrderOptions) => void;
}) => {
  return (
    <SelectButton
      onClick={() => {
        const toggleKeys = Object.keys(ORDER_OPTIONS_TEXT) as OrderOptions[];
        const index = toggleKeys.indexOf(orderBy) + 1;
        setOrderBy(toggleKeys[index >= toggleKeys.length ? 0 : index]);
      }}
      role="order-toggle">
      <Container>
        Sort by: <b>{ORDER_OPTIONS_TEXT[orderBy]}</b>
      </Container>
      {
        {
          newest: <SortByNewest />,
          oldest: <SortByOlder />,
        }[orderBy]
      }
    </SelectButton>
  );
};
