import React, { ReactNode } from 'react';
import { CompanyValuesDescriptionsWrapper, MainTitle } from './CompanyValuesBox.styled';

interface IProps {
  title?: ReactNode;
}

const CompanyValuesBox: React.FC<IProps> = ({ title = 'Our Values', children }) => {
  return (
    <div>
      <MainTitle>{title}</MainTitle>
      <CompanyValuesDescriptionsWrapper>{children}</CompanyValuesDescriptionsWrapper>
    </div>
  );
};

export default CompanyValuesBox;
