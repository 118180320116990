import React from 'react';
import {
  Root,
  Row,
  Column,
  ContentWrapper,
  TitleWrapper,
  TextWrapper,
  CartDescription,
  CartTitle,
  MainTitle,
} from './WhatCanWeOfferBox.styled';
import InnovationFocus from './img/innovation-focus.svg';
import OpenWorkplace from './img/open-workplace.svg';
import RemoteFirst from './img/remote-first.svg';

const WhatCanWeOfferBox = () => {
  return (
    <Root>
      <Row>
        <Column>
          <ContentWrapper>
            <TitleWrapper>
              <MainTitle>What can we offer?</MainTitle>
            </TitleWrapper>
            <TextWrapper>
              <Row>
                <div className="col">
                  <OpenWorkplace />
                  <CartTitle>
                    An open, inclusive <br /> place to work
                  </CartTitle>
                  <CartDescription>
                    We’ve a flat organisational structure with an emphasis on open, honest communication. There are no
                    siloes, no hierarchies and no barriers. We embrace diversity. We want to create an inclusive team
                    environment that’s enriched by our different perspectives and backgrounds.
                  </CartDescription>
                </div>
                <div className="col">
                  <RemoteFirst />
                  <CartTitle>
                    A remote first <br /> approach
                  </CartTitle>
                  <CartDescription>
                    It’s what you do that matters, not where you do it. You can work from our offices in Dublin, Poznan
                    or Bratislava, or work on a fully remote or hybrid basis. We believe in a healthy work-life balance
                    and organise ourselves to support you in this.
                  </CartDescription>
                </div>
                <div className="col">
                  <InnovationFocus />
                  <CartTitle>
                    A focus on <br /> innovation
                  </CartTitle>
                  <CartDescription>
                    We run projects to evaluate new frameworks, create tools for internal use, contribute to the open
                    source community and research new ways to use existing products in new problem domains. We also use
                    the likes of Kotlin, Rust and GraphQL and constantly push existing boundaries.
                  </CartDescription>
                </div>
              </Row>
            </TextWrapper>
          </ContentWrapper>
        </Column>
      </Row>
    </Root>
  );
};

export default WhatCanWeOfferBox;
