import React from 'react';
import { S } from './FlowItem.styled';

interface IProps {
  index: number;
  title: string;
  subTitle: string;
  description: string;
  ItemImage: React.ReactElement;
}

const FlowItem = ({ index, title, subTitle, description, ItemImage }: IProps) => {
  const orientation = index % 2 === 0 ? 'right' : 'left';

  return (
    <S.Item $orientation={orientation}>
      <S.ItemTextContainer>
        <S.Index>{index}</S.Index>
        <div>
          <S.Title>{title}</S.Title>
          <S.SubTitle>{subTitle}</S.SubTitle>
          <S.Description>{description}</S.Description>
        </div>
      </S.ItemTextContainer>
      <S.ItemImageContainer $orientation={orientation}>{ItemImage}</S.ItemImageContainer>
    </S.Item>
  );
};

export default FlowItem;
