import { graphql, useStaticQuery } from 'gatsby';
import { IFluidObject } from 'gatsby-background-image';
import React from 'react';
import Layout from 'src/components/layout/Layout';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import { ThemeName } from 'src/theme/ThemeStyles';
import { S } from './VisimetrixPageLayout.styled';

interface IStaticQuery {
  bg: { childImageSharp: { fluid: IFluidObject } };
}

interface IPureProps {
  data: IStaticQuery;
}

const PureVisimetrixPageLayout: React.FC<IPureProps> = ({ data, children }) => (
  <Layout theme={ThemeName.visimetrix}>
    <S.StyledBackgroundImage fluid={data.bg.childImageSharp.fluid}>
      <PageContentWide>
        <S.PageSectionsWrapper>{children}</S.PageSectionsWrapper>
      </PageContentWide>
    </S.StyledBackgroundImage>
  </Layout>
);

const VisimetrixPageLayout: React.FC = ({ children }) => {
  const data = useStaticQuery<IStaticQuery>(
    graphql`
      query {
        bg: file(relativePath: { eq: "visimetrix-bg.png" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  return <PureVisimetrixPageLayout data={data}>{children}</PureVisimetrixPageLayout>;
};

export default VisimetrixPageLayout;
