import styled from 'styled-components';
import { Colors } from 'src/theme/colors';

export const Root = styled.div`
  width: calc(100% - 40px);
  background-color: #262e32;
  border: solid 1px #767b7d;
  border-style: dashed;
  border-width: 1px;
  border-color: ${({ highlighted }: { highlighted: boolean }) => (highlighted ? Colors.white : '#767b7d')};
  border-radius: 5px;
  padding: 20px;
  color: var(--textPrimary);
  display: block;
  height: 90px;

  &:active {
    border-color: ${Colors.white};
  }

  .light & {
    border-color: ${({ highlighted }: { highlighted: boolean }) => (highlighted ? Colors.black : Colors.white)};
    background-color: #fff;
  }
`;

export const ActionLabel = styled.span`
  color: #7acdd6;
  cursor: pointer;

  .light & {
    color: #356ac8;
  }
`;

export const Description = styled.span`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 12px;
  color: #767b7d;
`;

export const UploadedFileInfo = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  height: 100%;
  align-items: center;
  color: var(--textPrimary);

  .light & {
    color: ${Colors.ui.greyDark};
  }
`;

export const UploadLabel = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: var(--textPrimary);

  .light & {
    color: ${Colors.ui.greyDark};
  }
`;
