import React from 'react';
import { S } from './NoJobsPlaceholder.styled';

interface IProps {
  searchTerm?: string;
  message?: string;
}

const NoJobsPlaceholder = ({
  searchTerm = '',
  message = 'We don’t have any "%SEARCH_TERM%" roles at the moment.',
}: IProps) => (
  <S.Container>
    <S.StyledSadFaceIcon className="fill-white light:fill-black" />
    <S.Header>Sorry</S.Header>
    <S.SubHeader>{message.replace('%SEARCH_TERM%', searchTerm)}</S.SubHeader>
  </S.Container>
);

export default NoJobsPlaceholder;
