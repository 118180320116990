import { Store } from 'pullstate';

interface IUIStore {
  country: null | string;
  isDarkMode: boolean;
  isProductPage: boolean;
  isBannerHidden: boolean;
  isGdprConsentGiven: boolean;
  isInitialized: boolean;
}

export const initialState = {
  country: null,
  isDarkMode: true,
  isProductPage: false,
  isBannerHidden: true,
  isGdprConsentGiven: false,
  isInitialized: false,
};

const UIStore = new Store<IUIStore>(initialState);

export default UIStore;
