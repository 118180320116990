import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { pagePaddingMobile } from 'src/theme/dimensions';

export const MiniaturesContainer = styled.div`
  display: none;
  scroll-margin-top: 80px;

  @media ${mediaQuery.tablet} {
    display: block;
  }
  background: #1b2327;
  border-radius: 16px;
  padding: ${pagePaddingMobile};
  @media ${mediaQuery.navbarCollapse} {
    padding: 64px 92px;
  }
  .light & {
    background-color: ${Colors.ui.componentLight};
    color: ${Colors.ui.greyDark};
  }
`;
export const ServiceMiniatureContainer = styled.div`
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  width: 100%;
  gap: 24px;
`;

interface ServiceMiniatureProps {
  type: 'ProductDesign' | 'TeamAugmentation' | 'ProductDevelopment' | 'DataAnalytics';
}
export const ServiceMiniature = styled.a<ServiceMiniatureProps>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: center;
  gap: 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  width: calc(150px);
  border-radius: 8px;

  color: ${Colors.white};

  background: ${Colors.ui.greyMedium};

  .light & {
    background: ${Colors.white};
    color: ${Colors.ui.greyDark};
  }
  padding: 24px;

  & svg {
    height: 42px;
    width: 42px;
  }
  &:hover svg,
  .light &:hover svg {
    color: ${props =>
      ({
        ProductDesign: Colors.blue.main,
        TeamAugmentation: Colors.teal.main,
        ProductDevelopment: Colors.purple.main,
        DataAnalytics: Colors.red.main,
      }[props.type])};
  }
`;
