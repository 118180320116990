import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingMobile, pagePaddingDesktop } from 'src/theme/dimensions';
import SwitchedOnLogo from './img/SoB-stacked.svg';

const Section = styled.section`
  padding-top: ${boxPaddingMobile};

  @media ${mediaQuery.tablet} {
    padding-top: ${pagePaddingDesktop};
  }
`;

const GoBackLink = styled.button`
  display: block;
  margin-bottom: 45px;
  background-color: unset;
  border: none;
`;

const PageTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 32px;
`;

const StyledSwitchedOnBillingLogo = styled(SwitchedOnLogo)`
  max-width: 100%;
`;

const SubTitle = styled.h2`
  font-size: 22px;
  line-height: 27px;
  font-weight: 400;
  letter-spacing: -1px;
  margin-bottom: 0px;
  color: ${Colors.switchedOnFibre.text.blue80};

  @media ${mediaQuery.tablet} {
    font-size: 22px;
    line-height: 27px;
    width: 70%;
  }
`;

export const S = {
  Section,
  GoBackLink,
  PageTitle,
  StyledSwitchedOnBillingLogo,
  SubTitle,
};
