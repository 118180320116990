import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import BoxText from 'src/components/boxText/BoxText';
import BoxTitle from 'src/components/boxTitle/BoxTitle';
import {
  Root,
  Row,
  Column,
  ContentWrapper,
  TitleWrapper,
  TextWrapper,
  ImgWrapper,
} from './ProjectManagementBox.styled';

const ProjectManagementBox = () => {
  return (
    <Root>
      <Row>
        <Column>
          <ContentWrapper>
            <TitleWrapper>
              <BoxTitle>Exceptional project management</BoxTitle>
            </TitleWrapper>
            <TextWrapper>
              <BoxText>Project management is central to everything we do.</BoxText>
              <BoxText>
                Our commitment to exceptional project management was recognised when we won Project of the Year at PMI
                Ireland’s National Project Awards. We received the award for our work with enet on the automation of
                fibre services.
              </BoxText>
              <BoxText>
                The National Project Awards recognise and reward the best examples of project management and delivery in
                Ireland.
              </BoxText>
            </TextWrapper>
            <ImgWrapper>
              <StaticImage
                className="first-image"
                src="img/pmi_chapter_ireland_logo.png"
                alt="Image for Ireland Chanpter of Project Management Institute"
              />
              <StaticImage
                src="img/ireland-chapter-pmi-npa.png"
                alt="Image for Ireland Chapter PMI National Project Awards"
              />
            </ImgWrapper>
          </ContentWrapper>
        </Column>
      </Row>
    </Root>
  );
};

export default ProjectManagementBox;
