import React from 'react';
import { S } from './NocSection.styled';
import NocCard from './components/nocCard/NocCard';

const NocSection = () => (
  <section>
    <S.Title>Tomorrow’s NOC today </S.Title>
    <S.Description>
      We worked closely with network operators over several years to research and design software for the next
      generation of NOCs. The result is VisiMetrix, a purpose-built solution that improves the user experience and
      unlocks the potential of your organisation. It can combine and leverage valuable data assets that were previously
      consigned to vertical silos.
    </S.Description>

    <S.CardsList>
      <NocCard
        index={1}
        title="Data science led insights"
        description="VisiMetrix models your data to highlight trends and warn you about statistical anomalies. It uses advanced analytics to learn seasonal patterns and enable dynamic thresholds."
        link="/telecom-solutions/visimetrix/data-science-led-insights"
      />
      <NocCard
        index={2}
        title="Better visibility"
        description="Get real-time visibility of network health. Accessible service dashboards provide a unique, customer-centric view of SLAs and track changes that could impact your service performance."
        link="/telecom-solutions/visimetrix/a-better-overview"
      />
      <NocCard
        index={3}
        title="UX excellence"
        description="Create custom user dashboards with an intuitive, feature rich portal. It’s easy to use, flexible and it aggregates data from multiple services to provide a better customer experience. VisiMetrix is designed to anticipate your needs and deliver a quality user experience."
        link="/telecom-solutions/visimetrix/ux-excellence"
      />
      <NocCard
        index={4}
        title="Easy integration"
        description="Collate data from multiple vendors or diverse technologies including probes, element managers, cells, links, routers and other network devices. The VisiMetrix plug-in architecture delivers out-of-the-box support for leading network nodes."
        link="/telecom-solutions/visimetrix/easy-integration"
      />
    </S.CardsList>
  </section>
);

export default NocSection;
