import React from 'react';
import LatestPostsBox from 'src/modules/indexPage/components/latestPostsBox/LatestPostsBox';
import { IParsedBlogPostListItemData } from 'src/types/blog.types';

interface IProps {
  posts: IParsedBlogPostListItemData[];
}

const LatestPostsSection: React.FC<IProps> = ({ posts }) => (
  <section>
    <LatestPostsBox posts={posts} theme="visimetrix" />
  </section>
);

export default LatestPostsSection;
