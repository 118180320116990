import { IBlogPostListItemData, IParsedBlogPostListItemData } from 'src/types/blog.types';

export const flattenBlogPostsData = (blogPostsData: IBlogPostListItemData[]): IParsedBlogPostListItemData[] => {
  return blogPostsData.map(({ postItem }: IBlogPostListItemData): IParsedBlogPostListItemData => {
    return {
      id: postItem.id,
      slug: postItem.fields.slug,
      readingTime: Math.ceil(postItem.fields.readingTime.minutes),
      ...postItem.frontmatter,
    };
  });
};
