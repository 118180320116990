import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingMobile, boxPaddingVerticalDesktop, boxPaddingHorizontalDesktop } from 'src/theme/dimensions';

const Container = styled.div`
  padding: ${boxPaddingMobile};
  border-radius: 16px;
  background-color: ${Colors.ui.greyDark};

  .light & {
    background-color: ${Colors.blue[90]};
  }

  @media ${mediaQuery.desktop} {
    padding: ${boxPaddingVerticalDesktop} ${boxPaddingHorizontalDesktop};
  }
`;

export const S = { Container };
