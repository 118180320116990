import React from 'react';
import { Helmet } from 'react-helmet';
import VisimetrixPageHeader from 'src/components/visimetrix/visimetrixPageHeader/VisimetrixPageHeader';
import VisimetrixPageLayout from 'src/components/visimetrix/visimetrixPageLayout/VisimetrixPageLayout';
import InfoSection from './sections/infoSection/InfoSection';

const VisimetrixABetterOverviewPage = () => {
  const pageTitle = 'VisiMetrix - Real time network and service monitoring';
  const pageDescription =
    'Better visibility of your network with multi customer and service views. Review network KPIs in real time for a better customer experience.';
  return (
    <VisimetrixPageLayout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
      </Helmet>
      <VisimetrixPageHeader
        title="Better visibility"
        subtitle="Monitor your network as a single, connected entity, not a loose set of elements (hardware, applications, etc). Detect potential problems before they affect service continuity."
        isSubPage={true}
      />
      <InfoSection />
    </VisimetrixPageLayout>
  );
};

export default VisimetrixABetterOverviewPage;
