import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

export const Title = styled.div`
  font-size: 56px;
  font-weight: 900;
`;

export const ServiceDescriptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .light & {
    color: ${Colors.ui.titleTextLight};
  }

  & > * {
    max-width: 100%;

    @media ${mediaQuery.tablet} {
      max-width: 48%;
    }
  }
`;

export const MainTitle = styled.h2`
  letter-spacing: -1.5px;
  font-weight: 800;
  font-size: 36px;
  line-height: 36px;

  @media ${mediaQuery.navbarCollapse} {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.5px;
  }
  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;
