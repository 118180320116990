import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingMobile, boxPaddingVerticalDesktop, boxPaddingHorizontalDesktop } from 'src/theme/dimensions';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: ${boxPaddingMobile};
  border-radius: 16px;
  background-color: ${Colors.ui.teal[50]};

  @media ${mediaQuery.smallScreen} {
    flex-direction: row;
  }

  @media ${mediaQuery.desktop} {
    padding: ${boxPaddingVerticalDesktop} ${boxPaddingHorizontalDesktop};
  }
`;

const Column = styled.div`
  flex: 1;
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -2px;
  margin-bottom: 24px;

  @media ${mediaQuery.phone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: ${Colors.ui.teal[80]};
`;

export const S = {
  Section,
  Column,
  Title,
  Description,
};
