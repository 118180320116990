import React from 'react';
import { useForm, UseFormRegister } from 'react-hook-form';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import BoxButton from 'src/components/boxButton/BoxButton';
import { InputWrapper, TermsCheckboxWrapper } from 'src/components/contactForm/ContactForm.styled';
import { IContactFormData } from 'src/components/contactForm/contactForm.types';
import { useContactForm } from 'src/components/contactForm/hooks/useContactForm/useContactForm';
import FormSuccess from 'src/components/formSuccess/FormSuccess';
import Input from 'src/components/input/Input';
import InputLabel from 'src/components/inputLabel/InputLabel';
import InputPhone from 'src/components/inputPhone/InputPhone';
import ValidationMessage from 'src/components/validationMessage/ValidationMessage.styled';
import { ThemeName } from 'src/theme/ThemeStyles';
import { S, Background } from './RequestDemoSection.styled';

const formName = 'SwitchOnBilling demo contact form';

const RequestDemoSection = () => {
  const [sendContactFormData] = useContactForm(formName);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IContactFormData>({ mode: 'onBlur' });
  const termsChecked = watch('terms', false);

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition key={isSubmitSuccessful ? 'success' : 'form'} timeout={500} classNames="fade">
        <S.Section>
          {!isSubmitSuccessful ? (
            <>
              <Background />
              <S.Title>Request a demo</S.Title>
              <S.Description>
                Want to find out more? Schedule a personal demo to see what SwitchedOn Billing™ can do for you.
              </S.Description>

              <S.FormSegment>
                <>
                  <S.FormContainer>
                    <S.Form
                      name={formName}
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      onSubmit={handleSubmit(sendContactFormData)}>
                      <input type="hidden" name="form-name" value={formName} />

                      <S.FormInputsContainer>
                        <InputWrapper>
                          <InputLabel htmlFor="name">Name</InputLabel>
                          <Input
                            name="name"
                            title="name"
                            id="name"
                            register={register as unknown as UseFormRegister<Record<string, any>>}
                            placeholder="Name"
                            rules={{ required: true }}
                            theme={ThemeName.switchedOnBilling}
                          />
                          {errors.name && (
                            <ValidationMessage $theme={ThemeName.switchedOnBilling}>
                              This field is required
                            </ValidationMessage>
                          )}
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel htmlFor="email">Email</InputLabel>
                          <Input
                            name="email"
                            title="email"
                            id="email"
                            register={register as unknown as UseFormRegister<Record<string, any>>}
                            placeholder="Email"
                            inputMode="email"
                            rules={{
                              required: 'This field is required',
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: 'Please provide a properly formatted email address',
                              },
                            }}
                            theme={ThemeName.switchedOnBilling}
                          />
                          {errors.email && (
                            <ValidationMessage $theme={ThemeName.switchedOnBilling}>
                              {errors.email.message}
                            </ValidationMessage>
                          )}
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel htmlFor="businessName">
                            <>
                              Business name <S.StyledInputOptionalLabel>(Optional)</S.StyledInputOptionalLabel>
                            </>
                          </InputLabel>
                          <Input
                            name="businessName"
                            id="businessName"
                            register={register as unknown as UseFormRegister<Record<string, any>>}
                            placeholder="Business name"
                            theme={ThemeName.switchedOnBilling}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel htmlFor="phone">
                            <>
                              Phone number <S.StyledInputOptionalLabel>(Optional)</S.StyledInputOptionalLabel>
                            </>
                          </InputLabel>
                          <InputPhone
                            setValue={val => {
                              setValue('phone', val);
                            }}
                            theme={ThemeName.switchedOnBilling}
                          />
                        </InputWrapper>
                      </S.FormInputsContainer>

                      <TermsCheckboxWrapper>
                        <S.StyledTermsCheckbox
                          onChange={value => setValue('terms', value)}
                          register={register as unknown as UseFormRegister<Record<string, any>>}
                          checked={termsChecked}
                        />
                        {errors.terms && (
                          <ValidationMessage $theme={ThemeName.switchedOnBilling}>
                            You need to accept the Privacy Policy
                          </ValidationMessage>
                        )}
                      </TermsCheckboxWrapper>

                      <BoxButton title="Send form" type="submit">
                        Request a demo
                      </BoxButton>
                    </S.Form>
                  </S.FormContainer>
                </>
              </S.FormSegment>
            </>
          ) : (
            <FormSuccess />
          )}
        </S.Section>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default RequestDemoSection;
