import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

const Title = styled.h2`
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -2px;
  text-align: left;
  color: ${Colors.white};
  margin-bottom: 27px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${mediaQuery.tablet} {
    flex-direction: row;
  }
`;

export const S = {
  Title,
  CardsContainer,
};
