import Link from 'gatsby-link';
import styled from 'styled-components';
import BoxContainer from 'src/components/boxContainer/BoxContainer';
import { BoxTitle } from 'src/components/boxTitle/BoxTitle';
import PageBoxWrapper from 'src/components/pageBoxWrapper/PageBoxWrapper';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

export const Root = styled(BoxContainer)`
  .light & {
    background-color: ${Colors.ui.componentLight};
  }

  .visimetrix & {
    background-color: #1c677e;
  }
`;

export const ContentWrapper = styled(PageBoxWrapper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TitleWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .light & {
    color: ${Colors.ui.titleTextLight};
  }

  .visimetrix & {
    color: #fff;
  }
`;

export const OlderPostsLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #fff;
  font-weight: 700;
  letter-spacing: -0.5px;

  & svg {
    margin-left: 18px;
  }

  &.desktopLink {
    display: none;
  }
  &.mobileLink {
    margin-top: 32px;
  }

  @media ${mediaQuery.tablet} {
    &.desktopLink {
      display: flex;
    }
    &.mobileLink {
      display: none;
    }
  }

  .light & {
    color: ${Colors.ui.titleTextLight};
  }

  .visimetrix & {
    color: #fff;
  }
`;

export const SectionTitle = styled(BoxTitle)`
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 40px;
  margin-top: 26px;
`;
