import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisimetrixInfoItem from 'src/components/visimetrix/visimetrixInfoItem/VisimetrixInfoItem';
import { S } from './InfoSection.styled';

const InfoSection = () => (
  <S.Section>
    <VisimetrixInfoItem
      title="Data presentation options"
      descriptions={[
        'The feature rich portal allows you to create custom user dashboards to suit your needs, including:',

        <S.PresentationOpionsList key="data-presentation-options">
          <li key="0">Bespoke wallboard visualisations for large, mission critical Network Operations Centres</li>
          <li key="1">Executive dashboards to get real time info to your C-suite</li>
          <li key="2">
            Granular views to enable performance monitoring, analysis and troubleshooting for technical teams
          </li>
          <li key="3">
            Enterprise views allow nodes to be partitioned based on customers, for account managers or individual
            customers
          </li>
        </S.PresentationOpionsList>,
      ]}
      ItemImage={
        <StaticImage src="img/data-presentation-options.png" alt="VisiMetrix - screenshot of 3G and 4G KPIs" />
      }
      tabletOrientation="right"
    />
    <VisimetrixInfoItem
      title="Advanced KPI visualisation"
      descriptions={[
        'In addition to providing bespoke dashboard views, VisiMetrix has advanced thresholding and KPI management features. KPIs are colour coded and displayed in appealing visuals that make it easier to interpret complex data.',

        'KPIs can be visualised in a variety of formats. This includes geographic map views that let you assess your network’s current status and its changing status over time.',
      ]}
      ItemImage={<StaticImage src="img/advanced-kpi.png" alt="VisiMetrix - screenshot of advanced visualisations" />}
      tabletOrientation="left"
    />
  </S.Section>
);

export default InfoSection;
