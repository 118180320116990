import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';

const Section = styled.section`
  margin: auto;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;

  & > * {
    flex: 1;
  }

  @media ${mediaQuery.smallScreen} {
    flex-direction: row;
    gap: 24px;
  }
`;

const RowWithSeparator = styled(Row)`
  margin-bottom: 80px;
`;

export const S = { Section, Row, RowWithSeparator };
