import styled from 'styled-components';
import { Colors } from 'src/theme/colors';

const Section = styled.section`
  border-radius: 16px;
  padding: 30px 30px 0px 30px;

  background-color: ${Colors.ui.teal[50]};
`;

export const S = { Section };
