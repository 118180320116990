import React from 'react';
import BoxText from 'src/components/boxText/BoxText';
import {
  Root,
  BoxContentWrapper,
  TitleWrapper,
  TextWrapper,
  ButtonWrapper,
  Container,
  Button,
  Content,
  SectionTitle,
} from './VisimetrixSwitchedOnFibreBox.styled';
import PivotsHeading from './img/pivots-logo.svg';
import SwitchedOnBillingHeading from './img/sob-logo.svg';
import SwitchedOnFibreHeading from './img/sof-logo.svg';
import VisimetrixHeading from './img/visimetrix-logo.svg';

const VisimetrixSwitchedOnFibreBox = () => {
  return (
    <Container $theme={'dark'}>
      <SectionTitle>Telecom solutions</SectionTitle>
      <Content>
        <Root className="fibre-root">
          <BoxContentWrapper className="fibre-content">
            <TitleWrapper>
              <p>Telecom solutions</p>
              <SwitchedOnFibreHeading style={{ maxWidth: '100%' }} title="SwitchedOn Fibre" />
            </TitleWrapper>
            <TextWrapper>
              <BoxText>
                A modular suite of BSS software that automates the lead-to-cash & trouble-to-resolve across wholesale,
                retail and enterprise services
              </BoxText>
            </TextWrapper>
            <ButtonWrapper title="Discover SwitchedOn Fibre">
              <Button to="/telecom-solutions/switched-on-fibre" state={{ canGoBack: true }}>
                Learn more
              </Button>
            </ButtonWrapper>
          </BoxContentWrapper>
        </Root>
        <Root className="visimetrix-root">
          <BoxContentWrapper className="visimetrix-content">
            <TitleWrapper>
              <p>Telecom solutions</p>
              <VisimetrixHeading title="Visimetrix" />
            </TitleWrapper>
            <TextWrapper>
              <BoxText>A new approach to network service monitoring and AIOps-enabled analytics</BoxText>
            </TextWrapper>

            <ButtonWrapper title="Go to VisiMetrix">
              <Button to="/telecom-solutions/visimetrix" state={{ canGoBack: true }}>
                Learn more
              </Button>
            </ButtonWrapper>
          </BoxContentWrapper>
        </Root>
        <Root className="billing-root">
          <BoxContentWrapper className="billing-content">
            <TitleWrapper>
              <p>Telecom solutions</p>
              <SwitchedOnBillingHeading style={{ maxWidth: '100%' }} title="SwitchedOn Billing" />
            </TitleWrapper>
            <TextWrapper>
              <BoxText>A powerful platform to manage and monetise your subscriptions</BoxText>
            </TextWrapper>

            <ButtonWrapper title="Go to VisiMetrix">
              <Button to="/telecom-solutions/switched-on-billing" state={{ canGoBack: true }}>
                Learn more
              </Button>
            </ButtonWrapper>
          </BoxContentWrapper>
        </Root>
        <Root className="pivots-root">
          <BoxContentWrapper>
            <TitleWrapper>
              <p>Telecom solutions</p>
              <PivotsHeading title="PivOTS" />
            </TitleWrapper>
            <TextWrapper>
              <BoxText>Secure, reliable One Touch Switching</BoxText>
            </TextWrapper>

            <ButtonWrapper title="Go to PivOTS">
              <Button to="/telecom-solutions/pivots-one-touch-switching" state={{ canGoBack: true }}>
                Learn more
              </Button>
            </ButtonWrapper>
          </BoxContentWrapper>
        </Root>
      </Content>
    </Container>
  );
};

export default VisimetrixSwitchedOnFibreBox;
