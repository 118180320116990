import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingHorizontalDesktop, boxPaddingMobile, boxPaddingVerticalDesktop } from 'src/theme/dimensions';
import { Typography } from 'src/theme/typography';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  height: 540px;
  background-color: ${Colors.switchedOnFibre.bg.blue60};
  color: ${Colors.white};
  padding-bottom: 68px;

  @media ${mediaQuery.smallScreen} {
    flex-direction: row;
    padding-bottom: 0;
  }
`;

const TextCol = styled.div`
  padding: ${boxPaddingMobile};

  @media ${mediaQuery.smallScreen} {
    width: 50%;
  }

  @media ${mediaQuery.desktop} {
    padding: ${boxPaddingVerticalDesktop} ${boxPaddingHorizontalDesktop};
  }
`;

const ImgCol = styled.div`
  flex: 1;
  overflow: hidden;

  @media ${mediaQuery.smallScreen} {
    width: 50%;
  }
`;

const Title = styled.h3`
  font-size: 40px;
  line-height: 40px;
  font-weight: 800;
  letter-spacing: -2px;
  text-align: left;
  margin-bottom: 32px;
`;

const Description = styled.p`
  color: ${Colors.switchedOnFibre.text.blue60};
  ${Typography.body[16]};
  text-align: left;
`;

export const S = {
  Card,
  TextCol,
  ImgCol,
  Title,
  Description,
};
