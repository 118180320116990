import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { flattenBlogPostsData } from 'src/services/blog/blog';
import { IBlogPostListItemData, IParsedBlogPostListItemData, ITagItemData } from 'src/types/blog.types';
import { useIsomorficLayoutEffect } from '../indexPage/hooks/useIsomorficLayoutEffect';
import { OrderOptions } from './components/blogListOrderSelect/blogListOrderSelect';

const POSTS_PER_PAGE = 9;

export const useBlogData = (blogPostsList: IBlogPostListItemData[] = []) => {
  const [category, setCategory] = useState<string | undefined>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [postsCount, setPostsCount] = useState(POSTS_PER_PAGE);
  const [orderBy, setOrderBy] = useState<OrderOptions>(OrderOptions.newest);

  const allPosts: IParsedBlogPostListItemData[] = flattenBlogPostsData(blogPostsList);
  const availableTags = React.useMemo<ITagItemData[]>(() => {
    const categories = allPosts.reduce<string[]>((categories, post) => [...categories, ...(post.categories || [])], []);
    const uniqueCategories = new Set(categories);
    return [...uniqueCategories].map(uc => ({ tagName: uc }));
  }, [allPosts]);

  const sortedCategoryPosts: IParsedBlogPostListItemData[] = React.useMemo(() => {
    const categoryPosts = category ? allPosts.filter(post => (post.categories || []).includes(category)) : allPosts;
    const sq = searchQuery.toLowerCase();
    const filteredPosts = sq
      ? categoryPosts.filter(post => (post.title || '').toLowerCase().search(sq) >= 0)
      : categoryPosts;
    const orderedPosts = orderBy === OrderOptions.oldest ? filteredPosts.reverse() : filteredPosts;
    return orderedPosts;
  }, [allPosts, category, searchQuery]);

  useIsomorficLayoutEffect(() => {
    setPostsCount(POSTS_PER_PAGE);
  }, [category, searchQuery]);

  const showMore = useCallback(() => {
    setPostsCount(postsCount => postsCount + POSTS_PER_PAGE);
  }, []);

  const posts = React.useMemo(
    () => sortedCategoryPosts.slice(0, postsCount).filter(Boolean),
    [sortedCategoryPosts, postsCount],
  );

  const canShowMore = useMemo(() => sortedCategoryPosts.length > postsCount, [postsCount, sortedCategoryPosts.length]);

  return {
    orderBy,
    setOrderBy,
    sortedCategoryPosts,
    category,
    setCategory,
    availableTags,
    searchQuery,
    setSearchQuery,
    showMore,
    posts,
    canShowMore,
  };
};
