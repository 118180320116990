export interface Address {
  title: string;
  addressLines: string[];
  mapUrl: string;
  coords: ICoords;
}
interface ICoords {
  lat: number;
  lng: number;
}

export const dublinOfficeCoords: ICoords = {
  lat: 53.300310692083634,
  lng: -6.175656672591268,
};

export const poznanOfficeCoords: ICoords = {
  lat: 52.4046733,
  lng: 16.9279183,
};

export const bratislavaOfficeCoords = {
  lat: 48.14614691071248,
  lng: 17.130622298422377,
};

export const addresses = {
  dublin: {
    title: 'Ireland',
    addressLines: ['Quantum House, Temple Road', 'Blackrock, Co. Dublin', 'A94 X0H9 Ireland'],
    mapUrl: `https://maps.google.com?q=${dublinOfficeCoords.lat},${dublinOfficeCoords.lng}`,
    coords: dublinOfficeCoords,
  },
  poland: {
    title: 'Poland',
    addressLines: ['Krysiewicza 2', '61-895 Poznań', 'Poland'],
    mapUrl: `https://maps.google.com?q=${poznanOfficeCoords.lat},${poznanOfficeCoords.lng}`,
    coords: poznanOfficeCoords,
  },
  slovakia: {
    title: 'Slovakia',
    addressLines: ['Mlynské Nivy 5', '82109 Bratislava', 'Slovakia'],
    mapUrl: `https://maps.google.com?q=${bratislavaOfficeCoords.lat},${bratislavaOfficeCoords.lng}`,
    coords: bratislavaOfficeCoords,
  },
};
export const addressList: Address[] = [addresses.dublin, addresses.poland, addresses.slovakia];

export const mapsApiKey = 'AIzaSyAD6KmtYVNhkFnanFe96S1iWbSYJrevUmA';
