function logText(text: string, color: string) {
  console.log(`%c${text}`, `color:${color}`);
}

export default function consoleEasterEgg() {
  const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const color = isDarkTheme ? '#fff' : '#1B2327';

  logText('    ,▄████▄   ╓@╣▒╢╣@╓   ██▄    ██     ▄██     ██▌        ╔██⌐    ▐█▌  ,██▀  ██████', color);
  logText('    ██      ╓╢╣`    ╙▒╣  ████   ██    ▄████    ██▌       ╓████    ██▌,██▀    ██    ', color);
  logText('     ▀███▄               ██ ██▄ ██   ▐██ ▐██   ██▌      ,██  ██   █████      ██████', color);
  logText('        ▀██ ▀██     ,██▌ ██  ▀████  ╔███████▌  ██▌      ████████  ██▌▀██▄    ██    ', color);
  logText('   ▐███▄██▀  ▀███████▀   ██    ███ ╒██     ██▌ ██████▌ ██`    ▐██ ██▌  ▀██▄  ██████', color);
}
