import Link from 'gatsby-link';
import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingHorizontalDesktop, boxPaddingMobile } from 'src/theme/dimensions';
import { Typography } from 'src/theme/typography';

const CardLink = styled(Link)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${Colors.switchedOnFibre.bg.blue60};
  color: ${Colors.white};
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.5s;

  &:hover {
    transform: scale(1.0375);
  }
`;

const DescriptionSegment = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${boxPaddingMobile};

  @media ${mediaQuery.desktop} {
    padding: 24px ${boxPaddingHorizontalDesktop};
  }
  & .logo {
    filter: invert(1);
  }
`;

const Description = styled.p`
  ${Typography.body[16]};
  color: ${Colors.ui.bodyText};
`;

export const S = {
  CardLink,
  DescriptionSegment,
  Description,
};
