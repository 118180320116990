import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

export const Root = styled.div``;

export const Title = styled.h1`
  font-size: 35px;
  font-weight: 900;
  margin-bottom: 20px;

  @media ${mediaQuery.desktop} {
    font-size: 64px;
    line-height: 64px;
  }

  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 30px;
  color: #dddedf;

  @media ${mediaQuery.desktop} {
    font-size: 22px;
    line-height: 27px;
  }

  .light & {
    color: #494f52;
  }
`;

export const DotSeparator = styled.span`
  padding: 0 9px;
  line-height: 10px;
  color: #dddedf;
`;
