import styled, { css } from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { Typography } from 'src/theme/typography';

const Item = styled.li`
  list-style-type: none;
  margin-bottom: 0px;
  display: flex;
  gap: 40px;
  flex-direction: column;

  @media ${mediaQuery.desktop} {
    gap: 80px;
    ${({ $orientation }: { $orientation: 'left' | 'right' }) =>
      css`
        flex-direction: ${$orientation === 'left' ? 'row' : 'row-reverse'};
      `}
  }
`;

const ItemTextContainer = styled.div`
  flex-grow: 2;
  flex-shrink: 0;
  flex-basis: 0;
  display: flex;
`;

const Index = styled.span`
  display: block;
  font-size: 34px;
  min-width: 84px;
  height: 84px;
  line-height: 84px;
  font-weight: 700;
  text-align: center;
  margin-right: 24px;
  border-radius: 50%;
  border: 2px solid #629eff;
  color: ${Colors.white};
`;

const Title = styled.h3`
  font-size: 34px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -2px;
  color: ${Colors.white};
  margin-bottom: 8px;
`;

const SubTitle = styled.h4`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: ${Colors.white};
  margin-bottom: 24px;
`;

const Description = styled.p`
  ${Typography.body[16]};
  color: ${Colors.switchedOnFibre.text.blue60};
`;

const ItemImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  align-self: center;
  max-width: 400px;

  @media ${mediaQuery.desktop} {
    justify-content: ${({ $orientation }: { $orientation: 'left' | 'right' }) =>
      $orientation === 'left' ? 'flex-end' : 'flex-start'};
  }
`;

export const S = {
  Item,
  ItemTextContainer,
  Index,
  Title,
  SubTitle,
  Description,
  ItemImageContainer,
};
