import Link from 'gatsby-link';
import * as React from 'react';
import { parseCategoryTag } from 'src/services/tags/tags';
import { ITagItemData } from 'src/types/blog.types';
import { Root, TagButton, BlogCategoriesPanelLinksWrapper } from './BlogCategoriesPanel.styled';

interface IProps {
  availableTags: ITagItemData[];
  hiddenCategories?: string[];
  currentCategory?: string;
}

const BlogCategoriesPanel = ({ availableTags, currentCategory, hiddenCategories }: IProps) => {
  const createHref = (tagName: string) => {
    const searchParams = new URLSearchParams({ category: tagName });
    return `/latest?${searchParams}`;
  };

  return (
    <Root>
      <BlogCategoriesPanelLinksWrapper>
        <Link to="/latest">
          <TagButton $isHighlighted={!currentCategory}>All</TagButton>
        </Link>
        {availableTags
          ?.filter(({ tagName }: ITagItemData) => !hiddenCategories?.includes(tagName))
          .map(({ tagName }: ITagItemData, index: number) => (
            <Link key={index} to={createHref(tagName)} state={{ keepScrollPosition: true }}>
              <TagButton $isHighlighted={currentCategory === tagName}>{parseCategoryTag(tagName)}</TagButton>
            </Link>
          ))}
      </BlogCategoriesPanelLinksWrapper>
    </Root>
  );
};

export default BlogCategoriesPanel;
