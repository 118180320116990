import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingMobile } from 'src/theme/dimensions';

export const Root = styled.div`
  padding: ${boxPaddingMobile};
  background-color: #262e32;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  height: 115px;

  @media ${mediaQuery.desktop} {
    padding: 24px;
  }

  .light & {
    background-color: #fff;
  }
`;

export const JobTitle = styled.div`
  font-size: 18px;
  font-weight: 700;

  @media ${mediaQuery.desktop} {
    font-size: 28px;
  }

  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;

export const LocationBadge = styled.span`
  display: flex;
  gap: 16px;
  align-items: center;
  text-transform: uppercase;
  background-color: #1b2327;
  font-weight: 900;
  padding: 16px;
  border-radius: 8px;
  letter-spacing: 2px;
  font-size: 12px;
  margin-right: 20px;

  svg {
    width: 32px;
  }

  @media ${mediaQuery.desktop} {
    font-size: 16px;
  }

  .light & {
    background-color: #f3f5f5;
  }
`;
