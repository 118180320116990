import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

export const Root = styled.div`
  height: 200px;
  width: 100%;
  border: solid 1px #3d4447;
  border-radius: 16px;
  overflow: hidden;

  @media ${mediaQuery.desktop} {
    height: 400px;
  }
`;

export const MarkerStyled = styled.div`
  width: 8px;
  height: 8px;
  border: solid 2px white;
  border-radius: 8px;
  background-color: ${Colors.blue.main};
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(2);
  }
`;
