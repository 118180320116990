import React from 'react';
import CompanyValuesCommunityIconSVG from 'src/assets/icons/icon_companyValues_community.svg';
import CompanyValuesEmpathyIconSVG from 'src/assets/icons/icon_companyValues_empathy.svg';
import CompanyValuesEvolutionIconSVG from 'src/assets/icons/icon_companyValues_evolution.svg';
import CompanyValuesImpactIconSVG from 'src/assets/icons/icon_companyValues_impact.svg';
import CompanyValuesPotentialIconSVG from 'src/assets/icons/icon_companyValues_potential.svg';
import CompanyValuesTrustIconSVG from 'src/assets/icons/icon_companyValues_trust.svg';
import BoxItem from 'src/components/boxItem/BoxItem';
import CompanyValuesBox from 'src/components/companyValues/CompanyValuesBox';

const AboutCompanyValuesBox = () => (
  <CompanyValuesBox>
    <BoxItem
      icon={<CompanyValuesImpactIconSVG className="fill-white light:fill-black" />}
      className="force-width"
      title="Impact"
      descriptions={['We aim to make an exceptional impact, bringing our innovative best to all that we do.']}
    />
    <BoxItem
      icon={<CompanyValuesEmpathyIconSVG className="fill-white light:fill-black" />}
      title="Empathy"
      descriptions={['We listen carefully before we act - to the user, to the customer, and to each other.']}
    />
    <BoxItem
      icon={<CompanyValuesTrustIconSVG className="fill-white light:fill-black" />}
      title="Trust"
      descriptions={['We build trust through our integrity and our proactive approach to collaboration.']}
    />
    <BoxItem
      icon={<CompanyValuesEvolutionIconSVG className="fill-white light:fill-black" />}
      title="Evolution"
      descriptions={['We always seek out inventive ways of working for team efficiency and fulfilment.']}
    />
    <BoxItem
      icon={<CompanyValuesPotentialIconSVG className="fill-white light:fill-black" />}
      title="Potential"
      descriptions={['We push for personal growth to reach our potential. Feedback, mentoring and training are core.']}
    />
    <BoxItem
      icon={<CompanyValuesCommunityIconSVG className="fill-white light:fill-black" />}
      title="Community"
      descriptions={['We believe in creating a diverse and stimulating environment of work and social engagement.']}
    />
  </CompanyValuesBox>
);

export default AboutCompanyValuesBox;
