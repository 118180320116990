import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { Typography } from 'src/theme/typography';

const IntroductionSegment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 80px;

  @media ${mediaQuery.tablet} {
    flex-direction: row;
  }
`;

const DescriptionContainer = styled.div`
  flex: 1;
`;

const Header = styled.h2`
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -2px;
  margin-bottom: 40px;
  color: ${Colors.white};
`;

const PrimaryHeaderRow = styled.span`
  display: block;
  margin-bottom: 8px;
`;

const SecondaryHeaderRow = styled.span`
  font-size: 32px;
`;

const Description = styled.p`
  ${Typography.body[16]};
  color: ${Colors.switchedOnFibre.text.blue80};
`;

const SimpleFlowList = styled.ul`
  flex: 1;
`;

const FlowList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

export const S = {
  IntroductionSegment,
  DescriptionContainer,
  Header,
  PrimaryHeaderRow,
  SecondaryHeaderRow,
  Description,
  SimpleFlowList,
  FlowList,
};
