import styled from 'styled-components';

export const Root = styled.div`
  & a {
    display: inline-flex;
    text-decoration: none;
  }
`;

export const CaseCategoriesPanelLinksWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 16px;
`;

export const TagButton = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 16px;
  background-color: ${({ $isHighlighted }: { $isHighlighted: boolean }) => ($isHighlighted ? '#fff' : '#1B2327')};
  color: ${({ $isHighlighted }: { $isHighlighted: boolean }) => ($isHighlighted ? '#1B2327' : '#fff')};
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;

  &:hover {
    color: #1b2327;
    background-color: #fff;
  }

  .light && {
    background-color: ${({ $isHighlighted }: { $isHighlighted: boolean }) => ($isHighlighted ? '#1B2327' : '#E4E5E6')};
    color: ${({ $isHighlighted }: { $isHighlighted: boolean }) => ($isHighlighted ? '#fff' : '#494F52')};

    &:hover {
      color: #fff;
      background-color: #1b2327;
    }
  }
`;
