import styled from 'styled-components';

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const S = {
  DescriptionContainer,
};
