import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';

const StyledBackgroundImage = styled(BackgroundImage)`
  background-size: 60% auto !important;
  background-position: right 700px !important;
  background-repeat: no-repeat;

  @media ${mediaQuery.tablet} {
    &,
    &::before,
    &::after {
      background-size: 40% auto !important;
      background-position: right 400px !important;
    }
  }

  @media ${mediaQuery.phone} {
    &,
    &::before,
    &::after {
      background-size: 60% auto !important;
      background-position: top 700px right 0px !important;
    }
  }
`;

const PageSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;

  @media ${mediaQuery.tablet} {
    gap: 80px;
    margin-bottom: 80px;
  }
`;

export const S = {
  StyledBackgroundImage,
  PageSectionsWrapper,
};
