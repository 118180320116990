import { useLocation } from '@reach/router';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import BackButton from 'src/components/backButton/BackButton';
import BlogPostBanner from 'src/components/blogPostBanner/BlogPostBanner';
import Layout from 'src/components/layout/Layout';
import useSiteMetadata from 'src/components/layout/hooks/useSiteMetadata';
import PageContentNarrow from 'src/components/pageContentNarrow/PageContentNarrow';
import BlogPostContent from 'src/modules/blogPost/components/blogPostContent/BlogPostContent';
import type { IAuthorCaptionData } from 'src/types/blog.types';
import { BlogPostBannerWrapper, GoBackLink } from './blogPost.styled';

interface IBlogPost {
  markdownRemark: {
    html: string;
    frontmatter: {
      title: string;
      description: string;
      creator: string;
      date: string;
      iconType: string;
      categories?: string[];
      authorCaption?: IAuthorCaptionData;
    };
    fields: {
      readingTime: {
        text: string;
      };
    };
  };
}

const BlogPost = ({ data }: { data: IBlogPost }) => {
  const { siteUrl } = useSiteMetadata();
  const { state } = useLocation() as { state: { canGoBack?: boolean } | null };
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <>
        <Helmet titleTemplate="%s | Blog">
          <title>{`${post?.frontmatter?.title}`}</title>
          <meta property="og:image" content={`${siteUrl}/blogCovers/${post.frontmatter.iconType}.png`} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={post?.frontmatter?.title} />
          <meta property="og:description" content={`${post?.frontmatter?.description}`} />
          <meta name="description" content={`${post?.frontmatter?.description}`} />
        </Helmet>
        <BlogPostBannerWrapper>
          <BlogPostBanner
            iconType={post.frontmatter.iconType}
            date={post?.frontmatter?.date}
            title={`${post?.frontmatter?.title}`}
            readingTime={post?.fields.readingTime.text}
            authorCaptionData={post?.frontmatter?.authorCaption}
            theme="blog"
          />
        </BlogPostBannerWrapper>

        <PageContentNarrow>
          <GoBackLink onClick={() => (state?.canGoBack ? navigate(-1) : navigate('/latest'))}>
            <BackButton />
          </GoBackLink>
          <BlogPostContent content={post.html} />
        </PageContentNarrow>
      </>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        readingTime {
          text
        }
      }
      frontmatter {
        date(formatString: "DD MMM YYYY")
        title
        description
        creator
        iconType
        categories
        authorCaption {
          name
          position
          photo {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 40)
            }
          }
        }
      }
    }
  }
`;
