import React from 'react';
import BoxText from 'src/components/boxText/BoxText';
import BoxSubTitle from '../boxSubTitle/BoxSubTitle';
import { Root, Row, Column, ContentWrapper, TitleWrapper, TextWrapper } from './CompanyTechStack.styled';

const CompanyTechStack = () => {
  return (
    <Root>
      <Row>
        <Column>
          <ContentWrapper>
            <TitleWrapper>
              <BoxSubTitle>Tech stack</BoxSubTitle>
            </TitleWrapper>
            <TextWrapper>
              <BoxText>
                We love open source tech. We develop large-scale platforms with multiple data integrations, and process
                billions of transactions per day. We embrace the latest technologies, frameworks, tools and processes.
              </BoxText>
            </TextWrapper>
          </ContentWrapper>
        </Column>
      </Row>
    </Root>
  );
};

export default CompanyTechStack;
