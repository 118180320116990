import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingHorizontalDesktop, boxPaddingMobile, boxPaddingVerticalDesktop } from 'src/theme/dimensions';

export const Container = styled.div`
  border-radius: 16px;
  background-color: #1b2327;
  padding: ${boxPaddingMobile};
  padding-bottom: 0;

  .light & {
    background-color: ${Colors.ui.componentLight};
  }

  @media ${mediaQuery.desktop} {
    padding: ${boxPaddingVerticalDesktop} ${boxPaddingHorizontalDesktop};
  }
`;

export const Title = styled.h2`
  letter-spacing: -1.5px;
  font-weight: 800;

  @media ${mediaQuery.tablet} {
    font-size: 40px;
  }

  @media ${mediaQuery.navbarCollapse} {
    font-size: 36px;
  }

  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;

export const S = {
  Container,
  Title,
};
