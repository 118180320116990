import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from 'src/components/layout/hooks/useSiteMetadata';
import PivotsPageHeader from 'src/components/pivots/pivotsPageHeader/PivotsPageHeader';
import PivotsPageLayout from 'src/components/pivots/pivotsPageLayout/PivotsPageLayout';
import MainSections from './sections/mainSections/MainSections';

const PivotsPage: React.FC = () => {
  const { siteUrl } = useSiteMetadata();

  const pageTitle = 'pivOTS™️ - Secure, reliable One Touch Switching';
  const pageDescription =
    'pivOTS minimises the effort required for providers to meet their OTS regulatory obligations. It simplifies TOTSCo Hub integration in both gaining and losing scenarios.';

  return (
    <PivotsPageLayout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={`${siteUrl}/banners/pivOts.png`} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      <PivotsPageHeader title="pivOTS™️" subtitle="Secure, reliable One Touch Switching" />
      <MainSections />
    </PivotsPageLayout>
  );
};

export default PivotsPage;
