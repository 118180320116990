import * as React from 'react';
import styled from 'styled-components';
import { getBlogIcon } from 'src/services/blog/blogIcon';

export interface IProps {
  iconType: string;
  className?: string;
}

const BlogImage = styled.div`
  width: 100%;
  height: 165px;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  & svg {
    width: 75%;
    height: 75%;
  }
`;

const BlogRollImage = ({ iconType, className }: IProps) => {
  const { background, Icon } = getBlogIcon(iconType);
  return (
    <BlogImage className={className} style={{ background }}>
      <Icon />
    </BlogImage>
  );
};

export default BlogRollImage;
