import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import React, { useEffect } from 'react';
import consoleEasterEgg from './src/components/consoleEasterEgg/ConsoleEasterEgg.tsx';
import './src/modules/blogPost/styles/gatsby-remark-code-buttons.scss';
import './src/modules/blogPost/styles/prism-theme-dark.scss';
import UIStore from './src/store/Store';

export const shouldUpdateScroll = ({ routerProps }) => {
  // todo load it only in blog page
  const state = routerProps.location.state || {};

  if (state.keepScrollPosition) {
    return false;
  }

  return window.scrollTo(0, 0);
};

export const onClientEntry = () => {
  consoleEasterEgg();
};

export const wrapPageElement = ({ element }) => {
  useEffect(() => {
    UIStore.update(s => {
      s.isDarkMode = __currentThemeMode !== 'light';
      s.isInitialized = true;
    });
  }, []);

  return element;
};
