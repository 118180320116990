import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingMobile, pagePaddingDesktop } from 'src/theme/dimensions';
import SwitchedOnFibreLogo from './img/SoF-inline.svg';

const Section = styled.section`
  padding-top: ${boxPaddingMobile};

  @media ${mediaQuery.tablet} {
    padding-top: ${pagePaddingDesktop};
  }
`;

const GoBackLink = styled.button`
  display: block;
  margin-bottom: 45px;
  background-color: unset;
  border: none;
`;

const PageTitle = styled.h1`
  margin-top: 0;
  margin-bottom: 32px;
`;

const StyledSwitchedOnFibreLogo = styled(SwitchedOnFibreLogo)`
  width: 100%;
  height: 100%;
`;

const SubTitle = styled.h2`
  font-size: 22px;
  line-height: 27px;
  font-weight: 400;
  letter-spacing: -1px;
  margin-bottom: 0px;
  color: ${Colors.switchedOnFibre.text.blue80};

  @media ${mediaQuery.tablet} {
    font-size: 22px;
    line-height: 27px;
    width: 50%;
  }
  @media ${mediaQuery.navbarCollapse} {
    width: 50%;
  }
`;

export const S = {
  Section,
  GoBackLink,
  PageTitle,
  StyledSwitchedOnFibreLogo,
  SubTitle,
};
