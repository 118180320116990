import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

const Title = styled.h2`
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -2px;
  color: ${Colors.white};
  margin-bottom: 40px;

  @media ${mediaQuery.phone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media ${mediaQuery.desktop} {
    gap: 80px;
    flex-direction: row;
  }
`;

const Description = styled.p`
  flex: 1;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: ${Colors.ui.teal[80]};
  margin-bottom: 0px;
`;

const LogosContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
  justify-content: space-around;
  align-items: center;

  @media ${mediaQuery.desktop} {
    justify-content: space-between;
  }
`;

export const S = {
  Title,
  DescriptionContainer,
  Description,
  LogosContainer,
};
