import styled from 'styled-components';
import { Colors } from 'src/theme/colors';

export const TextareaStyled = styled.textarea`
  width: calc(100% - 40px);
  border: none;
  resize: none;
  padding: 17px 16px 17px 24px;
  border: 1px solid #262e32;
  background-color: #262e32;
  border-radius: 8px;
  color: var(--textPrimary);
  display: block;

  &::placeholder {
    color: #767b7d;
    font-weight: 500;
  }

  &:active {
    border-color: ${Colors.white};
  }

  .light & {
    color: ${Colors.ui.greyDark};
    background-color: #fff;
    border: none;

    &::placeholder {
      color: #494f52;
    }
  }
`;
