import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { boxPaddingHorizontalDesktop } from 'src/theme/dimensions';
import Card from './components/card/Card';

export const CaseStudiesCategoriesPanelWrapper = styled.div`
  @media ${mediaQuery.desktop} {
    padding: 0 100px;
  }
`;

export const CardContentWrapper = styled.div`
  padding: 30px;
  display: flex;
  height: 100%;

  flex-direction: column;
  gap: 16px;
  & .logo {
    filter: invert(1);
  }
  .light & .logo {
    filter: invert(0);
  }

  img {
    max-height: 50px;
  }

  @media ${mediaQuery.desktop} {
    padding: 24px ${boxPaddingHorizontalDesktop};
    padding-bottom: 10px;
  }
`;
export const CardContentDescription = styled.div`
  flex: 1;
`;

export const CaseStudiesCard = styled(Card)`
  transition: transform 0.5s;
  height: 100%;
  &:hover {
    transform: scale(1.0375);
  }
`;

export const CardCoverImageWrapper = styled.div`
  img {
    border-radius: 0 0 8px 8px;
  }
  @media ${mediaQuery.navbarCollapse} {
    img {
      min-width: 520px !important;
    }
  }
`;

export const CaseStudiesList = styled.div`
  display: grid;

  gap: 24px;
  @media ${mediaQuery.navbarCollapse} {
    grid-template-columns: repeat(2, 1fr);
  }

  & a {
    height: 100%;
  }
`;
