import React from 'react';
import { Helmet } from 'react-helmet';
import VisimetrixPageHeader from 'src/components/visimetrix/visimetrixPageHeader/VisimetrixPageHeader';
import VisimetrixPageLayout from 'src/components/visimetrix/visimetrixPageLayout/VisimetrixPageLayout';
import TestimonialsSection from '../visimetrixPage/sections/testimonialsSection/TestimonialsSection';
import InfoSection from './sections/infoSection/InfoSection';
import IntroSection from './sections/introSection/IntroSection';

const VisimetrixUxExcellencePage = () => {
  const pageTitle = 'VisiMetrix - Customised user dashboards and data visualisation';
  const pageDescription =
    'User-friendly dashboards and intuitive visuals help you leverage valuable data assets and improve customer UX.';
  return (
    <VisimetrixPageLayout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
      </Helmet>
      <VisimetrixPageHeader
        title="UX excellence"
        subtitle="A quality user experience will encourage adoption across the organisation. User-friendly dashboards help you leverage valuable data assets that can now be collected in one place."
        isSubPage={true}
      />
      <IntroSection />
      <TestimonialsSection />
      <InfoSection />
    </VisimetrixPageLayout>
  );
};

export default VisimetrixUxExcellencePage;
