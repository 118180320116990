import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { pagePaddingMobile } from 'src/theme/dimensions';

export const BlogPostBannerWrapper = styled.div`
  display: flex;
  max-width: 1080px;
  margin: 0 auto;
  @media ${mediaQuery.phone} {
    padding: 0px ${pagePaddingMobile};
  }
`;

export const GoBackLink = styled.button`
  display: block;
  background-color: unset;
  border: none;
  margin: 60px 0;
`;
