import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';

export const Root = styled.div``;

const PanelWrapper = styled.div`
  @media ${mediaQuery.desktop} {
    padding: 0 140px;
  }
`;

export const BlogCategoriesPanelWrapper = styled(PanelWrapper)``;
export const BlogFilterPanelWrapper = styled(PanelWrapper)``;
export const BlogRollWrapper = styled(PanelWrapper)``;
export const BlogFilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${mediaQuery.phone} {
    flex-wrap: wrap;
  }
`;

export const BlogFilterSubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 4;
  @media ${mediaQuery.phone} {
    margin-top: 10px;
  }
`;

export const BlogSearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  @media ${mediaQuery.phone} {
    flex-basis: 100%;
  }
`;

export const ShowMoreButton = styled.button`
  margin-left: auto;
  margin-right: auto;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;
  background: #fff;
  color: #000;
  margin-top: 20px;
  position: relative;
  z-index: 1;

  .light & {
    color: #fff;
    background-color: #1b2327;
  }
`;
