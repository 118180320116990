import styled from 'styled-components';
import BoxContainer from 'src/components/boxContainer/BoxContainer';
import PageBoxWrapper from 'src/components/pageBoxWrapper/PageBoxWrapper';
import { mediaQuery } from 'src/config/deviceMediaQueries';

export const Root = styled(BoxContainer)`
  background: #e1edff;
`;

export const Column = styled.div`
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;

  &:before {
    content: '';
    background-image: url('/img/project-management-background.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right 0px;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 100%;
    opacity: 0.1;
  }

  @media ${mediaQuery.tablet} {
    align-items: flex-start;

    &:before {
      width: 45%;
      opacity: 1;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;

  @media ${mediaQuery.tablet} {
    flex-direction: row;
    text-align: left;
  }
`;

export const ImgWrapper = styled.div`
  padding-top: 30px;
  & .first-image {
    margin-right: 15px;
  }
`;

export const TitleWrapper = styled.div`
  color: #3461b6;

  @media ${mediaQuery.tablet} {
    justify-content: flex-start;
    width: 56%;
  }

  @media ${mediaQuery.desktop} {
    width: 50%;
  }
`;

export const TextWrapper = styled.div`
  margin-top: 30px;
  color: #3461b6;
  font-size: 20px;

  @media ${mediaQuery.tablet} {
    justify-content: flex-start;
    width: 50%;
  }
`;

export const ContentWrapper = styled(PageBoxWrapper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
