import Link from 'gatsby-link';
import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { IParsedBlogPostListItemData } from 'src/types/blog.types';
import BlogRollImage from './components/blogRollImage/BlogRollImage';

interface IProps {
  posts: IParsedBlogPostListItemData[];
}

const Root = styled.div`
  display: grid;
  column-gap: 64px;
  row-gap: 50px;
  width: 100%;
  grid-template-columns: 1fr;

  @media ${mediaQuery.smallScreen} {
    row-gap: 98px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  @media ${mediaQuery.desktop} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    box-sizing: border-box;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
`;

const PostItem = styled.article`
  border-radius: 8px;
  .grow {
    transition: transform 0.5s;
  }
  .grow svg {
    transition: transform 0.75s, 0.75s filter;
  }
  &:hover .grow {
    transform: scale(1.1);
  }
  &:hover .grow svg {
    filter: drop-shadow(3px 4px 6px rgb(0 0 0 / 0.3));

    transform: scale(1.5);
  }
`;

const PostTitle = styled.div`
  width: 100%;
  margin-top: 6px;
  font-size: 20px;
  font-weight: 700;

  .light & {
    color: #1b2327;
  }

  .visimetrix & {
    color: #fff;
  }

  .switchedonfibre & {
    color: #fff;
  }
`;

const PostDate = styled.div`
  margin-top: 15px;
  font-size: 10px;
  color: #dddedf;
  font-weight: 500;

  @media ${mediaQuery.tablet} {
    margin-top: 27px;
  }

  .light & {
    color: #494f52;
  }

  .visimetrix & {
    color: #dddedf;
  }

  .switchedonfibre & {
    color: #dddedf;
  }
`;

const ReadingTime = styled.div`
  margin-top: 8px;
  font-size: 10px;
  color: #dddedf;
  font-weight: 500;

  .light & {
    color: #494f52;
  }

  .visimetrix & {
    color: #dddedf;
  }

  .switchedonfibre & {
    color: #dddedf;
  }
`;

function BlogRoll(props: IProps) {
  const { posts } = props;

  return (
    <Root>
      {posts &&
        posts.map(({ id, title, slug, date, readingTime, iconType }: IParsedBlogPostListItemData) => (
          <Link key={id} to={`${slug}`} state={{ canGoBack: true }}>
            <PostItem title="Open post">
              <BlogRollImage iconType={iconType} className="grow" />
              <PostDate>Posted on {date}</PostDate>
              <PostTitle>{title}</PostTitle>
              <ReadingTime>
                READING TIME: {readingTime} {readingTime === 1 ? 'minute' : 'minutes'}
              </ReadingTime>
            </PostItem>
          </Link>
        ))}
    </Root>
  );
}

export default BlogRoll;
