import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import Icon from './img/search.svg';
import IconLight from './img/search_light.svg';

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SearchIcon = styled(Icon)`
  position: absolute;
  right: 14px;
  z-index: 1;
`;

export const SearchIconLight = styled(IconLight)`
  position: absolute;
  right: 14px;
  z-index: 1;
`;

export const Input = styled.input`
  width: 200px;
  background-color: #262e32;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  padding: 12px;
  color: white;
  padding-right: 40px;

  &.blog-search {
    background-color: ${Colors.ui.greyDark};
    @media ${mediaQuery.phone} {
      width: 100%;
    }
  }

  .light & {
    background-color: #fff;
    color: #494f52;

    &.blog-search {
      background-color: ${Colors.light.bg.input};
    }
    &::placeholder {
      color: #494f52;
    }
  }

  &::placeholder {
    color: #767b7d;
    opacity: 1;
  }
`;
