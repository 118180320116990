import axios from 'axios';
import React, { useCallback } from 'react';
import type { IJobApplyData } from 'src/types/job.types';

interface IState {
  loading: boolean;
  error?: string;
  completed: boolean;
}

const initialState: IState = {
  loading: false,
  error: '',
  completed: false,
};

const sendRequest = async (shortcode: string, data: IJobApplyData, setState: (state: IState) => void) => {
  try {
    setState({ loading: true, completed: false });

    await axios.post(`/.netlify/functions/job-apply/job-apply?shortcode=${shortcode}`, data);

    setState({ loading: false, completed: true });
  } catch (error) {
    setState({ loading: false, completed: false, error: 'Cannot send application' });
  }
};

export const useJob = (shortcode: string): [IState, (data: IJobApplyData) => Promise<void>] => {
  const [state, setState] = React.useState<IState>(initialState);
  const sendApplyRequest = useCallback(
    async (data: IJobApplyData) => await sendRequest(shortcode, data, setState),
    [shortcode],
  );

  return [state, sendApplyRequest];
};
