import Link from 'gatsby-link';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import UIStore from 'src/store/Store';
import Layout from '../components/layout/Layout';
import NotFoundIconLight from './img/not-found-icon-light.svg';
import NotFoundIcon from './img/not-found-icon.svg';

const Title = styled.h1`
  margin-bottom: 0px;
  font-size: 72px;
  font-weight: 700;

  .light & {
    color: #1b2327;
  }
`;

const SubTitle = styled.h4`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;

  .light & {
    color: #1b2327;
  }
`;

const Button = styled.button`
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;
  background: #fff;

  .light & {
    background: #1b2327;
    color: #fff;
  }
`;

const IconWrapper = styled.div`
  padding-top: 40px;
`;

const BodyContent = styled.div`
  margin-bottom: 60px;
  .light & {
    color: #1b2327;
  }
`;

const NotFoundPage = () => {
  const isDarkMode = UIStore.useState(s => s.isDarkMode);
  return (
    <Layout>
      <Helmet>
        <title>Error 404 - Page age not found.</title>
        <meta property="og:title" content="Error 404 - Page age not found" />
        <meta name="description" content="Sorry, this page isn't what it used to be." />
        <meta property="og:description" content="Sorry, this page isn't what it used to be." />
      </Helmet>
      <PageContentWide>
        <IconWrapper>{isDarkMode ? <NotFoundIcon /> : <NotFoundIconLight />}</IconWrapper>
        <BodyContent>
          <Title>Error 404.</Title>
          <SubTitle>Page not found.</SubTitle>
          <p>Sorry, this page isn't what it used to be.</p>
        </BodyContent>
        <Link to="/" aria-label="go-to-homepage" role="link">
          <Button>Go to homepage</Button>
        </Link>
      </PageContentWide>
    </Layout>
  );
};

export default NotFoundPage;
