import { graphql } from 'gatsby';
import React from 'react';
import ContactForm from 'src/components/contactForm/ContactForm';
import Layout from 'src/components/layout/Layout';
import PageContentSectionsWrapper from 'src/components/pageContentSectionsWrapper/PageContentSectionsWrapper';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import FindUsBox from './components/findUsBox/FindUsBox';

interface IProps {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        description: string;
      };
    };
  };
}

const GetInTouchPage = ({ data }: IProps) => {
  const { title, description } = data.markdownRemark.frontmatter;

  const formName = 'Get in touch contact form';

  return (
    <Layout getInTouch={false}>
      <PageContentWide>
        <PageContentSectionsWrapper>
          <ContactForm isMessageField={true} formName={formName} title={title} description={description} />
          <FindUsBox />
        </PageContentSectionsWrapper>
      </PageContentWide>
    </Layout>
  );
};

export default GetInTouchPage;

export const pageQuery = graphql`
  query GetInTouchPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
      }
    }
  }
`;
