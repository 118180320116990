import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

export const FlowList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const Image = styled('div')`
  width: 300px;
  flex-shrink: 0;
  height: 240px;
  position: relative;
  & svg {
    position: absolute;
    @media ${mediaQuery.phone} {
      max-width: 100%;
      left: 0 !important;
      right: 0 !important;
    }
  }
`;
const Root = styled('div')`
  width: 100%;
  display: flex;
  gap: 20px;

  flex-direction: column-reverse;
  align-items: center;
  @media ${mediaQuery.navbarCollapse} {
    flex-direction: row;
    justify-content: space-between;
    gap: 80px;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
`;
const Body = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Title = styled('h3')`
  letter-spacing: -2px;
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;

  @media ${mediaQuery.navbarCollapse} {
    font-size: 34px;
    line-height: 36px;
  }
`;
const Text = styled.p`
  letter-spacing: -0.5px;
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.blue['+80']};
`;
export const FlowItem = { Root, Image, Title, Text, Body };
