import styled from 'styled-components';
import { Colors } from 'src/theme/colors';

export const StyledCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d363b;
  color: #fff;
  border-radius: 8px;
  flex-direction: column;

  .light & {
    background-color: ${Colors.ui.componentLight};
    color: ${Colors.ui.titleTextLight};
  }
`;

export default StyledCard;
