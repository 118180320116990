import React from 'react';
import { Colors } from 'src/theme/colors';
import PageBox from '../pageBox/PageBox';
import { S } from './givingBackBox.styled';
import GivingBackIcon from './img/giving-back.svg';

const GivingBackBox = () => (
  <PageBox backgroundColor={Colors.red[90]}>
    <>
      <S.Container>
        <S.DescriptionCol>
          <S.StyledBoxSubTitle>
            Giving back -<br></br> tech in education
          </S.StyledBoxSubTitle>
          <S.Description>
            We believe it’s important to contribute to the community, and while we pursue lots of initiatives every
            year, our primary focus is on the adoption of technology in education. The following are two initiatives
            that we heavily support:
          </S.Description>
          <S.Description>
            <b>Kinia</b> - we helped found this amazing not-for-profit business that promotes education using creative
            technologies, with a focus on less-advantaged communities. Kinia has to date trained over 16,000 educators
            and impacted the learning of 200,000 young people.
          </S.Description>
          <S.Description>
            <b>Gatoto School</b> - we built an extensive computer lab for this school in the Mukuru slum in Nairobi,
            Kenya, and we are supporting the advancement of digital learning techniques within the school’s curriculum
            to help set an example of what’s possible.
          </S.Description>
        </S.DescriptionCol>
        <S.LogoCol>
          <GivingBackIcon
            title="Heart-shaped illustration featuring multiple people interacting with each other"
            css={{ width: '100%' }}
          />
        </S.LogoCol>
      </S.Container>
    </>
  </PageBox>
);

export default GivingBackBox;
