import { Link } from 'gatsby';
import React, { useLayoutEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Input from 'src/components/input/Input';
import InputLabel from 'src/components/inputLabel/InputLabel';
import { InputOptionalLabel } from 'src/components/inputOptionalLabel/InputOptionalLabel.styled';
import TermsCheckbox from 'src/components/termsCheckbox/TermsCheckbox';
import Textarea from 'src/components/textarea/Textarea';
import ValidationMessage from 'src/components/validationMessage/ValidationMessage.styled';
import FileInput from 'src/modules/careersJobPage/components/fileInput/FileInput';
import type { IJobApplyData, IResumeData } from 'src/types/job.types';
import { useJob } from '../../hooks/useJobApply/useJobApply';
import convertToBase64 from '../../services/convertToBase64/convertToBase64';
import {
  Root,
  Header,
  Description,
  Row,
  TermsCheckboxWrapper,
  SuccessHeader,
  SuccessMessage,
  SuccessContainer,
  Button,
} from './applyForm.styled';

interface IProps {
  shortcode: string;
}

interface IFormInputData extends Omit<IJobApplyData, 'resume'> {
  resumeFile?: File;
  terms?: boolean;
}

const getResumeData = async (file: File): Promise<IResumeData> => {
  const fileBase64: string | void = await convertToBase64(file);
  const fileName: string = file.name;

  return {
    name: fileName,
    data: fileBase64,
  };
};

const isFileSizeValid = (file: File) => file.size <= 12000000;

const isFileTypeValid = (file: File) =>
  [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/rtf',
  ].includes(file.type);

const validateFile = (file: File) => {
  if (!isFileSizeValid(file)) {
    return 'This file is too large';
  }
  if (!isFileTypeValid(file)) {
    return 'Invalid file extension';
  }
  return true;
};

const ApplyForm = ({ shortcode }: IProps) => {
  const [applyState, sendApplyRequest] = useJob(shortcode);
  const methods = useForm<IFormInputData>({ mode: 'onBlur' });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitSuccessful },
    watch,
  } = methods;

  const termsChecked = watch('terms', false);
  const onSubmit = async (data: IFormInputData) => {
    let resumeData: IResumeData | null = null;

    if (data.resumeFile) {
      resumeData = await getResumeData(data.resumeFile);
    }

    delete data.resumeFile;
    delete data.terms;

    const formData: IJobApplyData = {
      ...data,
      ...(resumeData && { resume: resumeData }),
    };

    await sendApplyRequest(formData);
  };

  useLayoutEffect(() => {
    if (applyState.completed) {
      document.getElementById('application-header')?.scrollIntoView();
    }
  }, [applyState.completed]);

  return (
    <Root id="apply-form">
      <Header id="application-header">Application</Header>
      <Description>
        <p>We know nobody loves uploading their resume and then typing in all that information again.</p>
        <p>So we’re going to make this simple and painless!</p>
      </Description>
      {!isSubmitSuccessful && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <InputLabel htmlFor="firstname">First name</InputLabel>
              <Input
                name="firstname"
                register={register}
                id="firstname"
                placeholder="First name"
                rules={{ required: true }}
                disabled={applyState.loading}
              />
              {errors.firstname && <ValidationMessage>This field is required</ValidationMessage>}
            </Row>
            <Row>
              <InputLabel htmlFor="lastname">Last name</InputLabel>
              <Input
                name="lastname"
                register={register}
                id="lastname"
                placeholder="Last name"
                rules={{ required: true }}
                disabled={applyState.loading}
              />
              {errors.lastname && <ValidationMessage>This field is required</ValidationMessage>}
            </Row>
            <Row>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                name="email"
                register={register}
                id="email"
                placeholder="Email"
                inputMode="email"
                rules={{
                  required: 'This field is required',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Please provide a properly formatted email address',
                  },
                }}
                disabled={applyState.loading}
              />
              {errors.email && <ValidationMessage>{errors.email.message}</ValidationMessage>}
            </Row>
            <Row>
              <InputLabel htmlFor="resumeFile">Resume</InputLabel>
              <FileInput
                name="resumeFile"
                id="resumeFile"
                disabled={applyState.loading}
                rules={{
                  required: 'This field is required',
                  validate: validateFile,
                }}
              />
              {errors.resumeFile && <ValidationMessage>{errors.resumeFile.message}</ValidationMessage>}
            </Row>
            <Row>
              <InputLabel htmlFor="coverLetter">
                <span>Message</span>
                <InputOptionalLabel>(Optional)</InputOptionalLabel>
              </InputLabel>
              <Textarea
                placeholder="Start writing"
                rows={6}
                id="coverLetter"
                name="cover_letter"
                register={register}
                disabled={applyState.loading}
              />
            </Row>
            <TermsCheckboxWrapper>
              <TermsCheckbox onChange={value => setValue('terms', value)} register={register} checked={termsChecked} />
              {errors.terms && <ValidationMessage>You need to accept the Privacy Policy</ValidationMessage>}
            </TermsCheckboxWrapper>
            <Button type="submit" disabled={applyState.loading}>
              Submit application
            </Button>
          </form>
        </FormProvider>
      )}

      {isSubmitSuccessful && (
        <SuccessContainer>
          <SuccessHeader>Thank you!</SuccessHeader>
          <SuccessMessage>
            We have received your application for this position and our recruiting team will get in touch.
          </SuccessMessage>
          <Link to="/careers">
            <Button>See all open roles</Button>
          </Link>
        </SuccessContainer>
      )}
    </Root>
  );
};

export default ApplyForm;
