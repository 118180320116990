import React from 'react';
import BoxSubTitle from 'src/components/boxSubTitle/BoxSubTitle';
import { S } from './CompanyInnovationBox.styled';
import Innovation_Logo from './img/Innovation_Logo.svg';

const CompanyInnovationBox = () => (
  <S.StyledPageBox>
    <S.LogosCol>
      <Innovation_Logo title="Stylised illustration of a lightbulb" style={{ height: '100%', display: 'block' }} />
    </S.LogosCol>
    <S.DescriptionCol>
      <BoxSubTitle>Innovation</BoxSubTitle>
      <S.Description>
        In the software space, tooling, frameworks, systems, and ways of working are constantly evolving. Staying
        abreast of and contributing to that evolution excites us.
      </S.Description>

      <S.Description>
        We invest to bring innovation mindsets, expertise and assets to our customer engagements.
      </S.Description>

      <S.Description>
        Our research and development activities are focused on emerging software technologies, engineering processes and
        tooling, in addition to our in-house{' '}
        <S.DescriptionLink to="/telecom-solutions">telco solution developments.</S.DescriptionLink>
      </S.Description>

      <S.Description>
        Projects range from comparative technology evaluations, making contributions to open source software we love, as
        well as open sourcing some software of our own.
      </S.Description>
    </S.DescriptionCol>
  </S.StyledPageBox>
);

export default CompanyInnovationBox;
