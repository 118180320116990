import React from 'react';
import { Helmet } from 'react-helmet';
import VisimetrixPageHeader from 'src/components/visimetrix/visimetrixPageHeader/VisimetrixPageHeader';
import VisimetrixPageLayout from 'src/components/visimetrix/visimetrixPageLayout/VisimetrixPageLayout';
import InfoSection from './sections/infoSection/InfoSection';

const VisimetrixEasyIntegration = () => {
  const pageTitle = 'VisiMetrix - Easy integration with multi-vendor systems';
  const pageDescription =
    'Collate data from disparate systems for end-to-end monitoring of network service performance.';
  return (
    <VisimetrixPageLayout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
      </Helmet>
      <VisimetrixPageHeader
        title="Easy integration"
        subtitle="Collate data from disparate systems to get better insights into your network service performance."
        isSubPage={true}
      />
      <InfoSection />
    </VisimetrixPageLayout>
  );
};

export default VisimetrixEasyIntegration;
