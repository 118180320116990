import React from 'react';
import { S } from './NocCard.styled';

interface IProps {
  index: number;
  title: string;
  description: string;
  link: string;
}

const NocCard = ({ index, title, description, link }: IProps) => (
  <li key="0">
    <S.Index>{index}</S.Index>
    <S.Title>{title}</S.Title>
    <S.Description>{description}</S.Description>

    <S.ReadMoreButton to={link} state={{ canGoBack: true }}>
      Read more
    </S.ReadMoreButton>
  </li>
);

export default NocCard;
