import Link from 'gatsby-link';
import styled from 'styled-components';
import { MainCarousel } from 'src/components/boxCarousel/MainCarousel.styled';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { boxPaddingHorizontalDesktop, boxPaddingVerticalDesktop, boxPaddingMobile } from 'src/theme/dimensions';

export const Root = styled(MainCarousel)``;

export const Column = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  &:nth-of-type(1) {
    padding: ${boxPaddingMobile};
    align-items: flex-start;
    order: 2;
    padding-bottom: 80px;

    @media ${mediaQuery.tablet} {
      order: 0;

      padding: ${boxPaddingVerticalDesktop} ${boxPaddingMobile} calc(${boxPaddingVerticalDesktop} + 80px);
    }

    @media ${mediaQuery.desktop} {
      padding: ${boxPaddingVerticalDesktop} ${boxPaddingHorizontalDesktop} calc(${boxPaddingVerticalDesktop} + 80px);
    }
  }

  @media ${mediaQuery.tablet} {
    align-items: flex-start;
    width: 50%;
    height: 100%;
  }
`;

export const Row = styled.div<{ bg: string }>`
  background-color: ${({ bg = 0 }) => bg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media ${mediaQuery.tablet} {
    flex-direction: row;
  }
`;

export const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 15px;

  @media ${mediaQuery.tablet} {
    margin-bottom: 62px;
  }
`;

export const Title = styled.h2`
  font-size: 34px;
  font-weight: 700;
  text-align: left;
  margin: 8px 0;
`;

export const DimmedLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  opacity: 0.8;
  text-align: left;
`;

export const ButtonWrapper = styled.div`
  margin-top: 40px;
`;

export const FeaturedBlogImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 200px;
  border-right-radius: 8px;
  background: rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  & svg {
    width: 50%;
    height: 50%;
  }
`;

export const Button = styled(Link)`
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;
  background: #fff;
  color: #000;
  margin-top: 20px;
  position: relative;
  z-index: 1;

  .visimetrix &,
  .switchedonfibre & {
    background-color: #263c59;
  }

  @media ${mediaQuery.tablet} {
    margin-top: 40px;
  }
`;
