import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { S } from './LeadToCashSection.styled';
import FlowItem from './components/flowItem/FlowItem';
import SimpleFlowItem from './components/simpleFlowItem/SimpleFlowItem';
import InstallSvg from './img/install.svg';
import OperateSvg from './img/operate.svg';
import OrderSvg from './img/order.svg';
import QuoteSvg from './img/quote.svg';

const flowList = [
  {
    title: 'Lead',
    subTitle: 'Quickly acquire new customers',
    description:
      'Simply enter a potential customer’s address into our portal to check fibre broadband eligibility and availability within seconds. Collect valuable demand and availability data to provide insights for your marketing and network planning teams.',
    ItemImage: <StaticImage src="img/lead.png" alt="SwitchedOn Fibre's address validation screen" />,
  },
  {
    title: 'Quote',
    subTitle: 'Instant quotes for all your products',
    description:
      'Get real time prices for products when someone qualifies for fibre broadband services – no waiting, no hassle. Tailor our flexible platform for B2B or B2C customers and configure the product catalogue with all your offerings. Expand your range of products with aggregated services from off-net providers.',
    ItemImage: <QuoteSvg title="SwitchedOn Fibre's product offerings screen" />,
  },
  {
    title: 'Order',
    subTitle: 'Optimise order management',
    description:
      'Deliver a seamless user experience with our optimised order capture process, which tracks and communicates updates, so everyone knows the status of an order. It supports promotions, cross-sell and upsell activities through its flexible product catalogue and discount framework.',
    ItemImage: <OrderSvg title="SwitchedOn Fibre's order managment update screen" />,
  },
  {
    title: 'Provision',
    subTitle: 'Boost your efficiency with integration',
    description:
      'Manage provisioning and installations with our open system, which ensures order flows are integrated with your field workforce management and network systems. Installations are automatically scheduled to avoid unnecessary delays. Conduct reviews using our analytics capabilities.',
    ItemImage: <InstallSvg title="SwitchedOn Fibre's installation appointment screen" />,
  },
  {
    title: 'Bill and care',
    subTitle: 'Streamline billing and service management',
    description:
      'Generate wholesale and retail invoices with our flexible billing system, or integrate with your own system to support existing processes. Our in-life service management capabilities support subscriber management, ticketing, and diagnostics. The platform offers useful customer insights and is designed to scale with your business as you grow.',
    ItemImage: <OperateSvg title="SwitchedOn Fibre's subscription information screen" />,
  },
];

const LeadToCashSection = () => (
  <section>
    <S.IntroductionSegment>
      <S.DescriptionContainer>
        <S.Header>
          <S.PrimaryHeaderRow>Customer flows:</S.PrimaryHeaderRow>
          <S.SecondaryHeaderRow>lead&#x2011;to&#x2011;cash | trouble&#x2011;to&#x2011;resolve</S.SecondaryHeaderRow>
        </S.Header>
        <S.Description>
          SwitchedOn Fibre™ handles everything from address validation through to account maintenance. It takes care of
          qualification, order management, installation, billing, ticketing, in-life service management and more with a
          minimum of fuss.
        </S.Description>
      </S.DescriptionContainer>

      <S.SimpleFlowList>
        {flowList.map((item, i) => (
          <SimpleFlowItem key={item.title} index={i + 1} title={item.title} />
        ))}
      </S.SimpleFlowList>
    </S.IntroductionSegment>

    <S.FlowList>
      {flowList.map((item, i) => (
        <FlowItem key={item.title} index={i + 1} {...item} />
      ))}
    </S.FlowList>
  </section>
);

export default LeadToCashSection;
