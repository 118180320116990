import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { ulMarginLeft } from 'src/theme/dimensions';

export const ContentWrapper = styled.section`
  margin: 0 auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  color: #dddedf;

  img {
    display: block;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden;
  }
  & ul {
    margin-left: ${ulMarginLeft};
  }
  & ol {
    margin-left: ${ulMarginLeft};
  }

  h2 {
    font-size: 40px;
    font-weight: 700;
    margin-top: 48px;
    letter-spacing: -2px;
    color: #fff;

    .light & {
      color: ${Colors.ui.titleTextLight};
    }
  }

  blockquote {
    border-left: solid 3px #fff;
    padding-left: 20px;
    font-style: italic;
    font-weight: 700;
  }

  a {
    color: white;
    text-decoration: underline;
  }

  .light & {
    color: ${Colors.ui.bodyTextLight};
  }
`;

export const Title = styled.h1`
  font-size: 40px;
  line-height: 36px;
  font-weight: 900;
  letter-spacing: -3px;
  margin: 16px 0 32px 0;

  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;

export const GoBackLink = styled.button`
  border: none;
  background-color: #262e32;
  padding: 0;
  display: block;
  margin: 60px 0;

  .light & {
    background-color: #fff;
  }
`;

export const CoverImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px auto 40px;
  width: 100%;
  max-width: 1080px;

  img {
    border-radius: 16px;
  }

  @media ${mediaQuery.desktop} {
    margin: 130px auto 60px;
  }
`;
