import Link from 'gatsby-link';
import * as React from 'react';
import { parseCategoryTag } from 'src/services/tags/tags';
import { Root, TagButton, CaseCategoriesPanelLinksWrapper } from './CaseCategoriesPanel.styled';

interface IProps {
  availableCategories: string[];
  currentCategory?: string;
}

const CaseCategoriesPanel = ({ availableCategories, currentCategory }: IProps) => {
  return (
    <Root>
      <CaseCategoriesPanelLinksWrapper>
        <Link to="/case-studies">
          <TagButton $isHighlighted={!currentCategory}>All</TagButton>
        </Link>
        {availableCategories?.map((categoryName: string, index: number) => (
          <Link key={index} to={`/case-studies?category=${categoryName}`}>
            <TagButton $isHighlighted={currentCategory === categoryName}>
              {parseCategoryTag(categoryName)} cases
            </TagButton>
          </Link>
        ))}
      </CaseCategoriesPanelLinksWrapper>
    </Root>
  );
};

export default CaseCategoriesPanel;
