import React, { ReactNode, useCallback, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { deviceSize } from 'src/config/deviceMediaQueries';
import { useWindowSize } from 'src/modules/indexPage/hooks/useWindowSize';
import ExpandLess from './img/expand_less.svg';
import ExpandMore from './img/expand_more.svg';
import { ServiceContainer, ServiceContent, ServiceTitle, ExpandButton, ScrollTopButton } from './service.styles';
import './transition.css';

const useScrollTo = () => {
  const innerRef = React.useRef<null | HTMLDivElement>();

  const scrollToRef = useCallback(() => {
    innerRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return { ref: innerRef, scrollToRef };
};
interface ServiceProps {
  icon: ReactNode;
  title: string;
  columns: string[][];
  tag: string;
}
export const Service = ({ icon, title, columns, tag }: ServiceProps) => {
  const [open, setOpen] = useState(false);
  const view = useWindowSize();
  const collapse = view.width ? view.width <= deviceSize.navbarCollapse : false;
  const { ref, scrollToRef } = useScrollTo();

  return (
    <ServiceContainer id={tag}>
      <ServiceTitle ref={node => (ref.current = node)}>
        {icon}
        {title}
      </ServiceTitle>
      <TransitionGroup mode="out-in">
        <CSSTransition key={!collapse || open ? 'open' : 'collapse'} timeout={500} classNames="expand">
          {!collapse || open ? (
            <ServiceContent>
              {columns.map((column, index) => (
                <div key={index}>
                  {column.map((text, index) => (
                    <p key={index}>{text}</p>
                  ))}
                </div>
              ))}
            </ServiceContent>
          ) : (
            <ServiceContent>
              <p>{columns[0][0]}</p>
            </ServiceContent>
          )}
        </CSSTransition>
      </TransitionGroup>
      <ExpandButton
        onClick={() => {
          setOpen(prev => !prev);
          scrollToRef();
        }}>
        {open ? (
          <>
            read less
            <ExpandLess className="fill-white light:fill-black" />
          </>
        ) : (
          <>
            read more
            <ExpandMore className="fill-white light:fill-black" />
          </>
        )}
      </ExpandButton>
      <ScrollTopButton href="#services">
        top <ExpandLess className="fill-white light:fill-black" />
      </ScrollTopButton>
    </ServiceContainer>
  );
};
