import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

const Item = styled.div`
  display: flex;
  flex-direction: column-reverse;
  row-gap: 40px;
  column-gap: 20px;

  @media ${mediaQuery.smallScreen} {
    flex-direction: ${({ $tabletOrientation }: { $tabletOrientation: 'left' | 'right' | 'bottom' }) => {
      switch ($tabletOrientation) {
        case 'left':
          return 'row';
        case 'right':
          return 'row-reverse';
        case 'bottom':
          return 'column-reverse';
      }
    }};
  }
`;

const TextContainer = styled.div`
  flex: 1;
`;

const ImageContainer = styled.div`
  flex: 1;

  & > div {
    border-radius: 8px;
  }
`;

const Title = styled.h2`
  font-size: 34px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -2px;
  color: ${Colors.white};
  margin-bottom: 24px;

  @media ${mediaQuery.phone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: ${Colors.ui.teal[80]};
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const S = {
  Item,
  TextContainer,
  ImageContainer,
  Title,
  Description,
};
