import styled from 'styled-components';
import { Colors } from 'src/theme/colors';

export const ServicePanel = styled.div`
  background: #1b2327;
  .light & {
    background-color: ${Colors.ui.componentLight};
    color: ${Colors.ui.bodyTextLight};
  }
  border-radius: 10px;
  margin-top: 34px;
`;

export const ServiceWrapper = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const ServiceSubTitle = styled.h3`
  font-size: 36px;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const ServiceDescription = styled.div`
  margin-top: 8px;
  padding-right: 10px;
  font-weight: 300;
  font-size: 20px;
  line-height: 34px;
`;

export const IconWrapper = styled.div`
  margin-top: 10px;
  &.force-width svg {
    width: 114px;
  }
`;
