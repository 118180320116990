import Link from 'gatsby-link';
import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

const Index = styled.span`
  display: block;
  width: 52px;
  font-size: 34px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid ${Colors.white};
  margin-bottom: 24px;
`;

const Title = styled.h3`
  font-size: 34px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -2px;

  @media ${mediaQuery.phone} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  color: ${Colors.ui.teal[80]};
`;

const ReadMoreButton = styled(Link)`
  background-color: transparent;
  color: ${Colors.ui.teal[80]};
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.5px;
  padding-left: 0;
  text-decoration: underline;
`;

export const S = { Index, Title, Description, ReadMoreButton };
