import React from 'react';
import { TestimonialsSwiper } from 'src/components/testimonials-swiper/testimonials-swiper';
import { Container } from './WhatWeDoTestimonialsBox.styled';

const testimonials = [
  {
    name: 'Jasson Casey',
    jobTitle: 'CTO, Beyond Identity',
    text: 'The Sonalake team made it really easy for us to scale up our CEE engineering operations. They got our vision, understood our needs, and selected super engineers for us who have grown seamlessly into the Beyond Identity ways of working, becoming part of our core team and culture.',
    personImageUrl: '/testimonial/beyond-identity.svg',
    isCompanyLogo: false,
  },
  {
    name: 'Paul Cash',
    jobTitle: 'CTO, Truata',
    text: 'We have been delighted with the support of Sonalake, providing product design and development support to help us scale our engineering capacity and get products to market with speed and quality.',
    personImageUrl: '/testimonial/truata.svg',
    isCompanyLogo: false,
  },
  {
    name: 'Clodagh NicCanna',
    jobTitle: 'Development Director, Ocuco',
    text: 'Sonalake’s peer-to-peer consulting culture is a refreshing approach to software partnering, adding the objectivity of a 3rd party with appropriate skills and new perspectives to the internal domain and software expertise of Ocuco.',
    personImageUrl: '/testimonial/ocuco.svg',
    isCompanyLogo: false,
  },
];

const WhatWeDoTestimonialsBox = () => {
  return (
    <Container>
      <TestimonialsSwiper testimonials={testimonials} slides={1} />
    </Container>
  );
};

export default WhatWeDoTestimonialsBox;
