import Link from 'gatsby-link';
import styled from 'styled-components';
import PageBox from 'src/components/pageBox/PageBox';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { Typography } from 'src/theme/typography';

const StyledPageBox = styled(PageBox)`
  position: relative;
  display: flex;
  row-gap: 64px;
  flex-direction: column;

  @media ${mediaQuery.smallScreen} {
    flex-direction: row;
  }
`;

const DescriptionCol = styled.div`
  flex-direction: column;
  display: flex;

  row-gap: 16px;
  z-index: 1;
  @media ${mediaQuery.smallScreen} {
    width: 50%;
  }
`;

const Description = styled.p`
  ${Typography.body[16]};
  margin-bottom: 0;
  color: ${Colors.white};

  .light & {
    color: ${Colors.grey[40]};
  }
`;

const DescriptionLink = styled(Link)`
  text-decoration: underline;
  color: ${Colors.white};

  .light & {
    color: ${Colors.grey[40]};
  }
`;

const LogosCol = styled.div`
  position: absolute;
  height: 100%;
  bottom: 0;
  right: 0;

  svg {
    width: 100%;
    max-height: 100% !important;
    display: block;
    opacity: 0.2;
    @media ${mediaQuery.smallScreen} {
      opacity: 1;
    }
  }
`;

export const S = {
  StyledPageBox,
  DescriptionCol,
  Description,
  DescriptionLink,
  LogosCol,
};
