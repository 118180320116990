import Link from 'gatsby-link';
import React from 'react';
import BlogPostBanner from 'src/components/blogPostBanner/BlogPostBanner';
import { IAuthorCaptionData } from 'src/types/blog.types';

interface IProps {
  post: {
    slug: string;
    date: string;
    readingTime: string;
    title: string;
    iconType: string;
    authorCaptionData: IAuthorCaptionData;
  };
}

const BlogPostSection: React.FC<IProps> = ({ post }) => (
  <section>
    <Link to={post.slug} state={{ canGoBack: true }}>
      <BlogPostBanner {...post} theme="blog" />
    </Link>
  </section>
);

export default BlogPostSection;
