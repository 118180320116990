import styled from 'styled-components';
import BoxSubTitle from 'src/components/boxSubTitle/BoxSubTitle';
import PageBox from 'src/components/pageBox/PageBox';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { Typography } from 'src/theme/typography';

const StyledPageBox = styled(PageBox)`
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    background: url('/img/about-page-company-values-box-background.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.1;
  }

  @media ${mediaQuery.desktop} {
    &:before {
      opacity: 1;
      width: 50%;
      height: 80%;
      right: 0;
      bottom: 0;
    }
  }
`;

const ContentContainer = styled.div`
  width: 100%;

  @media ${mediaQuery.desktop} {
    width: 50%;
  }
`;

const StyledSubTitle = styled(BoxSubTitle)`
  color: ${Colors.teal[40]};
  .light & {
    color: ${Colors.teal[40]};
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
`;

const Description = styled.p`
  ${Typography.body[16]};
  color: ${Colors.teal[40]};
  margin: 0;
`;

export const S = {
  StyledPageBox,
  ContentContainer,
  StyledSubTitle,
  DescriptionContainer,
  Description,
};
