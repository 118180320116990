import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';

const PageContentSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media ${mediaQuery.tablet} {
    gap: 64px;
  }
`;

export default PageContentSectionsWrapper;
