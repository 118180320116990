import styled from 'styled-components';
import { InputOptionalLabel } from 'src/components/inputOptionalLabel/InputOptionalLabel.styled';
import PageBoxWrapper from 'src/components/pageBoxWrapper/PageBoxWrapper';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import Bg from './img/bg.svg';

const Root = styled(PageBoxWrapper)`
  position: relative;
  background-color: ${Colors.pivots.ocean800};
  border-radius: 16px;
`;

const Background = styled(Bg)`
  opacity: 0.5;
  position: absolute;
  left: 0;
  bottom: 0;

  @media ${mediaQuery.smallScreen} {
    opacity: 1;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  & > * {
    flex: 1;
  }

  @media ${mediaQuery.smallScreen} {
    flex-direction: row;
    gap: 64px;
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -2px;
  margin-bottom: 0px;
`;

const InputWrapper = styled.div`
  position: relative;

  input {
    background-color: ${Colors.pivots.ocean850};
    border: none;

    &::placeholder {
      color: ${Colors.pivots.ocean400};
    }
  }
`;

const ContactInputOptionalLabel = styled(InputOptionalLabel)`
  color: ${Colors.white};
`;

const Button = styled.button`
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;
  background-color: ${Colors.white};
  color: ${Colors.black};

  &:hover {
    background: #dadada;
  }
`;

const TermsCheckboxWrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

export const S = {
  Root,
  FormContainer,
  FormContent,
  Background,
  Title,
  InputWrapper,
  ContactInputOptionalLabel,
  Button,
  TermsCheckboxWrapper,
};
