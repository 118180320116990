import { graphql } from 'gatsby';
import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import BackButton from 'src/components/backButton/BackButton';
import { HTMLContent } from 'src/components/content/Content';
import GoBackLink from 'src/components/goBackLink/GoBackLink.styled';
import Layout from 'src/components/layout/Layout';
import PageContentNarrow from 'src/components/pageContentNarrow/PageContentNarrow';
import { StyleMixins } from 'src/theme/style-mixins';

interface IProps {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
      };
      html: string;
    };
  };
}

const Wrapper = styled.div`
  color: var(--textPrimary);
`;

const Content = styled.div`
  padding: 0 20px;
  margin: auto;

  .light & {
    color: #494f52;
  }

  a {
    ${StyleMixins.TextLink};
    .light & {
      ${StyleMixins.LightTextLink};
    }
  }

  h2,
  h3 {
    padding-bottom: 0px;
    margin-bottom: 0px;
    .light & {
      color: #1b2327;
    }
  }

  h2 {
    font-size: 28px;
    letter-spacing: 0.5px;
  }

  h3 {
    font-size: 1em;
    line-height: 1.15;
  }
`;

const Title = styled.h1`
  margin-bottom: 35px;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 1px;
  .light & {
    color: #1b2327;
  }
`;

const PrivacyPolicyPage = ({ data }: IProps) => {
  const {
    frontmatter: { title },
    html,
  } = data.markdownRemark;

  return (
    <Layout>
      <Wrapper>
        <PageContentNarrow>
          <GoBackLink
            to="/"
            onClick={event => {
              event.preventDefault();
              navigate(-1);
            }}>
            <BackButton />
          </GoBackLink>
          <Content>
            <Title>{title}</Title>
            <HTMLContent content={html} />
          </Content>
        </PageContentNarrow>
      </Wrapper>
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const pageQuery = graphql`
  query PrivacyPolicyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
