import Link from 'gatsby-link';
import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';

export const ApplyNowLink = styled(Link)`
  display: inline-block;
  margin: 30px 0;

  @media ${mediaQuery.desktop} {
    margin: 80px 0;
  }
`;

export const GoBackLink = styled.button`
  display: block;
  background-color: unset;
  border: none;
  margin: 140px 0 44px 0;
`;

export const Button = styled(Link)`
  display: block;
  margin: 140px 0 44px 0;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;
  background: #fff;
  color: #000;
  position: relative;
  z-index: 1;

  .visimetrix &,
  .switchedonfibre & {
    background-color: #263c59;
  }

  @media ${mediaQuery.tablet} {
    margin-top: 40px;
  }

  .light & {
    background-color: #1b2327;
    color: #fff;
  }
`;
