import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #1b2327;
  border-radius: 16px;

  @media ${mediaQuery.desktop} {
    padding: 80px 109px;
    gap: 32px;
  }

  .light & {
    background-color: ${Colors.ui.componentLight};
    color: ${Colors.ui.titleTextLight};
  }
`;

export const Addresses = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media ${mediaQuery.desktop} {
    flex-direction: row;
    gap: 70px;
  }
`;
