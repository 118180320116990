import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { boxPaddingMobile, pagePaddingDesktop } from 'src/theme/dimensions';

const Section = styled.section`
  padding-top: ${boxPaddingMobile};
  width: 100%;

  @media ${mediaQuery.smallScreen} {
    width: 80%;
    padding-top: ${pagePaddingDesktop};
  }

  @media ${mediaQuery.desktop} {
    width: 60%;
    padding-top: ${pagePaddingDesktop};
  }
`;

const Brand = styled.p`
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -2px;
`;

const GoBackLink = styled.button`
  display: block;
  margin-bottom: 45px;
  background-color: unset;
  border: none;
`;

const PageTitle = styled.h1`
  font-size: 72px;
  font-weight: 800;
  line-height: 64px;
  letter-spacing: -4px;
  margin-top: 0;
  margin-bottom: 32px;

  @media ${mediaQuery.phone} {
    font-size: 40px;
    line-height: 36px;
  }
`;

const SubTitle = styled.h2`
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -1px;
  margin-bottom: 0px;

  @media ${mediaQuery.smallScreen} {
    width: ${({ $isNarrow }: { $isNarrow: boolean }) => $isNarrow && '66%'};
  }
`;

export const S = {
  Section,
  GoBackLink,
  Brand,
  PageTitle,
  SubTitle,
};
