import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

export const ServiceContainer = styled.div`
  background: #1b2327;
  border-radius: 16px;
  padding: 24px;
  @media ${mediaQuery.tablet} {
    padding: 64px 92px;
  }
  .light & {
    background-color: ${Colors.ui.componentLight};
    color: ${Colors.ui.greyDark};
  }
`;
export const ServiceTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 24px;
  line-height: 28px;
  scroll-margin-top: 80px;

  @media ${mediaQuery.tablet} {
    font-size: 40px;
    line-height: 40px;
  }
  & svg {
    height: 48px;
    width: 48px;
    padding: 8px;
  }
`;
export const ServiceContent = styled.section`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media ${mediaQuery.navbarCollapse} {
    flex-direction: row;
  }
  div {
    break-inside: avoid;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  p {
    margin: 12px;
    font-size: 16px;
    line-height: 20px;
  }
`;
export const ExpandButton = styled.button`
  margin-top: 16px;
  border: none;
  background: transparent;
  color: white;
  display: flex;
  align-items: center;
  @media ${mediaQuery.navbarCollapse} {
    display: none;
  }
  .light & {
    color: black;
  }
`;

export const ScrollTopButton = styled.a`
  margin-top: 16px;
  float: right;
  border: none;
  background: transparent;
  color: white;
  .light & {
    color: black;
  }
  display: none;
  @media ${mediaQuery.navbarCollapse} {
    display: flex;
  }
  align-items: center;
`;
