import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { ImageOrSVG } from 'src/components/ImageOrSvg/ImageOrSvg';
import Layout from 'src/components/layout/Layout';
import PageContentSectionsWrapper from 'src/components/pageContentSectionsWrapper/PageContentSectionsWrapper';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import PageDescription from 'src/components/pageDescription/PageDescription';
import PageHeading from 'src/components/pageHeading/PageHeading';
import PageSubtitle from 'src/components/pageSubtitle/PageSubtitle';
import PageTitle from 'src/components/pageTitle/PageTitle';
import { Tag } from 'src/components/tag/Tag';
import { parseCategoryTag } from 'src/services/tags/tags';
import { ICaseStudyListItemData, IParsedCaseStudyListItemData } from 'src/types/caseStudies.types';
import {
  CaseStudiesCategoriesPanelWrapper,
  CaseStudiesCard,
  CardContentWrapper,
  CardCoverImageWrapper,
  CaseStudiesList,
  CardContentDescription,
} from './CaseStudies.styled';
import CaseCategoriesPanel from './components/caseCategoriesPanel/CaseCategoriesPanel';

interface IProps {
  data: {
    caseStudiesPageData: {
      frontmatter: {
        pageTitle: string;
        title: string;
        subtitle: string;
        pageDescription: string;
        description: string;
        availableCategories: string[];
      };
    };
    caseStudiesData: {
      caseStudiesList: ICaseStudyListItemData[];
    };
  };
  category?: string;
}

function flattenCaseStudiesData(caseStudiesData: ICaseStudyListItemData[]): IParsedCaseStudyListItemData[] {
  return caseStudiesData.map(({ caseItem }: ICaseStudyListItemData): IParsedCaseStudyListItemData => {
    return {
      id: caseItem.id,
      slug: caseItem.fields.slug,
      ...caseItem.frontmatter,
    };
  });
}

const CaseStudiesPage = ({ data }: IProps) => {
  const {
    caseStudiesPageData: {
      frontmatter: { pageTitle, title, subtitle, pageDescription, description, availableCategories },
    },
    caseStudiesData: { caseStudiesList },
  } = data;
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [category, setCategory] = useState<string | undefined>();
  useEffect(() => {
    setCategory(urlSearchParams.get('category') || undefined);
  }, [urlSearchParams]);

  const caseStudies = flattenCaseStudiesData(caseStudiesList)
    .filter(cs => !category || cs.category === category)
    .sort((a, b) => (a.order <= b.order ? -1 : 1));

  return (
    <Layout>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
      </Helmet>
      <PageContentWide>
        <PageContentSectionsWrapper>
          <PageHeading>
            <PageTitle>{title}</PageTitle>
            <PageSubtitle>{subtitle}</PageSubtitle>
            <PageDescription>{description}</PageDescription>
          </PageHeading>
          <CaseStudiesCategoriesPanelWrapper>
            <CaseCategoriesPanel availableCategories={availableCategories} currentCategory={category} />
          </CaseStudiesCategoriesPanelWrapper>
          <CaseStudiesList>
            {caseStudies.map(item => {
              return (
                <Link key={item.id} to={item.slug}>
                  <CaseStudiesCard>
                    <CardContentWrapper>
                      {item.companyLogoImage && (
                        <div>
                          <ImageOrSVG
                            childImageSharp={item.companyLogoImage.childImageSharp}
                            extension={item.companyLogoImage.extension}
                            alt={`Open ${item.company} case study`}
                            className="logo"
                            publicURL={item.companyLogoImage.publicURL}
                          />
                        </div>
                      )}

                      <CardContentDescription>{item.description}</CardContentDescription>
                      <div>
                        {item.tags?.map(parseCategoryTag).map((tag, index) => (
                          <Tag key={index}>{tag.toLowerCase()}</Tag>
                        ))}
                      </div>
                    </CardContentWrapper>

                    {item.coverImage && (
                      <CardCoverImageWrapper>
                        <GatsbyImage image={item.coverImage.childImageSharp.gatsbyImageData} alt={item.coverImageAlt} />
                      </CardCoverImageWrapper>
                    )}
                  </CaseStudiesCard>
                </Link>
              );
            })}
          </CaseStudiesList>
        </PageContentSectionsWrapper>
      </PageContentWide>
    </Layout>
  );
};

export default CaseStudiesPage;

export const pageQuery = graphql`
  query CaseStudiesPage($id: String!) {
    caseStudiesPageData: markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        title
        pageDescription
        subtitle
        description
        availableCategories
        company
        coverImageAlt
      }
    }
    caseStudiesData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "caseStudy" } } }) {
      caseStudiesList: edges {
        caseItem: node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            category
            order
            company
            coverImageAlt
            companyLogoImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
            coverImage {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
