module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/config/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-code-buttons/gatsby-browser.js'),
      options: {"plugins":[],"buttonClass":"gatsby-remark-code-button","svgIcon":"<svg width=\"13\" height=\"16\" viewBox=\"0 0 13 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M11 0H4C3.46957 0 2.96086 0.210714 2.58579 0.585786C2.21071 0.960859 2 1.46957 2 2C1.46957 2 0.960859 2.21071 0.585786 \n2.58579C0.210714 2.96086 0 3.46957 0 4V14C0 14.5304 0.210714 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H9C9.53043 \n16 10.0391 15.7893 10.4142 15.4142C10.7893 15.0391 11 14.5304 11 14C11.5304 14 12.0391 13.7893 12.4142 13.4142C12.7893 13.0391 13 \n12.5304 13 12V2C13 1.46957 12.7893 0.960859 12.4142 0.585786C12.0391 0.210714 11.5304 0 11 0V0ZM11 13V4C11 3.46957 10.7893 2.96086 \n10.4142 2.58579C10.0391 2.21071 9.53043 2 9 2H3C3 1.73478 3.10536 1.48043 3.29289 1.29289C3.48043 1.10536 3.73478 1 4 1H11C11.2652 1 \n11.5196 1.10536 11.7071 1.29289C11.8946 1.48043 12 1.73478 12 2V12C12 12.2652 11.8946 12.5196 11.7071 12.7071C11.5196 12.8946 11.2652 \n13 11 13ZM1 4C1 3.73478 1.10536 3.48043 1.29289 3.29289C1.48043 3.10536 1.73478 3 2 3H9C9.26522 3 9.51957 3.10536 9.70711 3.29289C9.89464 \n3.48043 10 3.73478 10 4V14C10 14.2652 9.89464 14.5196 9.70711 14.7071C9.51957 14.8946 9.26522 15 9 15H2C1.73478 15 1.48043 14.8946 1.29289 \n14.7071C1.10536 14.5196 1 14.2652 1 14V4Z\" fill=\"#1B2327\"/>\n</svg>"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
