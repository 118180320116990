import styled from 'styled-components';
import { Colors } from '../../theme/colors';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 20px;
  line-height: 24px;
  cursor: pointer;

  span {
    white-space: nowrap;
  }
`;
export const AddressTitle = styled.h6`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  font-weight: 700;
  margin-bottom: 0px;
  color: ${Colors.blue.main};
`;

export const AddressLine = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  margin-bottom: 0px;
  color: ${Colors.ui.bodyText};

  .light & {
    color: ${Colors.ui.bodyTextLight};
  }
`;

export const DirectionsLink = styled.a`
  display: flex;
`;

export const DirectionsText = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  color: ${Colors.white};

  .light & {
    color: ${Colors.ui.greyDark};
  }
`;
