import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Root, JobDescription } from './jobPreview.styled';

const JobPreview = ({ description }: { description: string }) => {
  return (
    <Root>
      <JobDescription>{ReactHtmlParser(description)}</JobDescription>
    </Root>
  );
};

export default JobPreview;
