import React from 'react';
import { Root, JobTitle } from './JobListItem.styled';

interface IProps {
  title: string;
}

const JobListItem = ({ title }: IProps) => (
  <Root>
    <JobTitle>{title}</JobTitle>
  </Root>
);

export default JobListItem;
