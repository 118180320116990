import React, { useState, MouseEventHandler, MouseEvent } from 'react';
import { useDropzone } from 'react-dropzone';
import { RegisterOptions, UseFormRegister, Path } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import { Root, ActionLabel, Description, UploadedFileInfo, UploadLabel } from './FileInput.styled';

interface IProps<TFormValues> {
  id: string;
  register?: UseFormRegister<TFormValues>;
  name: Path<TFormValues>;
  disabled?: boolean;
  rules?: RegisterOptions;
}

const UploadFileDescription = () => {
  return (
    <Description>
      <UploadLabel>Upload a file or drag and drop here.</UploadLabel>
      <br />
      <div>Accepted formats: .pdf, .doc, .docx, .rtf</div>
      <br />
      Max file size: 12MB
    </Description>
  );
};

const Dropzone = ({
  id,
  selectedFileName,
  disabled,
  onDrop,
  removeFile,
}: {
  id: string;
  selectedFileName: string;
  disabled?: boolean;
  onDrop: (files: File[]) => void;
  removeFile: MouseEventHandler<HTMLSpanElement>;
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop,
  });

  return (
    <Root {...getRootProps()} highlighted={isDragActive} data-testid="dropzone-component">
      <input
        id={id}
        style={{ border: 'solid 10px red' }}
        {...getInputProps({ onDrop } as { onDrop: () => void })}
        disabled={disabled}
      />
      {selectedFileName ? (
        <UploadedFileInfo>
          {selectedFileName} <ActionLabel onClick={removeFile}>Remove</ActionLabel>
        </UploadedFileInfo>
      ) : (
        <UploadFileDescription />
      )}
    </Root>
  );
};

const FileInput = <TFormValues extends Record<string, unknown>>({ name, id, rules, disabled }: IProps<TFormValues>) => {
  const { control } = useFormContext();
  const [fileName, setFileName] = useState('');
  const removeFile = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();

    setFileName('');
  };

  return (
    <Controller
      rules={rules}
      render={({ field: { onChange } }) => (
        <Dropzone
          removeFile={(event: MouseEvent<HTMLSpanElement>) => {
            removeFile(event);
            onChange(null);
          }}
          selectedFileName={fileName}
          id={id}
          disabled={disabled}
          onDrop={(files: File[]) => {
            setFileName(files[0].name);
            onChange(files[0]);
          }}
        />
      )}
      name={name}
      control={control}
    />
  );
};

export default FileInput;
