import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React from 'react';
import BackButton from '../../backButton/BackButton';
import { S } from './VisimetrixPageHeader.styled';

interface IProps {
  title: string;
  subtitle: string;
  isSubPage?: boolean;
}

const VisimetrixPageHeader: React.FC<IProps> = ({ title, subtitle, isSubPage = false }) => {
  const { state } = useLocation() as { state: { canGoBack?: boolean } | null };

  return (
    <S.Section>
      <S.GoBackLink
        onClick={() =>
          state?.canGoBack ? navigate(-1) : navigate(isSubPage ? '/telecom-solutions/visimetrix' : '/telecom-solutions')
        }>
        <BackButton />
      </S.GoBackLink>
      {isSubPage && <S.Brand>VisiMetrix</S.Brand>}
      <S.PageTitle>{title}</S.PageTitle>
      <S.SubTitle $isNarrow={isSubPage}>{subtitle}</S.SubTitle>
    </S.Section>
  );
};

export default VisimetrixPageHeader;
