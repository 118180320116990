import GoogleMapReact, { Coords } from 'google-map-react';
import React, { useCallback, useState } from 'react';
import { Address } from 'src/components/address/Address';
import BoxTitle from 'src/components/boxTitle/BoxTitle';
import { deviceSize } from 'src/config/deviceMediaQueries';
import FindUsMap from '../findUsMap/FindUsMap';
import { addressList } from '../findUsMap/config';
import { Root, Addresses } from './findUsBox.styled';

const isBrowser = () => typeof window !== 'undefined';
const isDesktop: boolean = isBrowser()
  ? window.matchMedia?.(`only screen and (min-width: ${deviceSize.desktop}px)`).matches
  : false;

export interface IMapState {
  zoom: number;
  center: GoogleMapReact.Coords;
}

const FindUsBox = () => {
  const [mapState, setMapState] = useState<IMapState>({
    zoom: isDesktop ? 4.8 : 3.2,
    center: {
      lat: 50.475306,
      lng: 6.357625,
    },
  });

  const handleMapStateChange = useCallback(
    (state: Partial<IMapState>) => setMapState(currentState => ({ ...currentState, ...state })),
    [],
  );

  const handleMarkerClick = useCallback((center: Coords) => {
    setMapState({ zoom: 10, center });
  }, []);

  return (
    <Root>
      <BoxTitle>Where to find us</BoxTitle>
      <FindUsMap mapState={mapState} onMapStateChange={handleMapStateChange} onMarkerClick={handleMarkerClick} />
      <Addresses>
        {addressList.map(data => (
          <Address key={data.title} {...data} onClick={() => handleMarkerClick(data.coords)} />
        ))}
      </Addresses>
    </Root>
  );
};

export default FindUsBox;
