import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { ImageOrSVG } from 'src/components/ImageOrSvg/ImageOrSvg';
import { Tag } from 'src/components/tag/Tag';
import { parseCategoryTag } from 'src/services/tags/tags';
import { IParsedCaseStudyListItemData } from 'src/types/caseStudies.types';
import { S } from './CaseStudyCard.styled';

interface IProps {
  caseStudy: IParsedCaseStudyListItemData;
}

const CaseStudyCard = ({ caseStudy }: IProps) => {
  return (
    <S.CardLink to={caseStudy.slug}>
      <S.DescriptionSegment>
        {caseStudy.companyLogoImage && (
          <ImageOrSVG
            childImageSharp={caseStudy.companyLogoImage.childImageSharp}
            extension={caseStudy.companyLogoImage.extension}
            publicURL={caseStudy.companyLogoImage?.publicURL}
            alt={`${caseStudy.company} Logo`}
            className="logo"
            style={{ marginBottom: '28px', height: '50px' }}
          />
        )}

        <S.Description>{caseStudy.description}</S.Description>
        <div>
          {caseStudy.tags?.map(parseCategoryTag).map((tag, index) => (
            <Tag key={index}>{tag.toLowerCase()}</Tag>
          ))}
        </div>
      </S.DescriptionSegment>

      {caseStudy.coverImage && (
        <GatsbyImage
          image={caseStudy.coverImage.childImageSharp.gatsbyImageData}
          objectFit="cover"
          objectPosition="center"
          alt={caseStudy.coverImageAlt}
          style={{ height: '222.5px' }}
        />
      )}
    </S.CardLink>
  );
};

export default CaseStudyCard;
