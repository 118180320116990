import React from 'react';
import { S } from './StrengthCard.styled';

interface IProps {
  title: string;
  description: string;
  SideImage: React.ReactElement;
}

const StrengthCard = ({ title, description, SideImage }: IProps) => {
  return (
    <S.Card>
      <S.TextCol>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.TextCol>
      <S.ImgCol>{SideImage}</S.ImgCol>
    </S.Card>
  );
};

export default StrengthCard;
