import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { CSSProperties } from 'react';
import { IImageSharp } from 'src/types/gatsby.types';

interface ImagePropsBase {
  childImageSharp: IImageSharp;

  alt: string;
  className?: string;
  style?: CSSProperties;
}
type ImageProps = ImagePropsBase &
  (
    | {
        extension: string;
        publicURL: string;
      }
    | {
        extension?: never;
        publicURL?: never;
      }
  );
export const ImageOrSVG = ({ childImageSharp, extension, alt, className, style, publicURL }: ImageProps) => {
  return childImageSharp && extension !== 'svg' ? (
    <GatsbyImage image={childImageSharp.gatsbyImageData} alt={alt} className={className} style={style} />
  ) : (
    <img src={publicURL} alt={alt} className={className} style={style} />
  );
};
