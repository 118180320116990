import React, { ReactNode } from 'react';
import { S } from './PageBox.styled';

interface IProps {
  backgroundColor?: string;
  children: ReactNode;
  className?: string;
}

const PageBox = ({ backgroundColor, className, children }: IProps) => (
  <S.Container className={className} style={{ backgroundColor }}>
    {children}
  </S.Container>
);

export default PageBox;
