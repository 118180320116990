import Link from 'gatsby-link';
import styled from 'styled-components';
import BoxContainer from 'src/components/boxContainer/BoxContainer';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingMobile } from 'src/theme/dimensions';
import { BoxTitle } from '../boxTitle/BoxTitle';

export const Root = styled(BoxContainer)`
  display: flex;
  width: 100%;
  text-align: center;
  overflow: hidden;
  position: relative;
  &.visimetrix-root {
    background: linear-gradient(#1299a7, #1299a7);
  }
  &.visimetrix-root::before {
    content: ' ';

    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url('/img/switched-visimetrix.svg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.5;
  }
  &.fibre-root {
    background: linear-gradient(#2368d7, #4e8ff6);
  }
  &.fibre-root::before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url('/img/switched-on-fibre.svg');
    background-size: contain;
    background-position: right bottom;
    background-repeat: no-repeat;
    opacity: 0.5;
  }

  &.billing-root {
    background: linear-gradient(${Colors.purple['-50']}, ${Colors.purple['-60']});
  }
  &.billing-root::before {
    content: ' ';

    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url('/backgrounds/switched-on-billing-pattern.svg');
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
    opacity: 0.5;
  }

  &.pivots-root {
    background-color: ${Colors.pivots.ocean500};
  }

  &.pivots-root::before {
    content: ' ';

    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url('/backgrounds/pivots-bg.png');
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat;
    opacity: 0.5;
  }

  @media ${mediaQuery.tablet} {
    flex-direction: row;
    text-align: left;
  }
`;

export const Container = styled.div`
  padding: 30px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  .light & {
    background-color: ${Colors.ui.componentLight};
    color: ${Colors.ui.titleTextLight};
  }

  background-color: ${({ $theme }: { $theme: string }) => {
    switch ($theme) {
      case 'switchedonfibre':
        return Colors.switchedOnFibre.bg.blue60;
      case 'visimetrix':
        return Colors.ui.teal[50];
      default:
        return '#1b2327';
    }
  }};
`;
export const Content = styled.div`
  display: grid;
  align-items: stretch;
  justify-content: stretch;
  gap: 24px;
  grid-template-columns: 1fr;

  @media ${mediaQuery.smallScreen} {
    grid-template-columns: 1fr 1fr;
  }
`;
export const ButtonWrapper = styled.div`
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const TitleWrapper = styled.div`
  font-weight: 900;
  padding: 0;
  color: #fff;
  & p {
    opacity: 0.5;
    font-size: 11.8px;
    margin-bottom: 8px;
  }
`;

export const TextWrapper = styled.div`
  margin-top: 30px;
  color: #fff;
  flex-grow: 1;

  &.visimetrix-text {
    margin-top: 60px;
  }
`;

export const BoxContentWrapper = styled.div`
  padding: ${boxPaddingMobile};
  box-sizing: content-box;
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 1;

  @media ${mediaQuery.tablet} {
    width: 50%;
  }
  @media ${mediaQuery.smallScreen} {
    width: 100%;
  }
`;

export const Button = styled(Link)`
  display: block;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;
  background: #fff;
  color: #000;

  .visimetrix &,
  .switchedonfibre & {
    background-color: #263c59;
  }
`;

export const SectionTitle = styled(BoxTitle)`
  letter-spacing: -1.5px;
  font-weight: 800;
  font-size: 36px;
  line-height: 36px;

  @media ${mediaQuery.navbarCollapse} {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.5px;
  }
  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;
