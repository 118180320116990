import React from 'react';
import { getBlogIcon } from 'src/services/blog/blogIcon';
import { IAuthorCaptionData } from 'src/types/blog.types';
import { S } from './BlogPostBanner.styled';

interface IProps {
  date: string;
  readingTime: string;
  title: string;
  authorCaptionData?: IAuthorCaptionData;
  iconType: string;
  theme?: 'blog' | 'visimetrix';
}

const BlogPostBanner: React.FC<IProps> = ({
  iconType,
  date,
  readingTime,
  title,
  authorCaptionData,
  theme = 'visimetrix',
}) => {
  const { Icon, background } = getBlogIcon(iconType);
  return (
    <S.Banner style={theme === 'blog' ? { background } : undefined}>
      <S.IconContainer theme={theme}>
        <Icon />
      </S.IconContainer>
      <div>
        <S.StyledPublishedInfo date={date} readingTime={readingTime} theme={theme}></S.StyledPublishedInfo>
        <S.TitleAndAuthorContainer>
          <S.Title>{title}</S.Title>
          {authorCaptionData && <S.StyledAuthorCaption data={authorCaptionData} theme={theme} />}
        </S.TitleAndAuthorContainer>
      </div>
    </S.Banner>
  );
};

export default BlogPostBanner;
