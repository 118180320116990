import React from 'react';
import AgileTeamsIconSVG from 'src/assets/icons/icon_agileteams.svg';
import DataAnalyticsIconSVG from 'src/assets/icons/icon_data_analytics.svg';
import PlatformEngineeringIconSVG from 'src/assets/icons/icon_platformengineering.svg';
import ProductDesDevIconSVG from 'src/assets/icons/icon_productdesdev.svg';
import BoxItem from 'src/components/boxItem/BoxItem';
import ServicesBox from 'src/components/servicesBox/ServicesBox';

const MainPageServicesBox = () => {
  return (
    <ServicesBox title="Our services">
      <BoxItem
        icon={<ProductDesDevIconSVG className="fill-white light:fill-black" />}
        className="force-width"
        title="Product design & development"
        descriptions={[
          'From problem discovery, research and design to build and support, we deliver user friendly, extensible and scalable software applications.',
        ]}
      />
      <BoxItem
        icon={<AgileTeamsIconSVG className="fill-white light:fill-black" />}
        title="Agile teams"
        descriptions={[
          'Are you introducing new technologies, managing skill shortages, migrating to cloud or ramping up agile adoption? Our Team Augmentation is here to help.',
        ]}
      />
      <BoxItem
        icon={<DataAnalyticsIconSVG className="fill-white light:fill-black" />}
        title="Data & analytics"
        descriptions={[
          'We engineer data pipelines, platforms and applications, using visualisation and data science techniques to bring your data to life.',
        ]}
      />
      <BoxItem
        icon={<PlatformEngineeringIconSVG className="fill-white light:fill-black" />}
        title="Platform engineering"
        descriptions={[
          'Evolving from DevOps, we provision cloud infrastructure with toolchains to streamline the path to production.',
        ]}
      />
    </ServicesBox>
  );
};

export default MainPageServicesBox;
