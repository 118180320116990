import styled from 'styled-components';

const Section = styled.section`
  width: 80%;
  margin: auto;
`;

export const S = {
  Section,
};
