import { graphql } from 'gatsby';
import React from 'react';
import { Typewriter } from 'react-simple-typewriter';
import Layout from 'src/components/layout/Layout';
import PageContentSectionsWrapper from 'src/components/pageContentSectionsWrapper/PageContentSectionsWrapper';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import PageDescription from 'src/components/pageDescription/PageDescription';
import PageHeading from 'src/components/pageHeading/PageHeading';
import TypewriterPageTitle from 'src/components/typewriterPageTitle/TypewriterPageTitle';
import VisimetrixSwitchedOn from 'src/components/visimetrixSwitchedOnFibreBox/VisimetrixSwitchedOnFibreBox';
import { flattenBlogPostsData } from 'src/services/blog/blog';
import colors from 'src/theme/colors';
import { IBlogPostListItemData } from 'src/types/blog.types';
import CaseStudiesBox from '../../components/caseStudiesBox/CaseStudiesBox';
import { LinkButtonWrapper, Button, TypewriterCursor } from './IndexPage.styled';
import IntroductionBox from './components/introductionBox/IntroductionBox';
import LatestPostsBox from './components/latestPostsBox/LatestPostsBox';
import MainPageServicesBox from './components/mainPageServicesBox/MainPageServicesBox';
import MainPageTestimonials from './components/mainPageTestimonialCarousel/MainPageTestimonials';
import { useTypewritterHeight } from './hooks/useTypewritterHeight';

interface IProps {
  data: {
    markdownRemark: {
      frontmatter: {
        typewriterBeforeText: string;
        typewriterTexts: string[];
        description: string;
      };
    };
    blogPostsData: {
      blogPostsList: IBlogPostListItemData[];
    };
  };
}

const IndexPage = ({ data }: IProps) => {
  const { typewriterBeforeText, typewriterTexts, description } = data.markdownRemark.frontmatter;
  const {
    blogPostsData: { blogPostsList },
  } = data;
  const latestPosts = flattenBlogPostsData(blogPostsList);
  const { ref, height } = useTypewritterHeight(typewriterBeforeText, typewriterTexts);

  return (
    <Layout>
      <PageContentWide>
        <PageContentSectionsWrapper>
          <PageHeading>
            <TypewriterPageTitle ref={ref} style={{ minHeight: height }}>
              {typewriterBeforeText}{' '}
              <span style={{ color: colors.textSecondary }}>
                <Typewriter words={typewriterTexts} loop={0} cursor={false} typeSpeed={100} delaySpeed={2000} />
                <TypewriterCursor>|</TypewriterCursor>
              </span>
            </TypewriterPageTitle>
            <PageDescription>{description}</PageDescription>
            <LinkButtonWrapper>
              <Button title="Get in touch" role="button" to="/get-in-touch" className="light">
                Get in touch
              </Button>
            </LinkButtonWrapper>
          </PageHeading>
          <IntroductionBox />
          <MainPageServicesBox />
          <CaseStudiesBox />
          <VisimetrixSwitchedOn />
          <MainPageTestimonials />
          <LatestPostsBox posts={latestPosts} />
        </PageContentSectionsWrapper>
      </PageContentWide>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        typewriterBeforeText
        typewriterTexts
        description
      }
    }
    blogPostsData: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blogPost" } } }
      limit: 3
    ) {
      blogPostsList: edges {
        postItem: node {
          id
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            title
            description
            templateKey
            categories
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            creator
            iconType
          }
        }
      }
    }
  }
`;
