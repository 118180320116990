import Link from 'gatsby-link';
import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

export const Root = styled.div`
  padding: 80px 0;

  a {
    text-decoration: none;
    color: unset;
  }
`;

export const JobsListTitle = styled.h2`
  margin: 23px 0;
  letter-spacing: -1.5px;
  font-weight: 800;

  .light & {
    color: ${Colors.ui.titleTextLight};
  }

  @media ${mediaQuery.tablet} {
    font-size: 40px;
  }

  @media ${mediaQuery.navbarCollapse} {
    font-size: 36px;
  }
`;

export const JobsListSubTitle = styled.h2`
  color: #dddedf;
  margin-bottom: 80px;

  .light & {
    color: ${Colors.ui.bodyTextLight};
  }
`;

export const ListWrapper = styled.ul`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  column-gap: 24px;
  row-gap: 24px;
  margin: 70px 0 0;
  list-style-type: none;

  @media ${mediaQuery.tablet} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
`;

export const ListItem = styled.li`
  margin-bottom: 0;
`;

export const ListItemLink = styled(Link)`
  a {
    text-decoration: none;
    color: unset;
  }
`;

export const Tag = styled.button`
  display: inline-flex;
  background: ${({ isHighlighted }: { isHighlighted: boolean }) => (isHighlighted ? 'white' : '#262E32')};
  color: ${({ isHighlighted }: { isHighlighted: boolean }) => (isHighlighted ? '#262E32' : 'white')};
  border-radius: 8px;
  padding: 14px 16px;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  margin-right: 16px;
  border: none;

  &:first-of-type {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
  }

  @media ${mediaQuery.phone} {
    margin-bottom: 10px;
  }

  .light && {
    background: ${({ isHighlighted }: { isHighlighted: boolean }) =>
      isHighlighted ? Colors.ui.titleTextLight : '#fff'};
    color: ${({ isHighlighted }: { isHighlighted: boolean }) => (isHighlighted ? '#fff' : Colors.ui.bodyTextLight)};
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;

  @media ${mediaQuery.desktop} {
    flex-direction: row;
  }
`;
