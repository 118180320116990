import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';

export const TypewriterPageTitle = styled.h1`
  font-family: Inter;
  font-size: 40px;
  font-weight: 800;
  letter-spacing: -3px;
  margin: 0;
  white-space: break-spaces;

  .light & {
    color: #1b2327;
  }

  @media ${mediaQuery.tablet} {
    font-size: 72px;
    letter-spacing: -6px;
    max-width: 80%;
  }
`;

export default TypewriterPageTitle;
