import styled from 'styled-components';
import { Colors } from 'src/theme/colors';

const Item = styled.li`
  position: relative;
  display: flex;
  list-style-type: none;
  align-items: center;
  margin-bottom: 8px;

  &:after {
    content: '';
    background: ${Colors.switchedOnFibre.bg.blue20};
    position: absolute;
    bottom: -8px;
    left: 28px;
    height: 8px;
    width: 2px;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }
`;

const Index = styled.span`
  display: block;
  font-size: 34px;
  width: 52px;
  line-height: 52px;
  font-weight: 700;
  text-align: center;
  margin-right: 27px;
  border-radius: 50%;
  border: 2px solid #629eff;
  color: ${Colors.white};
`;

const Title = styled.h4`
  font-size: 34px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -2px;
  color: ${Colors.white};
`;

export const S = {
  Item,
  Index,
  Title,
};
