import React from 'react';
import BoxText from 'src/components/boxText/BoxText';
import { ServiceDescription, ServiceWrapper, IconWrapper, ServiceSubTitle, ServicePanel } from './BoxItem.styled';

interface IProps {
  title: string;
  descriptions: string[];
  icon: JSX.Element;
  className?: string;
}

const BoxItem = ({ title, descriptions, icon, className }: IProps) => {
  return (
    <ServicePanel>
      <ServiceWrapper>
        <IconWrapper className={className}>{icon}</IconWrapper>
        <ServiceSubTitle>{title}</ServiceSubTitle>
        <ServiceDescription>
          {descriptions.map((description, index) => (
            <BoxText key={index}>{description}</BoxText>
          ))}
        </ServiceDescription>
      </ServiceWrapper>
    </ServicePanel>
  );
};

export default BoxItem;
