import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { Typography } from 'src/theme/typography';

const Description = styled.p`
  ${Typography.body[16]};
  color: ${Colors.switchedOnFibre.text.blue80};
  margin-bottom: 0;

  @media ${mediaQuery.tablet} {
    max-width: 60%;
  }
`;

export const S = {
  Description,
};
