import Link from 'gatsby-link';
import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingMobile, pagePaddingDesktop } from 'src/theme/dimensions';

export const LinkButtonWrapper = styled.div`
  margin-top: 30px;
`;

export const JobsListWrapper = styled.div`
  background-color: #1b2327;
  padding-bottom: 80px;
  margin-top: 64px;

  .light & {
    background-color: ${Colors.ui.componentLight};
  }
`;

export const Button = styled(Link)`
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;
  background: #fff;
  color: #000;
  position: relative;
  z-index: 1;

  .light & {
    background-color: ${Colors.ui.titleTextLight};
    color: #fff;
  }

  .visimetrix &,
  .switchedonfibre & {
    background-color: #263c59;
  }

  @media ${mediaQuery.tablet} {
  }
`;
export const PageHeading = styled.section`
  display: flex;
  flex-direction: column;

  @media ${mediaQuery.navbarCollapse} {
    flex-direction: row;
    gap: 32px;
  }
  padding-top: ${boxPaddingMobile};

  @media ${mediaQuery.desktop} {
    padding-top: ${pagePaddingDesktop};
  }
`;

export const HeadingImage = styled.div`
  font-size: 40px;
  font-weight: 800;
  margin-top: 0.85em;

  @media ${mediaQuery.tablet} {
    font-size: 72px;
    max-width: 90%;
    line-height: 72px;
  }

  & img {
    border-radius: 8px;
    margin-bottom: 0;
  }
`;
export const HeadingContent = styled.div`
  display: flex;
  flex-direction: column;

  gap: 16px;
  @media ${mediaQuery.navbarCollapse} {
    min-width: 550px;
  }
`;

export const PageTitle = styled.h1`
  font-size: 40px;
  font-weight: 800;
  letter-spacing: -3px;
  margin-bottom: 0;

  @media ${mediaQuery.tablet} {
    font-size: 72px;
    letter-spacing: -6px;
    max-width: 90%;
    line-height: 72px;
  }

  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;
