import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { pagePaddingDesktop, pagePaddingMobile } from 'src/theme/dimensions';

const PageContentNarrow = styled.div`
  max-width: 712px;
  margin: 0 auto;
  padding: 0 ${pagePaddingMobile};
  box-sizing: content-box;

  @media ${mediaQuery.desktop} {
    padding: 0 ${pagePaddingDesktop};
  }
`;

export default PageContentNarrow;
