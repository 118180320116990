import styled from 'styled-components';
import { InputOptionalLabel } from 'src/components/inputOptionalLabel/InputOptionalLabel.styled';
import TermsCheckbox from 'src/components/termsCheckbox/TermsCheckbox';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { boxPaddingHorizontalDesktop, boxPaddingMobile, boxPaddingVerticalDesktop } from 'src/theme/dimensions';
import { Typography } from 'src/theme/typography';
import WavyLines from './WavyLines.svg';

export const Background = styled(WavyLines)`
  position: absolute;
  height: 100%;
  bottom: 10px;
  right: 0;
  scale: 1.2;
  opacity: 0.25;
  color: ${Colors.purple['+40']} !important;
  @media ${mediaQuery.navbarCollapse} {
    opacity: 0.75;
  }
`;

const Section = styled.section`
  padding: ${boxPaddingMobile};
  background-color: ${Colors.purple['-50']};
  position: relative;
  border-radius: 16px;
  overflow: hidden;

  @media ${mediaQuery.navbarCollapse} {
    padding: ${boxPaddingVerticalDesktop} ${boxPaddingHorizontalDesktop};
  }
`;

const Title = styled.h2`
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -2px;
  text-align: left;
  margin-bottom: 40px;
`;

const Description = styled.p`
  color: ${Colors.purple['+60']};
  ${Typography.body[16]};
  margin-bottom: 56px;

  @media ${mediaQuery.desktop} {
    max-width: 60%;
  }
`;

const FormSegment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${mediaQuery.desktop} {
    flex-direction: row;
  }
`;

const FormDescriptionContainer = styled.div`
  flex: 1;
`;

const FormDescriptionTitle = styled.h3`
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -2px;
  text-align: left;
  margin-bottom: 40px;
`;

const FormDescriptionList = styled.ul`
  color: ${Colors.switchedOnFibre.text.blue60};
  list-style-type: none;
`;

const FormDescriptionItem = styled.li`
  ${Typography.body[16]};
  margin-bottom: 24px;
`;

const FormContainer = styled.div`
  flex: 1;
`;

const Form = styled.form`
  margin-bottom: 0px;
`;

const FormInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 30px;
  @media ${mediaQuery.navbarCollapse} {
    width: 50%;
  }
`;

const StyledInputOptionalLabel = styled(InputOptionalLabel)`
  color: ${Colors.purple['+60']};
`;

const StyledTermsCheckbox = styled(TermsCheckbox)`
  @media ${mediaQuery.navbarCollapse} {
    max-width: 50%;
  }
  label {
    color: ${Colors.ui.bodyText};

    strong {
      color: ${Colors.white};
    }
  }
`;

export const S = {
  Section,
  Title,
  Description,
  FormSegment,
  FormDescriptionContainer,
  FormDescriptionTitle,
  FormDescriptionList,
  FormDescriptionItem,
  FormContainer,
  Form,
  FormInputsContainer,
  StyledInputOptionalLabel,
  StyledTermsCheckbox,
};
