import React from 'react';
import { S } from './IntroSection.styled';
import EirLogo from './img/eir-logo.svg';
import NbiLogo from './img/nbi-logo.svg';
import ThreeLogo from './img/three-logo.svg';
import VodafoneLogo from './img/vodafone-logo.svg';

const IntroSection = () => (
  <section>
    <S.Title>Enabling AIOps in the service organisation</S.Title>
    <S.DescriptionContainer>
      <S.Description>
        This is a product of our visual analytics software expertise, machine learning models and extensive domain
        knowledge. It is also a result of close collaborations with leading telecom carriers. Our promise of minimum
        complexity with maximum efficiency has led to partnerships with leaders including Three, eir and Vodafone.
      </S.Description>
      <S.LogosContainer>
        <EirLogo title="Eir Logo" />
        <NbiLogo title="NBI Logo" />
        <ThreeLogo title="Three Logo" />
        <VodafoneLogo title="Vodafone Logo" />
      </S.LogosContainer>
    </S.DescriptionContainer>
  </section>
);

export default IntroSection;
