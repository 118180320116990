import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { S } from './AwardsSection.styled';

const AwardsSection = () => (
  <S.Section>
    <S.Column>
      <S.Title>A best in class solution</S.Title>
      <S.Description>
        VisiMetrix was named emerging product of the year at the Analytics Institute Fellowship & Industry Awards,
        recognising the way it transforms data and visual analytics among telecom service providers. The award
        highlighted our use of advanced data analytics technologies to augment, improve, or automate human
        decision-making to deliver better business and customer outcomes.
      </S.Description>
    </S.Column>
    <S.Column>
      <StaticImage src="img/award.png" alt="The Fellowship & Industry Awards picture" />
    </S.Column>
  </S.Section>
);

export default AwardsSection;
