import { useLocation } from '@reach/router';
import { graphql, withPrefix } from 'gatsby';
import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from 'src/components/layout/hooks/useSiteMetadata';
import PageContentSectionsWrapper from 'src/components/pageContentSectionsWrapper/PageContentSectionsWrapper';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import PageDescription from 'src/components/pageDescription/PageDescription';
import PageHeading from 'src/components/pageHeading/PageHeading';
import PageTitle from 'src/components/pageTitle/PageTitle';
import ListFilterInput from 'src/components/searchInput/SearchInput';
import { flattenBlogPostsData } from 'src/services/blog/blog';
import BlogRoll from '../../components/blogRoll/BlogRoll';
import Layout from '../../components/layout/Layout';
import { IBlogPageContext, IBlogPostListItemData } from '../../types/blog.types';
import NoJobsPlaceholder from '../careersJobPage/components/noJobsPlaceholder/NoJobsPlaceholder';
import {
  BlogCategoriesPanelWrapper,
  BlogFilterContainer,
  BlogFilterPanelWrapper,
  BlogFilterSubContainer,
  BlogRollWrapper,
  Root,
  BlogSearchInputWrapper,
  ShowMoreButton,
} from './BlogPage.styled';
import BlogCategoriesPanel from './components/blogCategoriesPanel/BlogCategoriesPanel';
import { BlogListCounter } from './components/blogCounter/BlogListCounter';
import BlogFeaturedPosts from './components/blogFeaturedPosts/BlogFeaturedPosts';
import { BlogListOrderSelect } from './components/blogListOrderSelect/blogListOrderSelect';
import { useBlogData } from './useBlogData';

interface IProps {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        description: string;
      };
    };
    blogPostsData: {
      blogPostsList: IBlogPostListItemData[];
    };
    featuredBlogPostsData: {
      featuredBlogPostsList: IBlogPostListItemData[];
    };
  };
  pageContext: IBlogPageContext;
}

const BlogPage = ({ data }: IProps) => {
  const {
    blogPostsData: { blogPostsList },
    featuredBlogPostsData: { featuredBlogPostsList },
    markdownRemark: { frontmatter },
  } = data;

  const blogData = useBlogData(blogPostsList);
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { title, description } = frontmatter;
  const blogFeaturedPostsList = flattenBlogPostsData(featuredBlogPostsList);
  const { siteUrl } = useSiteMetadata();

  useEffect(() => {
    blogData.setCategory(urlSearchParams.get('category') || undefined);
  }, [urlSearchParams]);

  const pageTitle = 'Insights, news, updates and blog posts from Sonalake';
  const pageDescription = 'Get the latest news and our thoughts on current trends, technical topics, design and more.';
  const noJobsMessage = 'We don’t have any articles about "%SEARCH_TERM%".';

  return (
    <Layout>
      <Helmet>
        <meta property="og:image" content={`${siteUrl}${withPrefix('/')}img/meta-news.png`} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="page" />
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
      </Helmet>
      <Root>
        <PageContentWide>
          <PageContentSectionsWrapper>
            <PageHeading>
              <PageTitle>{title}</PageTitle>
              <PageDescription>{description}</PageDescription>
            </PageHeading>
            <BlogFeaturedPosts posts={blogFeaturedPostsList} />
            <BlogCategoriesPanelWrapper>
              <BlogCategoriesPanel
                availableTags={blogData.availableTags}
                currentCategory={blogData.category}
                hiddenCategories={['visimetrix']}
              />
            </BlogCategoriesPanelWrapper>
            <BlogFilterPanelWrapper>
              <BlogFilterContainer>
                <BlogSearchInputWrapper>
                  <ListFilterInput
                    value={blogData.searchQuery}
                    onChange={e => blogData.setSearchQuery((e.target as HTMLInputElement).value)}
                    className="blog-search"
                  />
                </BlogSearchInputWrapper>
                <BlogFilterSubContainer>
                  <BlogListCounter posts={blogData.sortedCategoryPosts} />
                  <BlogListOrderSelect orderBy={blogData.orderBy} setOrderBy={blogData.setOrderBy} />
                </BlogFilterSubContainer>
              </BlogFilterContainer>
              {blogData.searchQuery && blogData.posts.length === 0 && (
                <NoJobsPlaceholder searchTerm={blogData.searchQuery} message={noJobsMessage} />
              )}
            </BlogFilterPanelWrapper>
            <BlogRollWrapper>
              <BlogRoll posts={blogData.posts} />
            </BlogRollWrapper>
            {blogData.canShowMore && (
              <ShowMoreButton title="Show more posts" onClick={blogData.showMore}>
                Show more
              </ShowMoreButton>
            )}
          </PageContentSectionsWrapper>
        </PageContentWide>
      </Root>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogIndexPageQuery($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
      }
    }
    blogPostsData: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blogPost" } } }
    ) {
      blogPostsList: edges {
        postItem: node {
          id
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            title
            description
            templateKey
            iconType
            categories
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            creator
          }
        }
      }
    }
    featuredBlogPostsData: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blogPost" }, featuredpost: { eq: true } } }
    ) {
      featuredBlogPostsList: edges {
        postItem: node {
          id
          fields {
            slug
            readingTime {
              minutes
            }
          }
          frontmatter {
            title
            description
            templateKey
            iconType
            categories
            date(formatString: "DD MMM YYYY")
            featuredpost
            creator
          }
        }
      }
    }
  }
`;

export default BlogPage;
