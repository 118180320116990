import styled from 'styled-components';
import { InputOptionalLabel } from 'src/components/inputOptionalLabel/InputOptionalLabel.styled';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import backgroundPrimary, { Colors } from 'src/theme/colors';
import PageBoxWrapper from '../pageBoxWrapper/PageBoxWrapper';
import WavyLines from './WavyLines.svg';

export const Background = styled(WavyLines)`
  position: absolute;
  height: 100%;
  bottom: 10px;
  right: 0;
  scale: 1.2;
  opacity: 0.25;

  @media ${mediaQuery.navbarCollapse} {
    opacity: 0.75;
    .visimetrix & {
      opacity: 1;
    }
  }
  .visimetrix & {
    color: ${Colors.teal.main} !important;
  }
`;

export const ContactInputOptionalLabel = styled(InputOptionalLabel)`
  color: ${Colors.ui.text.placeholder};
  .visimetrix & {
    color: ${Colors.white};
  }
  .visimetrix & {
    color: ${Colors.white};
  }
`;

export const Root = styled(PageBoxWrapper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  background: ${Colors.ui.greyDark};
  border-radius: 16px;
  padding: 'auto';

  input,
  textarea {
    width: 100%;
  }
  .visimetrix &,
  .switchedonbilling & {
    background: none;
    padding: 0px;
  }

  .light & {
    background-color: ${Colors.blue[90]};
  }

  label ~ span {
    color: #fff;
  }
`;

export const Title = styled.h2`
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -2px;
  margin-bottom: 24px;
  .light & {
    color: #494f52;
  }

  @media ${mediaQuery.phone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  .light & {
    color: #494f52;
  }
`;
export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${mediaQuery.desktop} {
    margin-top: 0;
  }

  textarea {
    display: flex;
    flex-grow: 1;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;

  @media ${mediaQuery.desktop} {
    flex-direction: row;
    gap: 24px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 30px;

  label + input {
    &::placeholder {
      opacity: 1;
    }
  }
  .visimetrix & {
    label + input {
      background-color: ${Colors.visimetrix.bg.teal70};
      &::placeholder {
        color: #0ea5b3;
      }
    }
  }
  .switchedonbilling & {
    label + input {
      background-color: ${Colors.purple['-70']};
      &::placeholder {
        color: ${Colors.purple['+20']};
      }
    }
  }

  @media ${mediaQuery.desktop} {
    gap: 40px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  .visimetrix & {
    div + input {
      background-color: ${Colors.visimetrix.bg.teal70};
      &::placeholder {
        color: ${Colors.teal.main};
      }
    }
  }
  .switchedonbilling & {
    div + input {
      background-color: ${Colors.purple['-70']};
      &::placeholder {
        color: ${Colors.purple['+20']};
      }
    }
  }
`;

export const TermsCheckboxWrapper = styled.div`
  margin: 32px 0;
  position: relative;
`;

export const ButtonWrapper = styled.div`
  margin-top: 32px;
`;

export const Button = styled.button`
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;

  background: #fff;

  color: #000;

  &:hover {
    background: #dadada;
  }

  .light & {
    background: ${backgroundPrimary};
    color: #fff;
  }
`;

export const FormWrapper = styled.div`
  .visimetrix &,
  .switchedonbilling & {
    max-width: 60%;
  }
`;
