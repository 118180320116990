import React from 'react';
import BoxText from 'src/components/boxText/BoxText';
import BoxTitle from 'src/components/boxTitle/BoxTitle';
import {
  Root,
  Row,
  Column,
  ContentWrapper,
  TitleWrapper,
  TextWrapper,
  ButtonWrapper,
  Button,
} from './IntroductionBox.styled';

const IntroductionBox = () => {
  return (
    <Root>
      <Row>
        <Column>
          <ContentWrapper>
            <TitleWrapper>
              <BoxTitle>Who we are</BoxTitle>
            </TitleWrapper>
            <TextWrapper>
              <BoxText>
                Sonalake is a trusted partner for global technology brands, telcos and scale-up disruptors.
              </BoxText>
              <BoxText>
                Our software services accelerate product roadmaps for our clients in growth sectors including FinTech,
                TravelTech, Security and Life Sciences.
              </BoxText>
              <BoxText>
                Our specialised solutions for telecommunication networks transform operations through automation and
                analytics.
              </BoxText>
              <BoxText>
                For us, it’s all about collaboration and culture, building trust and delivering on your business case
                with contemporary technology and agile engineering practices.
              </BoxText>
            </TextWrapper>

            <ButtonWrapper>
              <Button title="About us" role="button" to="/about">
                About us
              </Button>
            </ButtonWrapper>
          </ContentWrapper>
        </Column>
      </Row>
    </Root>
  );
};

export default IntroductionBox;
