import styled from 'styled-components';
import BoxContainer from 'src/components/boxContainer/BoxContainer';
import { BoxTitle } from 'src/components/boxTitle/BoxTitle';
import PageBoxWrapper from 'src/components/pageBoxWrapper/PageBoxWrapper';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';

export const Root = styled(BoxContainer)`
  background: #1b2327;
  border-radius: 16px;
  display: flex;
  flex-direction: column;

  .light & {
    background-color: ${Colors.ui.componentLight};
    color: ${Colors.ui.titleTextLight};
  }
`;

export const Column = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 16px;

  @media ${mediaQuery.tablet} {
    align-items: flex-start;
    height: 100%;
    background-position: right 25px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;

  @media ${mediaQuery.tablet} {
    gap: 20px;
    flex-direction: row;
    text-align: left;
  }
`;

export const TitleWrapper = styled.div`
  @media ${mediaQuery.tablet} {
    justify-content: flex-start;
  }
`;

export const TextWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  font-size: 16px;

  @media ${mediaQuery.tablet} {
    justify-content: flex-start;
  }
`;

export const CartTitle = styled.h3`
  font-size: 28px;
  font-weight: 700;
  margin-top: 24px;
  letter-spacing: -2px;
`;

export const CartDescription = styled.div`
  margin-top: 10px;
  line-height: 20px;
  max-width: 300px;
  margin-bottom: 60px;
  letter-spacing: -0.5px;

  @media ${mediaQuery.desktop} {
    margin-bottom: unset;
  }
  color: ${Colors.ui.bodyText};
  .light & {
    color: ${Colors.ui.bodyTextLight};
  }
`;

export const ContentWrapper = styled(PageBoxWrapper)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const MainTitle = styled(BoxTitle)`
  letter-spacing: -1.5px;
  font-weight: 800;
  font-size: 36px;
  line-height: 36px;

  @media ${mediaQuery.navbarCollapse} {
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.5px;
  }
  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;
