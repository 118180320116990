import React from 'react';
import { TestimonialsSwiper } from 'src/components/testimonials-swiper/testimonials-swiper';
import { S } from './TestimonialsSection.styled';

const testimonials = [
  {
    name: 'John McGrane',
    jobTitle: 'Head of Access and Transmission Networks, Three Ireland',
    text: 'VisiMetrix provides Three Ireland with the intelligence to monitor and proactively respond to service related issues within the network, often before these issues can impact customers. Its powerful alerting, network visualisations and dynamic thresholding abilities can highlight inter-dependencies and hotspots quickly, clearly and directly to network operations teams.',
    personImageUrl: '/testimonial/three.svg',
  },
  {
    name: 'Declan Malone',
    jobTitle: 'Head of Mobile Data and Messaging, eir',
    text: 'The overriding strategic impact of VisiMetrix has been to allow us to effectively share our network data by breaking traditional boundaries, making real-time and historic data easily accessible across the organisation for monitoring and analytics.',
    personImageUrl: '/testimonial/eir.svg',
  },
];
const TestimonialsSection = () => (
  <S.Section>
    <TestimonialsSwiper testimonials={testimonials} slides={1} />
  </S.Section>
);

export default TestimonialsSection;
