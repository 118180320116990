import React from 'react';
import { Root, Title, Details, DotSeparator } from './jobSummary.styled';

interface IProps {
  title: string;
  location: string;
  employmentType?: string;
  experience?: string;
}

const JobSummary = ({ title, location, employmentType, experience }: IProps) => {
  return (
    <Root>
      <Title>{title}</Title>
      <Details>
        <span>{location}</span>
        {!!employmentType && (
          <>
            <DotSeparator>•</DotSeparator>
            <span>{employmentType}</span>
          </>
        )}
        {!!experience && (
          <>
            <DotSeparator>•</DotSeparator>
            <span>{experience}</span>
          </>
        )}
      </Details>
    </Root>
  );
};

export default JobSummary;
