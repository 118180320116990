import React from 'react';
import type { IAuthorCaptionData } from 'src/types/blog.types';
import { Root, Image, Name, Position } from './AuthorCaption.styled';

interface IProps {
  data: IAuthorCaptionData;
  className?: string;
}

const AuthorCaption = ({ data, className }: IProps) => {
  return (
    <Root className={className}>
      {!!data.photo?.childImageSharp && <Image image={data.photo.childImageSharp.gatsbyImageData} alt="author-image" />}
      <div>
        <Name>{data.name}</Name>
        <Position>{data.position}</Position>
      </div>
    </Root>
  );
};

export default AuthorCaption;
