import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisimetrixInfoItem from 'src/components/visimetrix/visimetrixInfoItem/VisimetrixInfoItem';
import { S } from './IntroSection.styled';

const IntroSection = () => (
  <S.Section>
    <VisimetrixInfoItem
      title="Turn complex data into intuitive visuals"
      descriptions={[
        'VisiMetrix was designed for users, striking a balance between data granularity and information-at-a-glance. Users can create bespoke journeys through the data, with highly personalised maps, schemas and other visual representations that bring data to life.',

        'Its visual representations make it easy to access, modify and interpret the data that you choose to see. It balances flexibility with ease of use.',
      ]}
      ItemImage={<StaticImage src="img/complex-data.png" alt="VisiMetrix - screenshot of wallboard" />}
      tabletOrientation="left"
    />
  </S.Section>
);

export default IntroSection;
