import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { Colors } from 'src/theme/colors';
import { Typography } from 'src/theme/typography';

export const Root = styled.div`
  background-color: #1b2327;
  padding: 20px;
  border-radius: 16px;

  @media ${mediaQuery.desktop} {
    margin-top: 80px;
    padding: 80px 109px;
  }

  .light & {
    background-color: ${Colors.ui.componentLight};
  }
`;

export const Header = styled.a`
  display: inline-block;
  font-size: 30px;
  font-weight: 900;
  color: var(--textPrimary);
  margin-bottom: 32px;

  @media ${mediaQuery.desktop} {
    font-size: 56px;
    letter-spacing: -3px;
  }

  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;

export const Description = styled.div`
  ${Typography.articleBody};
  max-width: 518px;
  margin-bottom: 80px;

  .light & {
    color: ${Colors.ui.bodyTextLight};
  }
`;

export const Row = styled.div`
  max-width: 518px;
  margin-bottom: 40px;
  position: relative;

  @media ${mediaQuery.desktop} {
    flex-direction: row;
  }
`;

export const TermsCheckboxWrapper = styled.div`
  position: relative;
  max-width: 518px;
  margin-bottom: 80px;
`;

export const SuccessContainer = styled.div`
  max-width: 518px;
`;

export const SuccessHeader = styled.h3`
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -2px;
  margin-bottom: 32px;

  .light & {
    color: ${Colors.ui.titleTextLight};
  }
`;

export const SuccessMessage = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin-bottom: 32px;

  .light & {
    color: ${Colors.ui.bodyTextLight};
  }
`;

export const Button = styled.button`
  display: block;
  margin: 140px 0 44px 0;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  width: fit-content;
  background: #fff;
  color: #000;
  position: relative;
  z-index: 1;

  @media ${mediaQuery.tablet} {
    margin-top: 40px;
  }

  .light & {
    background-color: #1b2327;
    color: #fff;
  }
`;
