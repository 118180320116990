import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisimetrixInfoItem from 'src/components/visimetrix/visimetrixInfoItem/VisimetrixInfoItem';
import { S } from './InfoSection.styled';

const InfoSection = () => (
  <S.Section>
    <S.RowWithSeparator>
      <VisimetrixInfoItem
        title="Multi-vendor monitoring"
        descriptions={[
          'Get real-time visibility of network data, service characteristics and quality measurements, even with multi-vendor systems. Incorporate a diverse range of network technologies in a variety of combinations.',
        ]}
        ItemImage={
          <StaticImage src="img/multi-vendor-monitoring.png" alt="VisiMetrix - screenshot of 3G and 4G KPIs" />
        }
        tabletOrientation="bottom"
      />
      <VisimetrixInfoItem
        title="Auto discovery"
        descriptions={[
          'VisiMetrix contains an inbuilt, IP-based discovery feature. Enable the auto-discovery of network nodes and links that can be dynamically presented within relevant dashboard layers.',
        ]}
        ItemImage={<StaticImage src="img/auto-discovery.png" alt="VisiMetrix - screenshot of entity tables" />}
        tabletOrientation="bottom"
      />
    </S.RowWithSeparator>

    <S.Row>
      <VisimetrixInfoItem
        title="Efficient webscale architecture"
        descriptions={[
          'Get a highly efficient, cost effective framework with the VisiMetrix plug-in architecture, which can integrate a diverse range of network and service data components.',
        ]}
        ItemImage={
          <StaticImage
            src="img/efficient-webscale-architecture.png"
            alt="VisiMetrix - screenshot of alerts and thresholds"
          />
        }
        tabletOrientation="bottom"
      />
      <VisimetrixInfoItem
        title="Cloud deployed and DevOps enabled"
        descriptions={[
          'Access the latest internet and open source webscale technologies at a fraction of the cost of traditional OSS solutions. VisiMetrix is currently deployed on Amazon AWS and Google Cloud Platform.',
        ]}
        ItemImage={
          <StaticImage src="img/cloud-deployed-and-devops-enabled.png" alt="VisiMetrix - screenshot of topology maps" />
        }
        tabletOrientation="bottom"
      />
      <VisimetrixInfoItem
        title="Connecting multiple data sources"
        descriptions={[
          'Build pipelines to connect diverse data sources. VisiMetrix uses a modular design and pipelines to connect to over 50 different vendors and technologies. All types of data sources and processes can be accommodated.',
        ]}
        ItemImage={
          <StaticImage src="img/connecting-multiple-data-sources.png" alt="VisiMetrix - screenshot of data sources" />
        }
        tabletOrientation="bottom"
      />
    </S.Row>
  </S.Section>
);

export default InfoSection;
