import React from 'react';
import { Colors } from 'src/theme/colors';
import { S } from './CompanyPurposeBox.styled';

const CompanyPurposeBox = () => {
  return (
    <S.StyledPageBox backgroundColor={Colors.teal[90]}>
      <S.ContentContainer>
        <S.StyledSubTitle>Our purpose</S.StyledSubTitle>
        <S.DescriptionContainer>
          <S.Description>
            Sonalake is committed to having an exceptional impact on our customers, our people and the wider community.
          </S.Description>
          <S.Description>
            Our business focus is to help companies innovate with software - designing new products for revenue growth,
            automation and insight.
          </S.Description>
          <S.Description>
            We do this by hiring great people, investing in innovation, and embracing agile and creative ways of
            working. Engineering excellence is at the heart of everything we do.
          </S.Description>
          <S.Description>
            Our approach is to earn and sustain trusted partner status with our clients, and trusted employer status
            with our people. This is central to our culture.
          </S.Description>
        </S.DescriptionContainer>
      </S.ContentContainer>
    </S.StyledPageBox>
  );
};

export default CompanyPurposeBox;
