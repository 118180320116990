import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { pagePaddingDesktop, pagePaddingMobile } from 'src/theme/dimensions';

const PageComponentWrapper = styled.div`
  margin: 0 ${pagePaddingMobile};
  box-sizing: content-box;

  @media ${mediaQuery.desktop} {
    margin: 0 ${pagePaddingDesktop};
  }
`;

export default PageComponentWrapper;
