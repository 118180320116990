import React from 'react';
import { useForm } from 'react-hook-form';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import InputLabel from 'src/components/inputLabel/InputLabel';
import TermsCheckbox from 'src/components/termsCheckbox/TermsCheckbox';
import ValidationMessage from 'src/components/validationMessage/ValidationMessage.styled';
import { Colors } from 'src/theme/colors';
import FormSuccess from '../formSuccess/FormSuccess';
import Input from '../input/Input';
import InputPhone from '../inputPhone/InputPhone';
import Textarea from '../textarea/Textarea';
import {
  Button,
  ButtonWrapper,
  Column,
  Form,
  FormWrapper,
  InputWrapper,
  Root,
  Row,
  TermsCheckboxWrapper,
  ContactInputOptionalLabel,
  Title,
  Description,
  Background,
} from './ContactForm.styled';
import { IContactFormData } from './contactForm.types';
import { useContactForm } from './hooks/useContactForm/useContactForm';
import './transition.css';

interface ContactFormProps {
  isMessageField: boolean;
  formName: string;
  title: string;
  description: string;
  visimetrix?: boolean;
  staticImage?: JSX.Element;
}

const ContactForm = ({ isMessageField, formName, title, description, visimetrix, staticImage }: ContactFormProps) => {
  // TODO handle form logic, validation https://sonalake.atlassian.net/browse/BNS-181

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IContactFormData>({ mode: 'onBlur' });
  const termsChecked = watch('terms', false);

  const [sendContactFormData] = useContactForm(formName);

  return (
    <Root>
      <SwitchTransition mode="out-in">
        <CSSTransition key={isSubmitSuccessful ? 'success' : 'form'} timeout={500} classNames="fade">
          {!isSubmitSuccessful ? (
            <FormWrapper>
              {visimetrix && <Background />}
              <Title>{title}</Title>
              <Description>{description}</Description>
              <Form
                name={formName}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit(sendContactFormData)}>
                <input type="hidden" name="form-name" value={formName} />
                <Row>
                  <Column>
                    <InputWrapper>
                      <InputLabel htmlFor="name">Name</InputLabel>
                      <Input
                        name="name"
                        id="name"
                        title="name"
                        register={register}
                        placeholder="Name"
                        rules={{ required: true }}
                      />
                      {errors.name && <ValidationMessage>This field is required</ValidationMessage>}
                    </InputWrapper>

                    <InputWrapper>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <Input
                        name="email"
                        id="email"
                        title="email"
                        register={register}
                        placeholder="Email"
                        inputMode="email"
                        rules={{
                          required: 'This field is required',
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: 'Please provide a properly formatted email address',
                          },
                        }}
                      />
                      {errors.email && <ValidationMessage>{errors.email.message}</ValidationMessage>}
                    </InputWrapper>
                    <InputWrapper>
                      <InputLabel htmlFor="businessName">
                        <span>Business name</span>
                        <ContactInputOptionalLabel>(Optional)</ContactInputOptionalLabel>
                      </InputLabel>
                      <Input
                        name="businessName"
                        id="businessName"
                        title="Business name"
                        register={register}
                        placeholder="Business name"
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <InputLabel htmlFor="phone">
                        <span>Phone number</span>
                        <ContactInputOptionalLabel>(Optional)</ContactInputOptionalLabel>
                      </InputLabel>
                      <InputPhone
                        setValue={val => {
                          setValue('phone', val);
                        }}
                      />
                    </InputWrapper>
                  </Column>
                  {isMessageField && (
                    <Column>
                      <InputWrapper>
                        <InputLabel htmlFor="message">
                          <span>Message</span>
                          <ContactInputOptionalLabel>(Optional)</ContactInputOptionalLabel>
                        </InputLabel>
                        <Textarea
                          name="message"
                          id="message"
                          title="Message"
                          register={register}
                          placeholder="Start writing"
                        />
                      </InputWrapper>
                    </Column>
                  )}
                </Row>
                <TermsCheckboxWrapper>
                  <TermsCheckbox
                    onChange={value => setValue('terms', value)}
                    register={register}
                    checked={termsChecked}
                  />
                  {errors.terms && <ValidationMessage>You need to accept the Privacy Policy</ValidationMessage>}
                </TermsCheckboxWrapper>
                <ButtonWrapper>
                  <Button title="Submit form" type="submit">
                    Send
                  </Button>
                </ButtonWrapper>
              </Form>
              {visimetrix && staticImage}
            </FormWrapper>
          ) : (
            <FormSuccess $textColor={Colors.ui.bodyText} />
          )}
        </CSSTransition>
      </SwitchTransition>
    </Root>
  );
};

export default ContactForm;
