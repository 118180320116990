import React from 'react';
import { S } from './VisimetrixInfoItem.styled';

interface IProps {
  title: string;
  descriptions: (string | React.ReactElement)[];
  ItemImage: React.ReactElement;
  tabletOrientation: 'left' | 'right' | 'bottom';
  className?: string;
}

const VisimetrixInfoItem = ({ title, descriptions, ItemImage, tabletOrientation, className }: IProps) => {
  return (
    <S.Item className={className} $tabletOrientation={tabletOrientation}>
      <S.TextContainer>
        <S.Title>{title}</S.Title>
        {descriptions.map((description, index) =>
          typeof description === 'string' ? <S.Description key={index}>{description}</S.Description> : description,
        )}
      </S.TextContainer>
      <S.ImageContainer>{ItemImage}</S.ImageContainer>
    </S.Item>
  );
};

export default VisimetrixInfoItem;
