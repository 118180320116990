import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';

const Title = styled.h2`
  font-size: 40px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: -2px;
  margin-bottom: 32px;

  @media ${mediaQuery.phone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
  margin-bottom: 40px;

  @media ${mediaQuery.desktop} {
    max-width: 60%;
  }
`;

const CardsList = styled.ol`
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;

  @media ${mediaQuery.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const S = {
  Title,
  Description,
  CardsList,
};
