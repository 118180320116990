import styled from 'styled-components';
import { mediaQuery } from 'src/config/deviceMediaQueries';
import { boxPaddingHorizontalDesktop, boxPaddingMobile, pagePaddingDesktop } from 'src/theme/dimensions';

const PageHeading = styled.section`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: ${boxPaddingMobile} ${boxPaddingMobile} 0;

  @media ${mediaQuery.desktop} {
    padding: ${pagePaddingDesktop} ${boxPaddingHorizontalDesktop} 0;
  }
`;

export default PageHeading;
