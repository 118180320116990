import { css } from 'styled-components';

export const Typography = {
  articleBody: css`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  `,
  body: {
    16: css`
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.5px;
    `,
  },
};
