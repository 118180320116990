import React from 'react';
import { RegisterOptions, UseFormRegister, Path } from 'react-hook-form';
import { TextareaStyled } from './Textarea.styled';

interface IProps<TFormValues> {
  id?: string;
  placeholder?: string;
  title?: string;
  register?: UseFormRegister<TFormValues>;
  name: Path<TFormValues>;
  rules?: RegisterOptions;
  disabled?: boolean;
  rows?: number;
}

const Textarea = <TFormValues extends Record<string, any>>({
  placeholder,
  name,
  id,
  title,
  register,
  rules,
  disabled,
  rows,
}: IProps<TFormValues>) => {
  return (
    <TextareaStyled
      id={id}
      title={title}
      name={name}
      placeholder={placeholder}
      rows={rows}
      disabled={disabled}
      {...(register && register(name, rules))}></TextareaStyled>
  );
};

export default Textarea;
