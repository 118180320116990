import React from 'react';
import { useForm, UseFormRegister } from 'react-hook-form';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import BoxButton from 'src/components/boxButton/BoxButton';
import { InputWrapper, TermsCheckboxWrapper } from 'src/components/contactForm/ContactForm.styled';
import { IContactFormData } from 'src/components/contactForm/contactForm.types';
import { useContactForm } from 'src/components/contactForm/hooks/useContactForm/useContactForm';
import FormSuccess from 'src/components/formSuccess/FormSuccess';
import Input from 'src/components/input/Input';
import InputLabel from 'src/components/inputLabel/InputLabel';
import InputPhone from 'src/components/inputPhone/InputPhone';
import ValidationMessage from 'src/components/validationMessage/ValidationMessage.styled';
import { ThemeName } from 'src/theme/ThemeStyles';
import { S } from './RequestDemoSection.styled';

const formName = 'SwitchOnFibre demo contact form';

const RequestDemoSection = () => {
  const [sendContactFormData] = useContactForm(formName);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IContactFormData>({ mode: 'onBlur' });
  const termsChecked = watch('terms', false);

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition key={isSubmitSuccessful ? 'success' : 'form'} timeout={500} classNames="fade">
        <S.Section>
          {!isSubmitSuccessful ? (
            <>
              <S.Title>Request a demo</S.Title>
              <S.Description>
                Schedule a personal demo of SwitchedOn Fibre™ to see how we can help you streamline your workflow, scale
                up your business and unlock additional revenue streams.
              </S.Description>

              <S.FormSegment>
                <>
                  <S.FormDescriptionContainer>
                    <S.FormDescriptionTitle>What our demo will cover</S.FormDescriptionTitle>

                    <S.FormDescriptionList>
                      <S.FormDescriptionItem>
                        <b>What to expect: </b>We’ll go through our “one-stop shop” customer portal for provision and
                        lifecycle management
                      </S.FormDescriptionItem>
                      <S.FormDescriptionItem>
                        <b>Opportunities for growth: </b>How to leverage existing infrastructure to provide and sell
                        additional services to RSPs
                      </S.FormDescriptionItem>
                      <S.FormDescriptionItem>
                        <b>Automation options: </b>Discover how efficient automation can improve inventory, ordering and
                        billing processes
                      </S.FormDescriptionItem>
                      <S.FormDescriptionItem>
                        <b>How to scale up: </b>Wholesale broadband providers can aggregate the offerings of multiple
                        wholesale networks into a single point of access
                      </S.FormDescriptionItem>
                      <S.FormDescriptionItem>
                        <b>Agile adaptation: </b>How to achieve the speed and flexibility needed to continuously evolve
                        and support customers, from the largest Tier-1 to smaller RSPs
                      </S.FormDescriptionItem>
                    </S.FormDescriptionList>
                  </S.FormDescriptionContainer>

                  <S.FormContainer>
                    <S.Form
                      name={formName}
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      onSubmit={handleSubmit(sendContactFormData)}>
                      <input type="hidden" name="form-name" value={formName} />

                      <S.FormInputsContainer>
                        <InputWrapper>
                          <InputLabel htmlFor="name">Name</InputLabel>
                          <Input
                            name="name"
                            title="name"
                            id="name"
                            register={register as unknown as UseFormRegister<Record<string, any>>}
                            placeholder="Name"
                            rules={{ required: true }}
                            theme={ThemeName.switchedOnFibre}
                          />
                          {errors.name && (
                            <ValidationMessage $theme={ThemeName.switchedOnFibre}>
                              This field is required
                            </ValidationMessage>
                          )}
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel htmlFor="email">Email</InputLabel>
                          <Input
                            name="email"
                            title="email"
                            id="email"
                            register={register as unknown as UseFormRegister<Record<string, any>>}
                            placeholder="Email"
                            inputMode="email"
                            rules={{
                              required: 'This field is required',
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: 'Please provide a properly formatted email address',
                              },
                            }}
                            theme={ThemeName.switchedOnFibre}
                          />
                          {errors.email && (
                            <ValidationMessage $theme={ThemeName.switchedOnFibre}>
                              {errors.email.message}
                            </ValidationMessage>
                          )}
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel htmlFor="businessName">
                            <>
                              Business name <S.StyledInputOptionalLabel>(Optional)</S.StyledInputOptionalLabel>
                            </>
                          </InputLabel>
                          <Input
                            name="businessName"
                            id="businessName"
                            register={register as unknown as UseFormRegister<Record<string, any>>}
                            placeholder="Business name"
                            theme={ThemeName.switchedOnFibre}
                          />
                        </InputWrapper>
                        <InputWrapper>
                          <InputLabel htmlFor="phone">
                            <>
                              Phone number <S.StyledInputOptionalLabel>(Optional)</S.StyledInputOptionalLabel>
                            </>
                          </InputLabel>
                          <InputPhone
                            setValue={val => {
                              setValue('phone', val);
                            }}
                            theme={ThemeName.switchedOnFibre}
                          />
                        </InputWrapper>
                      </S.FormInputsContainer>

                      <TermsCheckboxWrapper>
                        <S.StyledTermsCheckbox
                          onChange={value => setValue('terms', value)}
                          register={register as unknown as UseFormRegister<Record<string, any>>}
                          checked={termsChecked}
                        />
                        {errors.terms && (
                          <ValidationMessage $theme={ThemeName.switchedOnFibre}>
                            You need to accept the Privacy Policy
                          </ValidationMessage>
                        )}
                      </TermsCheckboxWrapper>

                      <BoxButton title="Send form" type="submit">
                        Send
                      </BoxButton>
                    </S.Form>
                  </S.FormContainer>
                </>
              </S.FormSegment>
            </>
          ) : (
            <FormSuccess />
          )}
        </S.Section>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default RequestDemoSection;
