import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import BackButton from '../../backButton/BackButton';
import { S } from './PivotsPageHeader.styled';

interface IProps {
  title: string;
  subtitle: string;
  isSubPage?: boolean;
}

const PivotsPageHeader: React.FC<IProps> = ({ title, subtitle, isSubPage = false }) => {
  const { state } = useLocation() as { state: { canGoBack?: boolean } | null };

  return (
    <S.Section>
      <S.GoBackLink
        onClick={() =>
          state?.canGoBack ? navigate(-1) : navigate(isSubPage ? '/telecom-solutions/visimetrix' : '/telecom-solutions')
        }>
        <BackButton />
      </S.GoBackLink>
      <S.TitleContainer>
        <div>
          <S.PageTitle>{title}</S.PageTitle>
          <S.SubTitle>{subtitle}</S.SubTitle>
        </div>
        <S.LogosContainer>
          <StaticImage src="img/connected-britain-award.png" alt="ConnectedBritain Award" objectFit="contain" />
          <S.TotscoLogo title="TOTSCO - ready" />
        </S.LogosContainer>
      </S.TitleContainer>
    </S.Section>
  );
};

export default PivotsPageHeader;
