import React from 'react';
import { Root } from './publishedInfo.styled';

interface IProps {
  readingTime: string;
  date?: string;
  className?: string;
}

const PublishedInfo = ({ date, readingTime, className }: IProps) => (
  <Root className={className}>
    <p>POSTED ON {date?.toUpperCase()}</p>
    <p>READING TIME: {parseInt(readingTime)} MINUTES</p>
  </Root>
);

export default PublishedInfo;
