import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import VisimetrixInfoItem from 'src/components/visimetrix/visimetrixInfoItem/VisimetrixInfoItem';
import { S } from './IntroSection.styled';

const IntroSection = () => (
  <S.Section>
    <VisimetrixInfoItem
      title="Machine learning models"
      descriptions={[
        'Our data science team builds advanced machine learning models to help you understand and solve complex network problems.',
        'Predict the impact of storms and other weather events on the performance of the radio access network. Track the impact of localised transmission outages on linked cell sites. Get more insights from your complex data with clustering and predictive modeling.',
      ]}
      ItemImage={
        <StaticImage
          src="img/machine-learning-models.png"
          alt="VisiMetrix - screenshot of advanced machine learning models"
        />
      }
      tabletOrientation="left"
    />
  </S.Section>
);

export default IntroSection;
