import { graphql, withPrefix } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import GivingBackBox from 'src/components/givingBackBox/GivingBackBox';
import Layout from 'src/components/layout/Layout';
import useSiteMetadata from 'src/components/layout/hooks/useSiteMetadata';
import PageContentSectionsWrapper from 'src/components/pageContentSectionsWrapper/PageContentSectionsWrapper';
import PageContentWide from 'src/components/pageContentWide/PageContentWide';
import PageDescription from 'src/components/pageDescription/PageDescription';
import CompanyTechStack from '../../components/companyTechStack/CompanyTechStack';
import JobsList from '../careersJobPage/components/jobsList/JobsList';
import MainPageTestimonials from '../indexPage/components/mainPageTestimonialCarousel/MainPageTestimonials';
import {
  LinkButtonWrapper,
  JobsListWrapper,
  Button,
  PageHeading,
  HeadingContent,
  HeadingImage,
  PageTitle,
} from './careers.styled';
import GalleryBox from './components/galeryBox/galleryBox';
import PersonalDevelopmentBox from './components/personalDevelopmentBox/PersonalDevelopmentBox';
import WhatCanWeOfferBox from './components/whatCanWeOfferBox/WhatCanWeOfferBox';
import FrontImage from './img/title_image.jpg';

interface IProps {
  data: {
    markdownRemark: {
      frontmatter: {
        pageTitle: string;
        title: string;
        pageDescription: string;
        description: string;
        description2: string;
      };
    };
  };
}

const CarrersPage = ({ data }: IProps) => {
  const { pageTitle, title, pageDescription, description, description2 } = data.markdownRemark.frontmatter;
  const { siteUrl } = useSiteMetadata();
  return (
    <Layout carrers>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={`${siteUrl}${withPrefix('/')}img/meta-jobs.png`} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`${siteUrl}${withPrefix('/')}careers`} />
      </Helmet>
      <PageContentWide>
        <PageContentSectionsWrapper>
          <PageHeading>
            <HeadingImage>
              <img src={FrontImage} />
            </HeadingImage>
            <HeadingContent>
              <PageTitle>{title}</PageTitle>
              <PageDescription>{description}</PageDescription>
              <PageDescription>{description2}</PageDescription>
              <LinkButtonWrapper>
                <Button title="See open roles" role="button" to="#jobs">
                  See open roles
                </Button>
              </LinkButtonWrapper>
            </HeadingContent>
          </PageHeading>
          <WhatCanWeOfferBox />
          <PersonalDevelopmentBox />
          <GivingBackBox />
          <MainPageTestimonials />
          <GalleryBox />
          <CompanyTechStack />
        </PageContentSectionsWrapper>
      </PageContentWide>
      <JobsListWrapper>
        <PageContentWide>
          <JobsList />
        </PageContentWide>
      </JobsListWrapper>
    </Layout>
  );
};

export default CarrersPage;

export const pageQuery = graphql`
  query CarrersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        title
        pageDescription
        description
        description2
      }
    }
  }
`;
