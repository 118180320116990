exports.components = {
  "component---src-modules-about-about-tsx": () => import("./../../../src/modules/about/About.tsx" /* webpackChunkName: "component---src-modules-about-about-tsx" */),
  "component---src-modules-blog-page-blog-page-tsx": () => import("./../../../src/modules/blogPage/BlogPage.tsx" /* webpackChunkName: "component---src-modules-blog-page-blog-page-tsx" */),
  "component---src-modules-blog-post-blog-post-tsx": () => import("./../../../src/modules/blogPost/BlogPost.tsx" /* webpackChunkName: "component---src-modules-blog-post-blog-post-tsx" */),
  "component---src-modules-careers-careers-tsx": () => import("./../../../src/modules/careers/Careers.tsx" /* webpackChunkName: "component---src-modules-careers-careers-tsx" */),
  "component---src-modules-careers-job-page-careers-job-page-tsx": () => import("./../../../src/modules/careersJobPage/CareersJobPage.tsx" /* webpackChunkName: "component---src-modules-careers-job-page-careers-job-page-tsx" */),
  "component---src-modules-case-studies-case-studies-tsx": () => import("./../../../src/modules/caseStudies/CaseStudies.tsx" /* webpackChunkName: "component---src-modules-case-studies-case-studies-tsx" */),
  "component---src-modules-case-study-case-study-tsx": () => import("./../../../src/modules/caseStudy/CaseStudy.tsx" /* webpackChunkName: "component---src-modules-case-study-case-study-tsx" */),
  "component---src-modules-get-in-touch-get-in-touch-tsx": () => import("./../../../src/modules/getInTouch/GetInTouch.tsx" /* webpackChunkName: "component---src-modules-get-in-touch-get-in-touch-tsx" */),
  "component---src-modules-index-page-index-page-tsx": () => import("./../../../src/modules/indexPage/IndexPage.tsx" /* webpackChunkName: "component---src-modules-index-page-index-page-tsx" */),
  "component---src-modules-pivots-page-pivots-page-tsx": () => import("./../../../src/modules/pivotsPage/PivotsPage.tsx" /* webpackChunkName: "component---src-modules-pivots-page-pivots-page-tsx" */),
  "component---src-modules-portfolio-portfolio-tsx": () => import("./../../../src/modules/portfolio/Portfolio.tsx" /* webpackChunkName: "component---src-modules-portfolio-portfolio-tsx" */),
  "component---src-modules-privacy-policy-page-privacy-policy-page-tsx": () => import("./../../../src/modules/privacyPolicyPage/PrivacyPolicyPage.tsx" /* webpackChunkName: "component---src-modules-privacy-policy-page-privacy-policy-page-tsx" */),
  "component---src-modules-switched-on-billing-page-switched-on-billing-page-tsx": () => import("./../../../src/modules/switchedOnBillingPage/SwitchedOnBillingPage.tsx" /* webpackChunkName: "component---src-modules-switched-on-billing-page-switched-on-billing-page-tsx" */),
  "component---src-modules-switched-on-fibre-page-switched-on-fibre-page-tsx": () => import("./../../../src/modules/switchedOnFibrePage/SwitchedOnFibrePage.tsx" /* webpackChunkName: "component---src-modules-switched-on-fibre-page-switched-on-fibre-page-tsx" */),
  "component---src-modules-visimetrix-a-better-overview-page-visimetrix-a-better-overview-page-tsx": () => import("./../../../src/modules/visimetrixABetterOverviewPage/VisimetrixABetterOverviewPage.tsx" /* webpackChunkName: "component---src-modules-visimetrix-a-better-overview-page-visimetrix-a-better-overview-page-tsx" */),
  "component---src-modules-visimetrix-data-science-page-visimetrix-data-science-page-tsx": () => import("./../../../src/modules/visimetrixDataSciencePage/VisimetrixDataSciencePage.tsx" /* webpackChunkName: "component---src-modules-visimetrix-data-science-page-visimetrix-data-science-page-tsx" */),
  "component---src-modules-visimetrix-easy-integration-page-visimetrix-easy-integration-page-tsx": () => import("./../../../src/modules/visimetrixEasyIntegrationPage/VisimetrixEasyIntegrationPage.tsx" /* webpackChunkName: "component---src-modules-visimetrix-easy-integration-page-visimetrix-easy-integration-page-tsx" */),
  "component---src-modules-visimetrix-page-visimetrix-page-tsx": () => import("./../../../src/modules/visimetrixPage/VisimetrixPage.tsx" /* webpackChunkName: "component---src-modules-visimetrix-page-visimetrix-page-tsx" */),
  "component---src-modules-visimetrix-ux-excellence-page-visimetrix-ux-excellence-page-tsx": () => import("./../../../src/modules/visimetrixUxExcellencePage/VisimetrixUxExcellencePage.tsx" /* webpackChunkName: "component---src-modules-visimetrix-ux-excellence-page-visimetrix-ux-excellence-page-tsx" */),
  "component---src-modules-what-we-do-what-we-do-tsx": () => import("./../../../src/modules/whatWeDo/WhatWeDo.tsx" /* webpackChunkName: "component---src-modules-what-we-do-what-we-do-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

